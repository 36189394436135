import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import FundDetails from "./FundDetails";
import Benchmark from "./Benchmark";
import Performance from "./Performance";
import Dividend from "./Dividend";
import ErrorModal from "./ErrorModal";
import ConfirmationModal from "./ConfirmationModal";
import { FundManagerConverter, FundTypeConverter } from "./data_converter";
import {
  requestFundDetails,
  cleanupFundDetails,
  setModeFundDetails,
  requestFundCodeExists,
  requestFundCreate,
  requestFundEdit,
  resetFundCodeExists
} from "./redux/actions";
import "./style.scss";

function LoadingComponent(props) {
  const {
    requestingFundDetails,
    requestingPutFund,
    requestingFundCodeExists,
    requestingEditFund,

  } = props;
  return (
    <div className={"funddetails-progress"}>
      <CircularProgress />
      <p className="funddetails-progress-text">
        {requestingFundDetails
          ? "Retrieving Fund Details in English and Thai..."
          : null}
        {requestingPutFund ? "Pushing up Fund Details..." : null}
        {requestingFundCodeExists ? "Checking if Fund code exists..." : null}
        {requestingEditFund ? "Saving Fund Details edit..." : null}
      </p>
    </div>
  );
}

function FundDetailComponent(props) {
  const {
    history,
    fundDetailsEN,
    fundDetailsTH,
    mode,
    setModeFundDetails,
    requestFundCodeExists,
    requestFundCreate,
    requestFundEdit,
    requestingFundCodeExists,
    requestingEditFund,
    requestingPutFund,
    fundCodeExistsInDB,
    fundCodeExistsInPentacle,
    fundIDFromCheckingFundCodeExists,
    resetFundCodeExists,
    errorMessage
  } = props;

  // default state, before getting anything from API.
  let defaultValues = {
    "fund-active": false,
    "funddetails-fundcode": "",
    "funddetails-website": "",
    "funddetails-articleinitial": "",
    "funddetails-launchdate": null,
    "funddetails-ipostatus": null,
    "funddetails-ipostartdate": null,
    "funddetails-ipoenddate": null,
    "funddetails-recommend": null,
    "funddetails-closeddate": null,
    "funddetails-maturitydates": null,
    "funddetails-risklevel": null,
    "funddetails-fundid": null,
    "funddetails-fundphoto": "",
    "funddetails-inceptiondate": null,
    "funddetailsextra-english-fundname": "",
    "funddetailsextra-english-fundcategory": "",
    "funddetailsextra-english-fundcurrency": "",
    "funddetailsextra-english-factsheeturl": "",
    "funddetailsextra-english-prospectusurl": "",
    "funddetailsextra-english-highlightsheeturl": "",
    "investmentpolicy-english": "",
    "fundmanager-english": "",
    "fundtype-english": "",
    "fundsize-english": "",
    "dividendpolicy-english": "",
    "dividendpolicy-option": false,
    "benchmark-english": "",
    "hedgingpolicy-english": "",
    "periodipo-english": "",
    "periodipoafter-english": "",
    "redemptionperiod-english": "",
    "minsubscription-english": "",
    "nextsubscription-english": "",
    "firstlowbuy-english": "",
    "nextlowbuy-english": "",
    "settlement-english": "",
    "dividendpaymenthistory-english": "",
    "frontendprospectus-english": "",
    "frontendactual-english": "",
    "backendprospectus-english": "",
    "backendactual-english": "",
    "brokerageprospectus-english": "",
    "brokerageactual-english": "",
    "managementprospectus-english": "",
    "managementactual-english": "",
    "trusteeprospectus-english": "",
    "trusteeactual-english": "",
    "registrationprospectus-english": "",
    "registrationactual-english": "",

    "funddetailsextra-thai-fundname": "",
    "funddetailsextra-thai-fundcategory": "",
    "funddetailsextra-thai-fundcurrency": "",
    "funddetailsextra-thai-factsheeturl": "",
    "funddetailsextra-thai-prospectusurl": "",
    "funddetailsextra-thai-highlightsheeturl": "",
    "investmentpolicy-thai": "",
    "fundmanager-thai": "",
    "fundtype-thai": "",
    "fundsize-thai": "",
    "dividendpolicy-thai": "",
    "benchmark-thai": "",
    "hedgingpolicy-thai": "",
    "periodipo-thai": "",
    "periodipoafter-thai": "",
    "redemptionperiod-thai": "",
    "minsubscription-thai": "",
    "nextsubscription-thai": "",
    "firstlowbuy-thai": "",
    "nextlowbuy-thai": "",
    "settlement-thai": "",
    "dividendpaymenthistory-thai": "",
    "frontendprospectus-thai": "",
    "frontendactual-thai": "",
    "backendprospectus-thai": "",
    "backendactual-thai": "",
    "brokerageprospectus-thai": "",
    "brokerageactual-thai": "",
    "managementprospectus-thai": "",
    "managementactual-thai": "",
    "trusteeprospectus-thai": "",
    "trusteeactual-thai": "",
    "registrationprospectus-thai": "",
    "registrationactual-thai": "",

    "benchmark-countrycode": "TH",
    "benchmark-fundcode": "",
    "benchmark-benchmarkcode": null,
    "benchmark-benchmarkname": null,
    "benchmark-fundid": null,
    "benchmark-benchmarkytd": null,
    "benchmark-03months": null,
    "benchmark-06months": null,
    "benchmark-12months": null,
    "benchmark-03years": null,
    "benchmark-05years": null,
    "benchmark-10years": null,
    "benchmark-sinceinception": null,
    "benchmark-asofdate": null,

    "performance-countrycode": "TH",
    "performance-fundcode": "",
    "performance-fundid": null,
    "performance-returnytd": null,
    "performance-return03months": null,
    "performance-return06months": null,
    "performance-return12months": null,
    "performance-return03years": null,
    "performance-return05years": null,
    "performance-return10years": null,
    "performance-returnsinceinception": null,
    "performance-navperunit": null,
    "performance-totalnav": null,

    "dividend-countrycode": "TH",
    "dividend-fundcode": "",
    "dividend-year": null,
    "dividend-closingdate": null,
    "dividend-rate": null,
    can_buy: true,
    can_topup: true,
    can_switch: true,
    can_redeem: true,
    recommend: 0
  };
  if (fundDetailsEN && Object.keys(fundDetailsEN).length > 0) {
    var dividendOption;
    var dividendStatus = fundDetailsEN.dividend_policy_status;
    switch (dividendStatus) {
      case true:
        dividendOption = "1";
        break;
      case false:
        dividendOption = "0";
        break;
      case 1:
        dividendOption = "1";
        break;
      case 0:
        dividendOption = "0";
        break;
      case "1":
        dividendOption = "1";
        break;
      case "0":
        dividendOption = "0";
        break;
      default:
        dividendOption = "0";
    }

    defaultValues = {
      ...defaultValues,
      "fund-active": fundDetailsEN.active,
      "funddetails-fundcode": fundDetailsEN.fund_code,
      "funddetails-website": fundDetailsEN.website,
      "funddetails-articleinitial": fundDetailsEN.fund_article_initial,
      "funddetails-launchdate": Date.parse(fundDetailsEN.launch_date),
      "funddetails-closeddate": Date.parse(fundDetailsEN.closed_date),
      "funddetails-ipostatus": fundDetailsEN.ipo_status === 'true' ? 1 : 0,
      "funddetails-ipostartdate": Date.parse(fundDetailsEN.ipo_started_date),
      "funddetails-ipoenddate": Date.parse(fundDetailsEN.ipo_ended_date),
      "funddetails-recommend": fundDetailsEN.recommend === 'true' ? 1 : 0,
      "funddetails-fundphoto": fundDetailsEN.fund_photo_url,
      "funddetails-maturitydates": Date.parse(fundDetailsEN.maturity_date),
      "funddetails-risklevel": fundDetailsEN.risk_level.toString(),
      "funddetails-fundid": fundDetailsEN.fund_no,
      "funddetails-inceptiondate": Date.parse(fundDetailsEN.inception_date),
      "funddetailsextra-english-fundname": fundDetailsEN.fund_name,
      "funddetailsextra-english-fundcategory": fundDetailsEN.fund_category,
      "funddetailsextra-english-fundcurrency": fundDetailsEN.fund_currency,
      "funddetailsextra-english-factsheeturl":
        fundDetailsEN.fund_fact_sheet_url,
      "funddetailsextra-english-prospectusurl": fundDetailsEN.prospectus_url,
      "funddetailsextra-english-highlightsheeturl":
        fundDetailsEN.product_highlight_sheet_url,
      "investmentpolicy-english": fundDetailsEN.investment_policy,
      "fundmanager-english": FundManagerConverter(fundDetailsEN.fund_manager),
      "fundtype-english": FundTypeConverter(fundDetailsEN.fund_type),
      "fundsize-english": fundDetailsEN.fund_size,
      "dividendpolicy-english": fundDetailsEN.dividend_policy,
      "dividendpolicy-option": dividendOption,
      "benchmark-english": fundDetailsEN.benchmark,
      "hedgingpolicy-english": fundDetailsEN.hedging_policy,
      "periodipo-english": fundDetailsEN.subscription_period,
      "periodipoafter-english": fundDetailsEN.subscription_period, // fundDetailsEN.subscription_period_after_ipo,
      "redemptionperiod-english": fundDetailsEN.redemption_period,
      "minsubscription-english": fundDetailsEN.minimum_subscription,
      "nextsubscription-english": fundDetailsEN.next_subscription,
      "firstlowbuy-english": fundDetailsEN.first_lowbuy_val,
      "nextlowbuy-english": fundDetailsEN.next_lowbuy_val,
      "settlement-english": fundDetailsEN.settlement,
      "dividendpaymenthistory-english": fundDetailsEN.dividend_payment_history,
      "frontendprospectus-english":
        fundDetailsEN.front_end_fee_and_switching_in_fee_prospectus,
      "frontendactual-english":
        fundDetailsEN.front_end_fee_and_switching_in_fee_actual,
      "backendprospectus-english":
        fundDetailsEN.back_end_fee_and_switching_out_fee_prospectus,
      "backendactual-english":
        fundDetailsEN.back_end_fee_and_switching_out_fee_actual,
      "brokerageprospectus-english": fundDetailsEN.brokerage_fee_prospectus,
      "brokerageactual-english": fundDetailsEN.brokerage_fee_actual,
      "managementprospectus-english": fundDetailsEN.management_fee_prospectus,
      "managementactual-english": fundDetailsEN.management_fee_actual,
      "trusteeprospectus-english": fundDetailsEN.trustee_fee_prospectus,
      "trusteeactual-english": fundDetailsEN.trustee_fee_actual,
      "registrationprospectus-english":
        fundDetailsEN.registration_fee_prospectus,
      "registrationactual-english": fundDetailsEN.registration_fee_actual,
      "benchmark-countrycode": (fundDetailsEN.country_code && fundDetailsEN.country_code.length > 0) ? fundDetailsEN.country_code[0] : null,
      "benchmark-fundcode": fundDetailsEN.fund_code,
      "benchmark-benchmarkcode": fundDetailsEN.benchmark_code,
      "benchmark-benchmarkname": fundDetailsEN.benchmark_name,
      "benchmark-fundid": fundDetailsEN.fund_no,
      "benchmark-benchmarkytd": fundDetailsEN.benchmarkytd,
      "benchmark-03months": fundDetailsEN.benchmark03m,
      "benchmark-06months": fundDetailsEN.benchmark06m,
      "benchmark-09months": "",
      "benchmark-12months": fundDetailsEN.benchmark12m,
      "benchmark-03years": fundDetailsEN.benchmark03y,
      "benchmark-05years": fundDetailsEN.benchmark05y,
      "benchmark-10years": fundDetailsEN.benchmark10y,
      "benchmark-sinceinception": fundDetailsEN.benchmarksin,
      "benchmark-asofdate": fundDetailsEN.asof
        ? Date.parse(fundDetailsEN.asof)
        : null,
      "performance-countrycode": (fundDetailsEN.country_code && fundDetailsEN.country_code.length > 0) ? fundDetailsEN.country_code[0] : null,
      "performance-fundcode": fundDetailsEN.fund_code,
      "performance-fundid": fundDetailsEN.fund_no,
      "performance-returnytd": fundDetailsEN.returnytd,
      "performance-return03months": fundDetailsEN.return03m,
      "performance-return06months": fundDetailsEN.return06m,
      "performance-return12months": fundDetailsEN.return12m,
      "performance-return03years": fundDetailsEN.return03y,
      "performance-return05years": fundDetailsEN.return05y,
      "performance-return10years": fundDetailsEN.return10y,
      "performance-returnsinceinception": fundDetailsEN.returnsin,
      "performance-navperunit": fundDetailsEN.navperunit,
      "performance-totalnav": fundDetailsEN.totalnav_mb,
      "dividend-countrycode": (fundDetailsEN.country_code && fundDetailsEN.country_code.length > 0) ? fundDetailsEN.country_code[0] : null,
      "dividend-fundcode": fundDetailsEN.fund_code,
      "dividend-year": fundDetailsEN.funds_dividend.year,
      "dividend-closingdate": fundDetailsEN.funds_dividend.closing_date
        ? Date.parse(fundDetailsEN.funds_dividend.closing_date)
        : null,
      "dividend-rate": fundDetailsEN.funds_dividend.per_unit,
      can_buy: fundDetailsEN.can_buy,
      can_topup: fundDetailsEN.can_topup,
      can_switch: fundDetailsEN.can_switch,
      can_redeem: fundDetailsEN.can_redeem,
      recommend: fundDetailsEN.recommend
    };
  }


  if (fundDetailsTH && Object.keys(fundDetailsTH).length > 0) {
    defaultValues = {
      ...defaultValues,
      "funddetailsextra-thai-fundname": fundDetailsTH.fund_name,
      "funddetailsextra-thai-fundcategory": fundDetailsTH.fund_category,
      "funddetailsextra-thai-fundcurrency": fundDetailsTH.fund_currency,
      "funddetailsextra-thai-factsheeturl": fundDetailsTH.fund_fact_sheet_url,
      "funddetailsextra-thai-prospectusurl": fundDetailsTH.prospectus_url,
      "funddetailsextra-thai-highlightsheeturl":
        fundDetailsTH.product_highlight_sheet_url,
      "investmentpolicy-thai": fundDetailsTH.investment_policy,
      "fundmanager-thai": FundManagerConverter(fundDetailsTH.fund_manager),
      "fundtype-thai": FundTypeConverter(fundDetailsTH.fund_type),
      "fundsize-thai": fundDetailsTH.fund_size,
      "dividendpolicy-thai": fundDetailsTH.dividend_policy,
      "benchmark-thai": fundDetailsTH.benchmark,
      "hedgingpolicy-thai": fundDetailsTH.hedging_policy,
      "periodipo-thai": fundDetailsTH.subscription_period,
      "periodipoafter-thai": fundDetailsTH.subscription_period,//fundDetailsTH.subscription_period_after_ipo,
      "redemptionperiod-thai": fundDetailsTH.redemption_period,
      "minsubscription-thai": fundDetailsTH.minimum_subscription,
      "nextsubscription-thai": fundDetailsTH.next_subscription,
      "firstlowbuy-thai": fundDetailsTH.first_lowbuy_val,
      "nextlowbuy-thai": fundDetailsTH.next_lowbuy_val,
      "settlement-thai": fundDetailsTH.settlement,
      "dividendpaymenthistory-thai": fundDetailsTH.dividend_payment_history,
      "frontendprospectus-thai":
        fundDetailsTH.front_end_fee_and_switching_in_fee_prospectus,
      "frontendactual-thai":
        fundDetailsTH.front_end_fee_and_switching_in_fee_actual,
      "backendprospectus-thai":
        fundDetailsTH.back_end_fee_and_switching_out_fee_prospectus,
      "backendactual-thai":
        fundDetailsTH.back_end_fee_and_switching_out_fee_actual,
      "brokerageprospectus-thai": fundDetailsTH.brokerage_fee_prospectus,
      "brokerageactual-thai": fundDetailsTH.brokerage_fee_actual,
      "managementprospectus-thai": fundDetailsTH.management_fee_prospectus,
      "managementactual-thai": fundDetailsTH.management_fee_actual,
      "trusteeprospectus-thai": fundDetailsTH.trustee_fee_prospectus,
      "trusteeactual-thai": fundDetailsTH.trustee_fee_actual,
      "registrationprospectus-thai": fundDetailsTH.registration_fee_prospectus,
      "registrationactual-thai": fundDetailsTH.registration_fee_actual
    };
  }

  const {
    register,
    handleSubmit,
    trigger,
    formState,
    setValue,
    getValues,
    errors,
    control
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues
  });

  // mode is onBlur, meaning errors will only be checked after input is left
  // if there's an issue, then reValidateMode will set that input to onChange until user inputs correct value

  // Tabs can be FUNDDETAILS, BENCHMARK, PERFORMANCE, or DIVIDEND
  const [currentTab, setCurrentTab] = useState("FUNDDETAILS");
  const [fundStatus, setFundStatus] = useState(fundDetailsEN.active);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showButtonTypeSubmit, setShowButtonTypeSubmit] = useState(false);

  // Read the formState before render to subscribe the form state through Proxy
  const { touched } = formState;
  useEffect(() => {
    if (mode === "CREATE") {
      setShowButtonTypeSubmit(true);
    }
  },[]);
  const onSubmit = data =>  {
    let modifiedData = JSON.parse(JSON.stringify(data));

    // Force set these fields to null if there's no user input, otherwise BE validation break
    if (
      typeof modifiedData["dividend-year"] === "string" &&
      modifiedData["dividend-year"].length === 0
    ) {
      modifiedData["dividend-year"] = null;
    }
    if (
      typeof modifiedData["dividend-rate"] === "string" &&
      modifiedData["dividend-rate"].length === 0
    ) {
      modifiedData["dividend-rate"] = null;
    }

    if (
      typeof modifiedData["benchmark-benchmarkcode"] === "string" &&
      modifiedData["benchmark-benchmarkcode"].length === 0
    ) {
      modifiedData["benchmark-benchmarkcode"] = null;
    }

    if (
      typeof modifiedData["benchmark-benchmarkname"] === "string" &&
      modifiedData["benchmark-benchmarkname"].length === 0
    ) {
      modifiedData["benchmark-benchmarkname"] = null;
    }
    if (
      typeof modifiedData["benchmark-benchmarkytd"] === "string" &&
      modifiedData["benchmark-benchmarkytd"].length === 0
    ) {
      modifiedData["benchmark-benchmarkytd"] = null;
    }
    if (
      typeof modifiedData["benchmark-03months"] === "string" &&
      modifiedData["benchmark-03months"].length === 0
    ) {
      modifiedData["benchmark-03months"] = null;
    }
    if (
      typeof modifiedData["benchmark-06months"] === "string" &&
      modifiedData["benchmark-06months"].length === 0
    ) {
      modifiedData["benchmark-06months"] = null;
    }
    if (
      typeof modifiedData["benchmark-12months"] === "string" &&
      modifiedData["benchmark-12months"].length === 0
    ) {
      modifiedData["benchmark-12months"] = null;
    }
    if (
      typeof modifiedData["benchmark-03years"] === "string" &&
      modifiedData["benchmark-03years"].length === 0
    ) {
      modifiedData["benchmark-03years"] = null;
    }
    if (
      typeof modifiedData["benchmark-05years"] === "string" &&
      modifiedData["benchmark-05years"].length === 0
    ) {
      modifiedData["benchmark-05years"] = null;
    }
    if (
      typeof modifiedData["benchmark-10years"] === "string" &&
      modifiedData["benchmark-10years"].length === 0
    ) {
      modifiedData["benchmark-10years"] = null;
    }
    if (
      typeof modifiedData["benchmark-sinceinception"] === "string" &&
      modifiedData["benchmark-sinceinception"].length === 0
    ) {
      modifiedData["benchmark-sinceinception"] = null;
    }
    if (
      typeof modifiedData["benchmark-asofdate"] === "string" &&
      modifiedData["benchmark-asofdate"].length === 0
    ) {
      modifiedData["benchmark-asofdate"] = null;
    }
    if (
      typeof modifiedData["performance-returnytd"] === "string" &&
      modifiedData["performance-returnytd"].length === 0
    ) {
      modifiedData["performance-returnytd"] = null;
    }
    if (
      typeof modifiedData["performance-return03months"] === "string" &&
      modifiedData["performance-return03months"].length === 0
    ) {
      modifiedData["performance-return03months"] = null;
    }
    if (
      typeof modifiedData["performance-return06months"] === "string" &&
      modifiedData["performance-return06months"].length === 0
    ) {
      modifiedData["performance-return06months"] = null;
    }
    if (
      typeof modifiedData["performance-return12months"] === "string" &&
      modifiedData["performance-return12months"].length === 0
    ) {
      modifiedData["performance-return12months"] = null;
    }
    if (
      typeof modifiedData["performance-return03years"] === "string" &&
      modifiedData["performance-return03years"].length === 0
    ) {
      modifiedData["performance-return03years"] = null;
    }
    if (
      typeof modifiedData["performance-return05years"] === "string" &&
      modifiedData["performance-return05years"].length === 0
    ) {
      modifiedData["performance-return05years"] = null;
    }
    if (
      typeof modifiedData["performance-return10years"] === "string" &&
      modifiedData["performance-return10years"].length === 0
    ) {
      modifiedData["performance-return10years"] = null;
    }
    if (
      typeof modifiedData["performance-returnsinceinception"] === "string" &&
      modifiedData["performance-returnsinceinception"].length === 0
    ) {
      modifiedData["performance-returnsinceinception"] = null;
    }
    if (
      typeof modifiedData["performance-navperunit"] === "string" &&
      modifiedData["performance-navperunit"].length === 0
    ) {
      modifiedData["performance-navperunit"] = null;
    }
    if (
      typeof modifiedData["performance-totalnav"] === "string" &&
      modifiedData["performance-totalnav"].length === 0
    ) {
      modifiedData["performance-totalnav"] = null;
    }
    

    // We need to know if file is pre-filled from API or if user choose a file from their local system
    let fileFromLocal =
      getValues()["funddetails-fundphoto"] &&
      getValues()["funddetails-fundphoto"].length > 0 &&
      touched["funddetails-fundphoto"];

    let fileImage = null;
    if (fileFromLocal) {
      fileImage = getValues()["funddetails-fundphoto"];
    }

    if (mode === "CREATE" || mode === "CLONE") {
      requestFundCreate({
        formValues: modifiedData,
        history: history,
        active: fundStatus ? "1" : "0",
        fileFromLocal,
        fileImage
      });
    } else if (mode === "EDIT") {
      requestFundEdit({
        formValues: modifiedData,
        history: history,
        active: fundStatus ? "1" : "0",
        fileFromLocal,
        fileImage
      });
    }
  };
  return (
    <Fragment>
      {requestingFundCodeExists || requestingEditFund || requestingPutFund ? (
        <LoadingComponent
          requestingFundCodeExists={requestingFundCodeExists}
          requestingEditFund={requestingEditFund}
          requestingPutFund={requestingPutFund}
        />
      ) : null}
      {errorMessage ? <ErrorModal /> : null}
      <form onSubmit={handleSubmit(onSubmit)}
        onKeyDown={keyEvent => {
          // pressing Enter should NOT submit form, so we block it here
          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
          }
        }}
      >
        <ConfirmationModal
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
        />
        <div className="fund-detail-header">
          {mode === "CREATE" ? (
            <Fragment>
              <button
                className="fund-detail-cancel"
                onClick={e => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                CANCEL
              </button>
              <button
                className="fund-detail-create"
                type="submit"
                //disabled={!formState.isValid}
              >
                CREATE
              </button>
            </Fragment>
          ) : null}
          {mode === "CLONE" ? (
            <Fragment>
              <button
                className="fund-detail-cancel"
                onClick={e => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                CANCEL
              </button>
              <button
                className="fund-detail-create"
                type="button"
                onClick={() => setShowConfirmation(true)}
                //disabled={!formState.isValid}
              >
                CREATE
              </button>
            </Fragment>
          ) : null}
          {mode === "EDIT" ? (
            <Fragment>
              <button
                className="fund-detail-cancel"
                onClick={e => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                CANCEL
              </button>
              <button
                className="fund-detail-create"
                type="button"
                // disabled={!formState.isValid}
                onClick={() => setShowConfirmation(true)}
              >
                SAVE
              </button>
            </Fragment>
          ) : null}
          {mode === "VIEW" ? (
            <Fragment>
              <button
                className="fund-detail-clone"
                onClick={e => {
                  e.preventDefault();
                  // user will need to re-enter a fresh fund code when making a fund from cloning
                  setValue("funddetails-fundcode", "", true);
                  // retrigger entire form validation to make sure empty fields are highlighted
                  trigger();
                  // we assume there's a default image coming in, so keep this field empty
                  setModeFundDetails({ mode: "CLONE" });
                }}
              >
                CLONE FUND
              </button>
              <button
                className="fund-detail-create"
                onClick={e => {
                  e.preventDefault();
                  trigger();
                  setModeFundDetails({ 
                    mode: "EDIT" ,
                    detailsFromList:  props.detailsFromList,
                    PageCurrent: 1
                  });
                }}
              >
                EDIT
              </button>
            </Fragment>
          ) : null}
        </div>
        <div className="fund-detail-tab">
          <button
            onClick={e => {
              e.preventDefault();
              setCurrentTab("FUNDDETAILS");
            }}
            className={
              currentTab === "FUNDDETAILS"
                ? "fund-detail-tabbutton-selected"
                : "fund-detail-tabbutton"
            }
          >
            Fund Details
          </button>
          <button
            type={showButtonTypeSubmit && mode === "CREATE" ? "submit" : "button"}
            onClick={() => {
              setCurrentTab("BENCHMARK");
              if (mode === "CREATE") {
                setTimeout(
                  () => setShowButtonTypeSubmit(false),
                  3000
                );
              }
            }}
            className={
              currentTab === "BENCHMARK"
                ? "fund-detail-tabbutton-selected"
                : "fund-detail-tabbutton"
            }
          >
            Benchmark
          </button>
          <button
            type={showButtonTypeSubmit && mode === "CREATE" ? "submit" : "button"}
            onClick={() => {
              setCurrentTab("PERFORMANCE");
              if (mode === "CREATE") {
                setTimeout(
                  () => setShowButtonTypeSubmit(false),
                  3000
                );
              }
            }}
            className={
              currentTab === "PERFORMANCE"
                ? "fund-detail-tabbutton-selected"
                : "fund-detail-tabbutton"
            }
          >
            Performance
          </button>
          <button
            type={showButtonTypeSubmit && mode === "CREATE" ? "submit" : "button"}
            onClick={() => {
              setCurrentTab("DIVIDEND");
              if (mode === "CREATE") {
                setTimeout(
                  () => setShowButtonTypeSubmit(false),
                  3000
                );
              }
            }}
            className={
              currentTab === "DIVIDEND"
                ? "fund-detail-tabbutton-selected"
                : "fund-detail-tabbutton"
            }
          >
            Dividend
          </button>
        </div>
        <div className="fund-detail-form">
          <FundDetails
            register={register}
            errors={errors}
            show={currentTab === "FUNDDETAILS"}
            trigger={trigger}
            setValue={setValue}
            control={control}
            getValues={getValues}
            fundDetailsEN={fundDetailsEN}
            requestFundCodeExists={requestFundCodeExists}
            fundCodeExistsInDB={fundCodeExistsInDB}
            fundCodeExistsInPentacle={fundCodeExistsInPentacle}
            fundIDFromCheckingFundCodeExists={fundIDFromCheckingFundCodeExists}
            resetFundCodeExists={resetFundCodeExists}
            fundStatus={fundStatus}
            setFundStatus={setFundStatus}
            formState={formState}
            defaultValues ={defaultValues}
            mode={mode}
          />
          <Benchmark
            register={register}
            errors={errors}
            setValue={setValue}
            control={control}
            getValues={getValues}
            show={currentTab === "BENCHMARK"}
            mode={mode}
            defaultValues ={defaultValues}
          />
          <Performance
            register={register}
            errors={errors}
            show={currentTab === "PERFORMANCE"}
            mode={mode}
          />
          <Dividend
            register={register}
            errors={errors}
            control={control}
            setValue={setValue}
            show={currentTab === "DIVIDEND"}
            mode={mode}
            defaultValues ={defaultValues}


          />
        </div>
      </form>
    </Fragment>
  );
}

function FundDetail(props) {
  // if we load the form before data has downloaded, useForm doesn't properly set default values.
  // so don't load form component at all until data is in or API call has failed.

  const {
    mode,
    requestFundDetails,
    cleanupFundDetails,
    requestingFundDetails,
    history
  } = props;

  useEffect(() => {
    if (mode === "VIEW" || mode === "EDIT") {
      // get fund details on component did mount

      requestFundDetails();
    }
    // on component unmount, do cleanup
    return () => {
      cleanupFundDetails();
    };
  }, []);

  const [isPageReload, setIsPageReload] = useState(false);
  const [isUnloading, setIsUnloading] = useState(false);

  useEffect(() => {
    if (mode === "VIEW" || mode === "EDIT") {
      const savePageState = () => {
        const dataFromList = JSON.parse(localStorage.getItem('currentFundList'))
        const dataFromListFundRec = JSON.parse(localStorage.getItem('currentFundrecMan'))
        console.log("datalist", dataFromList)
        console.log("datafundrec", dataFromListFundRec)
        if (dataFromList) {
          localStorage.setItem('currentFundDetail', JSON.stringify(dataFromList));
        }
        if (dataFromListFundRec) {
          localStorage.setItem('currentFundDetail', JSON.stringify(dataFromListFundRec));
        }
      };
  
      // Save initial state
      savePageState();
      
      const handleBeforeUnload = () => {
        savePageState();
      };
    
      const handlePageShow = (event) => {
        if (!event.persisted) {
          const storedData = JSON.parse(localStorage.getItem('currentFundDetail'));
          if (storedData) {
            console.log("stored", storedData)
          }
        }
      };
  
      // Check for page reload using Performance API
      const navigationType = window.performance.getEntriesByType("navigation")[0]?.type;
      if (navigationType === 'reload') {
        setIsPageReload(true);
        const storedData = JSON.parse(localStorage.getItem('currentFundDetail'));
        console.log("storedData in reload", storedData)
        if (storedData && storedData.from === "fundList") {
          history.push(`/fund_listDetail/${storedData.rowData.fund_no}`);
          console.log("fundlist reload")
        }
        if (storedData && storedData.from === "FundRec" && storedData.mode === "VIEW") {
          history.push(`/FundRec_FundManView/${storedData.rowData.fund_no}`);
          console.log("fundrec reload")
        }
        if (storedData && storedData.from === "FundRec" && storedData.mode === "EDIT") {
          history.push(`/FundRec_FundManEdit/${storedData.rowData.fund_no}`);
          console.log("fundrec reload")
        }
      }
  
      // Handle navigation events
      const unlisten = history.listen((location, action) => {
        const storedData = JSON.parse(localStorage.getItem('currentFundDetail'));
        if (action === 'POP' && !isPageReload && !isUnloading && storedData.from === "fundList") {
          history.replace({
            pathname: "/fund_list",
          });
        }
        if (action === 'POP' && !isPageReload && !isUnloading && storedData.from === "fundRec") {
          history.replace({
            pathname: "/FundRec_FundMan",
          });
        }
      });
  
      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('pageshow', handlePageShow);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        window.removeEventListener('pageshow', handlePageShow);
        unlisten();
  
        // Only clean up stored data if we're not reloading and not unloading
        if (!isPageReload && !isUnloading) {
          localStorage.removeItem('currentFundDetail');
          localStorage.removeItem('currentFundList');
          localStorage.removeItem('currentFundrecMan');
        }
      };
    }
    
  }, []);

  if (requestingFundDetails) {
    return <LoadingComponent {...props} />;
  } else {
    return <FundDetailComponent {...props} />;
  }
}

const withConnect = connect(
  state => ({
    ...state.fundDetailsReducer
  }),
  {
    requestFundDetails,
    cleanupFundDetails,
    setModeFundDetails,
    requestFundCodeExists,
    requestFundCreate,
    requestFundEdit,
    resetFundCodeExists
  }
);

export default compose(
  withConnect,
  withRouter
)(FundDetail);
