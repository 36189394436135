import React, { Fragment, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQFundRecCodiList } from "./FundRecCodi_redux/actions";
import { MEMFundRecCodiDetailEdt } from "../FundRecommendDetail/FundRecCodi_redux/actions";

import Table_FundRecCodi from "./table_FundRecCodi";
import {col_FundRecCodi } from "./list_of_columns";
import "./style.scss";
function FundRecCodiList(props) {
    const {
        REQFundRecCodiList,
        REQStrFundRecCodiList,
        FundRecCodiList,
        MEMFundRecCodiDetailEdt,
        history
    } = props;
    const columns = React.useMemo(() => col_FundRecCodi, []);

    const GETxFundRecCodi = () => {
      REQFundRecCodiList();
    };

    useEffect(() => {
      GETxFundRecCodi();
    }, []);
  

    // --------------Click With Table------------------
    const SETxActTableColClick = ({ mode, rowData,pageIndex }) => {
      if (mode === "edit") {
        console.log("RowDataAt:",rowData);
        MEMFundRecCodiDetailEdt({mode: "EDIT", RowDataAt:rowData,PageCurrent:pageIndex });
        localStorage.setItem('currentFundCondition', JSON.stringify({mode: "EDIT", RowDataAt:rowData,PageCurrent:pageIndex }))
        history.push(`/FundRec_CodiDetail/${rowData.idofcodi}`); 
      }
    };

    return (
        <Fragment>
         <div className="button-create-container">
          <Link
            className="button-create-new-content"
            style={{width:270}}
            to={`/FundRec_CodiCrt`} 
          >
            <span style={{paddingRight:10}}>+</span>CREATE NEW CONDITION
        </Link>  
        </div> 
           {REQStrFundRecCodiList ? (
          <div className="feed-list-progress">
            <CircularProgress />
            <p className="feed-list-progress-text">
              {REQStrFundRecCodiList
                ? "Retrieving data to get FundRecommend condition articles"
                : null}
              {REQStrFundRecCodiList ? "Retrieving FundRecommend condition articles..." : null}
            </p>
          </div>
        ) : null}
          <Table_FundRecCodi
            data={FundRecCodiList}
            columns={columns}
            handleActionClick={SETxActTableColClick}
            history={history}
          />
      
 
        </Fragment>
    );
}

const withConnect = connect(
    state => ({
        ...state.fundRecCodiListReducer
    }),
    {
          REQFundRecCodiList,
          MEMFundRecCodiDetailEdt
    }
);
export default compose(
    withConnect,
    withRouter
)(FundRecCodiList);


