import React from "react";
import "./style.scss";

export default function BasicInput({
  type = "text",
  placeholder = "",
  disabled = false,
  value = "",
  onChange = () => {},
  className = "",
  sx = {},
}) {
  return (
    <div
      className={`input-wrapper ${disabled ? "disabled" : ""} ${className}`}
      style={{ ...sx, margin: "0rem 1rem 0rem 1rem" }}
    >
      <input
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        className="input-box"
      />
    </div>
  );
}
