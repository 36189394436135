import { createReducer } from "redux-act";

import {
  // --------------UntHld ACCOUNT-----------------
  SetFullImgUrlAuto,
  REQUntHldListAuto,
  RESUntHldListAuto,
  FailUntHldListAuto,
  CleanUpUntHldListAuto,
  // ---------DisArpove----
  requestDisapproveClientAuto,
  receiveDisapproveClientAuto,
  failDisapproveClientAuto,
} from "./actions";

const initialState = {
  REQStrUntHldList: false,
  UntHldList: [],
  errorMessage: false,
  totalUntHldAct: 0,
  fullImgUrl: '',
};

const untHldListAutoReducer = createReducer(
  {
  
    [REQUntHldListAuto]: (state) => {
      return {
        ...state,
        REQStrUntHldList: true
      };
    },
   
    [RESUntHldListAuto]: (state, payload) => {
     
      return {
        ...state,
        REQStrUntHldList: false,
        UntHldList: payload.data,
        totalUntHldAct: payload.total
      };
    },
     
    [FailUntHldListAuto]: (state) => {
      return {
        ...state,
        REQStrUntHldList: false,
        UntHldList: [],
        totalUntHldAct: 0
      };
    } , 
    [CleanUpUntHldListAuto]: () => {
      return initialState;
    },
    // ----------------DisAprove---------------
    [requestDisapproveClientAuto]: (state) => {
      return {
        ...state,
        requestingDisapproveClient: true
      };
    },
    [receiveDisapproveClientAuto]: (state) => {
      return {
        ...state,
        requestingDisapproveClient: false
      };
    },
    [failDisapproveClientAuto]: (state) => {
      return {
        ...state,
        requestingDisapproveClient: false
      };
    },
    [SetFullImgUrlAuto]: (state, payload) => {
      return {
        ...state,
        fullImgUrl: payload.fullImgUrl
      }
    }
  },

  initialState
);

export default untHldListAutoReducer;
