import React from "react";
import Images from "../../utils/styles/images";

// start test pass
export function modifyCell(cell, pageSelect, pageSize, handleActionClick) {
  const { props } = cell;
  const columnName = props.cell?.column?.Header ?? null;
  const cellValue = props?.cell?.value ?? null;
  const rowNumber = props?.row?.index + 1 ?? null;
  const rowData = props?.row?.original ?? null;

  const handleViewOrEditClick = (mode) => {
    handleActionClick({ mode, rowData, pageSelect });
  };

  const handleDeviceNameClick = () => {
    handleActionClick(cellValue, rowData, columnName, pageSelect);
  };

  const handleExportClick = () => {
    handleActionClick(cellValue, rowData, columnName, pageSelect);
  };
  const formatCreatedAt = (value) => {
    if (value) {
      const dateObject = new Date(value);
      dateObject.setHours(dateObject.getHours());
    
      const tDate = `${dateObject.toISOString().slice(0, 19).replace("T", " ")}`;
      return tDate;
    } else {
      return cell;    
    }
  };

  switch (columnName) {
    case "ACTION":
      if (rowData) {
        return (
          <div className="funds-action-buttons">
            <button className="funds-action-view" onClick={() => handleViewOrEditClick("view")}>
              <img src={Images.fundListIcon.view} alt="eye" />
              View
            </button>
            <button className="funds-action-edit" onClick={() => handleViewOrEditClick("edit")}>
              <img src={Images.fundListIcon.edit} alt="pencil" />
              Edit
            </button>
          </div>
        );
      }
      break;
    case "NO.":
      return rowNumber;
    case "DEVICE NAME":
      if (handleActionClick) {
        return (
          <div>
            <button onClick={handleDeviceNameClick} className="feed-list-article-button">
              {cellValue}
            </button>
          </div>
        );
      }
      break;
    case "CREATED AT":
      return formatCreatedAt(cellValue);
    case "ACTIVITY TYPE":
      if (cellValue) {
        return cellValue
      } else {
        return "-"
      }
    case "ELEMENT NAME":
      if (cellValue) {
        return cellValue
      } else {
        return "-"
      }
    case "SCREEN SECTION":
      if (cellValue) {
        return cellValue
      } else {
        return "-"
      }
    case "SCREEN NAME":
      if (cellValue) {
        return cellValue
      } else {
        return "-"
      }
    case "ELEMENT LOCATION":
      if (cellValue) {
        return cellValue
      } else {
        return "-"
      }
    case "ELEMENT TYPE":
      if (cellValue) {
        return cellValue
      } else {
        return "-"
      }
    case "ELEMENT STATUS":
      if (cellValue) {
        return cellValue
      } else {
        return "-"
      }
        
    case "EXPORT":

      return (
        <div>
          <button onClick={handleExportClick} className="conFd-synce-button" style={{ width: 71, height: 25, fontSize: 12 }}>
            Export file
          </button>
        </div>
      );
    case "ID":
      return cellValue ? cell : null;
    default:
      return cell;
  }
}
// end test


// This is just for the header cells
export function modifyCellHeader(
  cell,
  handleActionHeaderClick,
  handleSortHeader
) {
  if (cell === "DEVICE NAME") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        DEVICE NAME
      </button>
    );
  } else if (cell === "VERIFIED BY") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        VERIFIED BY
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "CREATE DATE") {
    return (
      <button className="transactions-header-with-sort">
        CREATE DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "REGISTERED DATE") {
    return (
      <button className="transactions-header-with-sort">
        REGISTERED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "DEREGISTERED DATE") {
    return (
      <button className="transactions-header-with-sort">
        DEREGISTERED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "TYPE") {
    return (
      <button className="transactions-header-with-sort">
        TYPE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "STATUS") {
    return (
      <button className="transactions-header-with-sort">
        STATUS
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else {
    return cell;
  }
}
