import { createReducer } from "redux-act";

import {
  requestSaveAndPublishContentAction,
  receiveSaveAndPublishContentAction,
  failSaveAndPublishContentAction,
  setContentFeedModeAction,
  requestFeedContentAction,
  receiveFeedContentAction,
  failFeedContentAction,
  clearFeedContentAction,
  requestDeleteFeedContentAction,
  receiveDeleteFeedContentAction,
  failDeleteFeedContentAction,
  clearLocalCopyRemoteImage,

  // ---new----
  MEMConFdEdt,
  // ----ArticleTag-----
  REQArticleTag,
  RESArticleTag,
  FailArticleTag,
  // ----Fund-----
  REQFund,
  RESFund,
  FailFund,
  // ----Edit ContentFeedDetail----
  REQContFdEdt,
  RESContFdEdt,
  FailContFdEdt,

  // ----Creat ContentFeedDetail----
  REQContFdCrt,
  RESContFdCrt,

  // ----Edit ContentFeedNotiInvest Detail----
  REQContFdNotiInvestEdt,
  RESContFdNotiInvestEdt,
  FailContFdNotiInvestEdt,

  // ----Creat ContentFeedNotiInvest Detail----
  REQContFdNotiInvestCrt,
  RESContFdNotiInvestCrt,
  FailContFdNotiInvestCrt,

  // ----Del ContentFeedDetail----
  REQContFdDel,
  RESContFdDel,
  FailContFdDel,
  // ----Del ContentFeedNotiInvest Detail----
  REQContFdNotiInvestDel,
  RESContFdNotiInvestDel,
  FailContFdNotiInvestDel,
  CleanConFdDetail,
  //  fund code
  REQFundCodeList,
  RESFundCodeList,
  FailFundCodeList
} from "./actions";

const initialState = {
  requestingSaveAndPublish: false,
  mode: null,
  feedUUID: null,
  requestingFeedContent: false,
  feedContent: null,
  requestingDelete: false,

  // ----ArticleTag----
  ArticleTagList: [],
  totalArticleTag: 0,
  // ----Fund----
  FundList: [],
  totalFund: 0,


  // ---ContentFeed detail Edit---
  ConFdEdit: [],
  totalConFdEdit: 0,
  // ---ContentFeed detail Creat---
  ConFdCrt: [],
  totalConFdCrt: 0,
  // ---ContentFeed detail Del---
  ConFdDel: [],
  totalConFdDel: 0,
  // ---ContentFeed Noti Invest detail Edit---
  ContFdNotiInvestEdit: [],
  totalContFdNotiInvestEdit: 0,
  // ---ContentFeed Noti Invest detail Ceeat---
  ContFdNotiInvestCrt: [],
  totalContFdNotiInvestCrt: 0,
  // ---ContentFeed Noti Invest detail Del---
  ContFdNotiInvestDel: [],
  totalContFdNotiInvestDel: 0,

  errorMessage: false
};

const conFdDetailReducer = createReducer(
  {
    [requestSaveAndPublishContentAction]: (state) => {
      return {
        ...state,
        requestingSaveAndPublish: true
      };
    },
    [receiveSaveAndPublishContentAction]: (state) => {
      return {
        ...state,
        requestingSaveAndPublish: false
      };
    },
    [failSaveAndPublishContentAction]: (state) => {
      return {
        ...state,
        requestingSaveAndPublish: false
      };
    },
    [requestFeedContentAction]: (state) => {
      return {
        ...state,
        requestingFeedContent: true
      };
    },
    [receiveFeedContentAction]: (state, payload) => {
      return {
        ...state,
        requestingFeedContent: false,
        feedContent: payload
      };
    },
    [failFeedContentAction]: (state) => {
      return {
        ...state,
        requestingFeedContent: false,
        feedContent: null
      };
    },
    [requestDeleteFeedContentAction]: (state) => {
      return {
        ...state,
        requestingDelete: true
      };
    },
    [receiveDeleteFeedContentAction]: (state) => {
      return {
        ...state,
        requestingDelete: false
      };
    },
    [failDeleteFeedContentAction]: (state) => {
      return {
        ...state,
        requestingDelete: false
      };
    },
    [clearFeedContentAction]: (state) => {
      return {
        ...state,
        requestingFeedContent: false,
        feedContent: null
      };
    },
    [setContentFeedModeAction]: (state, payload) => {
      return {
        ...state,
        mode: payload.mode,
        feedUUID: payload.uuid
      };
    },
    [clearLocalCopyRemoteImage]: (state) => {
      return {
        ...state,
        feedContent: { ...state.feedContent, coverImgUrl: null }
      };
    },
    [setContentFeedModeAction]: (state, payload) => {
      return {
        ...state,
        mode: payload.mode,
        feedUUID: payload.uuid
      };
    },

    // -------------ArticleTag------------------
    [REQArticleTag]: (state) => {
      
      return {
        ...state,
        REQStrLoadingConFdDetail: true
      };
    },
    [RESArticleTag]: (state, payload) => {
      
      return {
        ...state,
        REQStrLoadingConFdDetail: false,
        ArticleTagList: payload.body,
        totalArticleTag: payload.total
      };
    },
    [FailArticleTag]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: false,
        ArticleTagList: [],
        totalArticleTag: 0
      };
    },
    // -------------Releate Fund------------------
    [REQFund]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: true
      };
    },
    [RESFund]: (state, payload) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: false,
        FundList: payload.data,
        totalFund: payload.total
      };
    },
    [FailFund]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: false,
        FundList: [],
        totalFund: null
      };
    },
    // -------Edit to ContentFeedDetail----------------
    [REQContFdEdt]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: true
      };
    },
    [RESContFdEdt]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: false
      };
    },
    [FailContFdEdt]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: false,
        ConFdEdit: [],
        totalFundCode: 0
      };
    },
    // -------Creat to ContentFeedDetail----------------
    [REQContFdCrt]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: true
      };
    },
    [RESContFdCrt]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: false
      };
    },
    [FailContFdEdt]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: false,
        ConFdEdit: []
      };
    },
    // -------Del to ContentFeedDetail----------------
    [REQContFdDel]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: true
      };
    },
    [RESContFdDel]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: false
      };
    },
    [FailContFdDel]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: false,
        ConFdDel: [],
        totalConFdDel: 0
      };
    },
    // -------Edit to ContentFeed Noti Invest Detail----------------
    [REQContFdNotiInvestEdt]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: true
      };
    },
    [RESContFdNotiInvestEdt]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: false
      };
    },
    [FailContFdNotiInvestEdt]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: false,
        ContFdNotiInvestEdit: [],
        totalContFdNotiInvestEdit: 0
      };
    },

    // -------Del to ContentFeed Noti Invest Detail----------------
    [REQContFdNotiInvestDel]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: true
      };
    },
    [RESContFdNotiInvestDel]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: false
      };
    },
    [FailContFdNotiInvestDel]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: false,
        ContFdNotiInvestDel: [],
        totalContFdNotiInvestDel: 0
      };
    },
    // -------Creat to ContentFeed Noti Invest Detail----------------
    [REQContFdNotiInvestCrt]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: true
      };
    },
    [RESContFdNotiInvestCrt]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: false
      };
    },
    [FailContFdNotiInvestCrt]: (state) => {
      return {
        ...state,
        REQStrLoadingConFdDetail: false,
        ContFdNotiInvestCrt: [],
        totalContFdNotiInvestCrt: 0
      };
    },
    // -----------Mem--------
    [MEMConFdEdt]: (state, payload) => {
      
      return {
        ...state,
        mode: payload.mode,
        PageCurrent: payload.PageCurrent,
        RowDataAt: payload.RowDataAt
      };
    },
    [clearLocalCopyRemoteImage]: (state) => {
      return {
        ...state,
        feedContent: { ...state.feedContent, coverImgUrl: null }
      };
    },
    [CleanConFdDetail]: () => {
      return initialState;
    },
    [REQFundCodeList]: (state) => {
      return {
        ...state,
        REQStrFundCodeList: true
      };
    },
    [RESFundCodeList]: (state, payload) => {
      
      return {
        ...state,
        REQStrFundCodeList: false,
        FundCodeList: payload.data,
        totalFundCode: payload.total
      };
    },
    [FailFundCodeList]: (state) => {
      return {
        ...state,
        REQStrFundCodeList: false,
        FundCodeList: [],
        totalFundCode: 0
      };
    },
  },
  initialState
);

export default conFdDetailReducer;
