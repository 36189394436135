
import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import { config } from "../../../utils/config";
import {
  FailByCodiEdt, FailFundCode, FailIncome, FailOccu, FailRisk, REQByCodiCrt, REQByCodiEdt,
  // -----Fundcode---
  REQFundCode,
  // -----Income---
  REQIncome,
  // -----Occupation---
  REQOccu,
  // -----Risk---
  REQRisk, RESByCodiEdt, RESFundCode, RESIncome, RESOccu, RESRisk
} from "./actions";
import * as actions from "../../Authentication/redux/actions";


// --------------FundRecommend Codi---------------------------------
function* fetchFundCodeWorker() {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);

  try {
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds`,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    
    if (response && response.data) {
      yield put(RESFundCode(response.data));
    } else {
            yield put(FailFundCode());
    }
  } catch (error) {
    
    yield put(FailFundCode());
  }
}
function* fetchRiskWorker() {
  try {
    console.log("fetchRiskWorker:");
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds/recommend_by_fund_risk`,
      {
        headers: {
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    console.log("fetchRiskWorker:",response);
    if (response && response.data) {
      console.log("RESRisk:",response.data);
      yield put(RESRisk(response.data));
    } else {
      yield put(FailRisk());
    }
  } catch (error) {
    yield put(FailRisk());
  }
}
function* fetchOccupationWorker() {
  try {
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/occupations?fields=code%2Cname`,
      {
        method: "GET",  
        headers: config().headers
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    const response = yield request.json();
    
    if (response && response.data) {
      yield put(RESOccu(response.data));
    } else {
      yield put(FailOccu());
    }
  } catch (error) {
    
    yield put(FailOccu());
  }
}
function* fetchIncomeWorker() {
  try {
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/monthlyIncomes?fields=code%2Cname`,
      {
        method: "GET",
        headers: config().headers
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    const response = yield request.json();
    
    if (response && response.data) {
      yield put(RESIncome(response.data));
    } else {
            yield put(FailIncome());
    }
  } catch (error) {
    
    yield put(FailIncome());
  }
}
function* EDTxByCodiDetail({ payload }) {
  try {
    const { FormData, history } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let body = FormData;
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds/recommend_by_condition/edit`,
      body,
      {
        headers: {
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }

    if (response && response.data && response.status === 200) {
      
      yield put(RESByCodiEdt(response));
      history.goBack();
    } else {
      yield put(FailByCodiEdt());
    }
  } catch (error) {
    
    yield put(FailByCodiEdt());
  }
}
function* CRTxByCodiDetail({ payload }) {
  try {
    const { FormData, history } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let body = FormData;
    console.log("body create", body)
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds/recommend_by_condition/create`,
      body,
      {
        headers: {
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    console.log('response', response)
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }

    if (response && response.data && response.status === 200) {
      yield put(RESByCodiEdt(response));
      history.push({ pathname: "/FundRec_Condi" });
    } else {
      yield put(FailByCodiEdt());
    }
  } catch (error) {
    
    yield put(FailByCodiEdt());
  }
}
export function* fundRecCodiDetailSaga() {
  yield all([
    // ------FundRecommend Codi------
    takeLatest(REQFundCode, fetchFundCodeWorker),
    takeLatest(REQRisk, fetchRiskWorker),
    takeLatest(REQOccu, fetchOccupationWorker),
    takeLatest(REQIncome, fetchIncomeWorker),

    takeLatest(REQByCodiEdt, EDTxByCodiDetail),
    takeLatest(REQByCodiCrt, CRTxByCodiDetail)
  ]);
}
