export const list_of_columns = [
  {
    Header: "FUND NO.",
    accessor: "fund_no",
    sortType: "alphanumeric",
    width: 110
  },
  {
    Header: "FUND CODE",
    accessor: "fund_code",
    width: 260
  },
  {
    Header: "ASSET CLASS",
    accessor: "fund_category",
    width: 150
  },
  {
    Header: "RISK LEVEL",
    accessor: "risk_level",
    width: 150
  },
  {
    Header: "NAV (THB)",
    accessor: "navperunit",
    disableSortBy: true,
    width: 110
  },
  {
    Header: "NAV DATE",
    accessor: "nav_effdate",
    disableSortBy: true,
    width: 110
  },
  {
    Header: "SYNC DATE",
    accessor: "sync_updated_at",
    disableSortBy: true,
    width: 110
  },
  {
    Header: "FUND STATUS",
    accessor: "active",
    width: 140
  },
  {
    Header: "ACTION",
    accessor: "action",
    disableSortBy: true,
    width: 150
  }
];
