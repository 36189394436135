import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { WarningModal } from "../../components/Modal";
import Images from "../../utils/styles/images";
import Select from "react-select";

import "./style.scss";

import { REQSrchRecDetailEdt, REQFundTag } from "./redux/actions";

function SrchRecDetail(poPrp) {
  const {
    REQFundTag,
    FunTagOp,

    REQSrchRecDetailEdt,
    RowDataAt,
    history
  } = poPrp;

  const GETxFundTag = () => {
    REQFundTag();
  };

  useEffect(() => {
    GETxFundTag();
  }, []);

  const [isPageReload, setIsPageReload] = useState(false);
  const [isUnloading, setIsUnloading] = useState(false);

  useEffect(() => {
    const savePageState = () => {
      const dataFromList = JSON.parse(localStorage.getItem('currentSearchRec'))
      console.log("dataFromList", dataFromList)
      localStorage.setItem('currentSearchRec', JSON.stringify(dataFromList));
    };

    // Save initial state
    savePageState();
    
    const handleBeforeUnload = () => {
      savePageState();
    };
  
    const handlePageShow = (event) => {
      if (!event.persisted) {
        const storedData = JSON.parse(localStorage.getItem('currentSearchRec'));
        if (storedData) {
          console.log("stored", storedData)
        }
      }
    };

    // Check for page reload using Performance API
    const navigationType = window.performance.getEntriesByType("navigation")[0]?.type;
    if (navigationType === 'reload') {
      setIsPageReload(true);
      const storedData = JSON.parse(localStorage.getItem('currentSearchRec'));
      if (storedData) {
        history.push(`/SrchRecDetail`);
       
      }
    }

    // Handle navigation events
    const unlisten = history.listen((location, action) => {
      if (action === 'POP' && !isPageReload && !isUnloading) {
        history.replace({
          pathname: "/SearchRec",
        });
      }
    });

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('pageshow', handlePageShow);
      unlisten();

      // Only clean up stored data if we're not reloading and not unloading
      if (!isPageReload && !isUnloading) {
        localStorage.removeItem('currentSearchRec');
      }
    };
  }, []);

  const oNeedAuth = JSON.parse(localStorage.getItem("NeedAuth"));
  const [oShwEdtAlr, SETxShwEdtAlr] = useState(false);
  // ------------------IniText----------
  const [oVal2, SETxVal2] = useState(
    RowDataAt === null
      ? []
      : RowDataAt.map(x => ({
          id: x.id,
          fundTagId: x.fundTagId,
          fundTag: x.fundTag,
          modify: x.created_by
        }))
  );
  
  // ----------Prepare data set to Funtag element option-------
  let oFundTagOp = FunTagOp.map(d => ({
    value: d.id,
    label: d.tagNameEN
  }));

  let oRwType = oVal2 ? oVal2.map(a => a.fundTagId) : null;
  oFundTagOp = oVal2
    ? oFundTagOp.filter(x => !oRwType.includes(x.value))
    : oFundTagOp;

  // ---------------Select option-------------
  const [oSelFundTagOp, SETxSelFundTagOp] = useState({
    value: oFundTagOp.length === 0 ? "" : oFundTagOp[0].value,
    label: oFundTagOp.length === 0 ? "" : oFundTagOp[0].label
  });
  const SETxOnChgFundTagSelect = e => {
    SETxSelFundTagOp({ value: e.value, label: e.label });
  };
  // ---------------Event------------
  function CRTxAdd() {
    // ------Set State --------
    if (oSelFundTagOp.value === "") {
      return;
    }
    SETxVal2(arr => [
      ...arr,
      {
        id: oVal2.length === 0 ? 1 : oVal2.length + 1,
        fundTagId: oSelFundTagOp.value,
        fundTag: oSelFundTagOp.label,
        modify: oNeedAuth?.Email
      }
    ]);

    // -----------------Remove Select ที่เลือกไป--------------------------------------
    let aFlt = oFundTagOp.filter(c => c.value !== oSelFundTagOp.value);

    // ----------Set Select--------------
    SETxSelFundTagOp({
      value: aFlt.length === 0 ? "" : aFlt[0].value,
      label: aFlt.length === 0 ? "" : aFlt[0].label
    });
  }
  const DELxCard = (ptFundTagId, ptFundTag) => () => {
    
    SETxSelFundTagOp({
      value: ptFundTagId,
      label: ptFundTag
    });
    SETxVal2(oVal2.filter(x => x.fundTagId !== ptFundTagId));
  };
  return (
    <Fragment>
      <div className="fundrec-feed-form-container">
        <div className="fundrec-feed-form-buttons-container">
          <button
            className="fundrec-detail-cancel"
            onClick={() => history.goBack()}
          >
            CANCEL
          </button>
          <button
            type="submit"
            onClick={() => SETxShwEdtAlr(true)}
            className="content-feed-form-publish-button"
          >
            SAVE
          </button>
          <WarningModal
            show={oShwEdtAlr}
            onHide={() => SETxShwEdtAlr(false)}
            onHandleGo={() => {
              SETxShwEdtAlr(false);
              let oSendDataAPI = {
                fundTagLists: oVal2
              };
              REQSrchRecDetailEdt({
                FormData: oSendDataAPI,
                history
              });
            }}
            onHandleCancel={() => {
              SETxShwEdtAlr(false);
            }}
            type={"Disapprove"}
            status={"1"}
            title={"Are you sure you want to edit?"}
          />
        </div>

        <div className="fundrec-feed-form-inputs-containerRisk">
          {/*------------------GEN card--------------------------*/}
          {oVal2.map((x, index) => (
            <div
              key={index}
              style={{ backgroundColor: "#EDEDED", marginTop: 20 }}
            >
              <button
                name={index}
                className="fundrec-feed-form-delete-button"
                onClick={DELxCard(x.fundTagId, x.fundTag)}
              >
                <img
                  src={Images.closeButton.close}
                  style={{
                    marginBottom: 3,
                    width: 18,
                    height: 18
                  }}
                  alt="close"
                />
              </button>
              <div
                style={{ paddingTop: 15, paddingLeft: 40, paddingRight: 40 }}
              >
                <div className="labelInput">
                  <span
                    style={{
                      paddingRight: 142,
                      fontFamily: "Lato",
                      color: "#000000"
                    }}
                  >
                    Fund Tag
                  </span>
                  <div style={{ width: 599 }}>
                    <Select
                      name="ocbFunRecCodiCode1111"
                      value={[{ value: x.fundTagId, label: x.fundTag }]}
                      maxMenuHeight={150}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isDisabled={true}
                    />
                  </div>
                </div>

                <div className="fundrec-feed-form-input-spacer" />
              </div>
            </div>
          ))}

          <div className="fundrec-feed-form-input-spacer" />
          {/*------------------------Input Card---------------------------*/}
          <div
            style={{
              backgroundColor: "#EDEDED",
              paddingLeft: 40,
              paddingRight: 40,
              paddingTop: 15,
              paddingBottom: 15
            }}
          >
            <div className="labelInput">
              <span
                style={{
                  paddingRight: 142,
                  fontFamily: "Lato",
                  color: "#000000"
                }}
              >
                Fund Tag
              </span>
              <div style={{ width: 599 }}>
                <Select
                  name="ocbFunRecCodiCode1"
                  options={oFundTagOp}
                  maxMenuHeight={150}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select FunTag"
                  onChange={SETxOnChgFundTagSelect}
                  value={oSelFundTagOp}
                />
              </div>
            </div>
          </div>

          <button
            style={{
              marginTop: 20,
              fontSize: 12,
              width: 150,
              height: 40,
              paddingTop: 4
            }}
            className="fundrec-detail-cancel"
            onClick={CRTxAdd}
          >
            <img
              src={Images.buttonIcon.plus}
              style={{
                marginBottom: 3,
                marginRight: 5,
                width: 16,
                height: 16
              }}
              alt="delete"
            />
            <span>ADD FUND TAG</span>
          </button>
        </div>
      </div>
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.srchRecDetailReducer
  }),
  {
    REQSrchRecDetailEdt,
    REQFundTag
  }
);

export default compose(
  withConnect,
  withRouter
)(SrchRecDetail);
