import React from "react";
import { Modal } from "react-bootstrap";
import * as Button from "../../components/Buttons";
import { ButtonPanel } from "../../components/DoubleButtonsPanel";
import { transformRawDateForErrorModal } from "../../utils/functions/date_formatter";
import "./style.scss";
export const ErrorModal = props => {
  return (
    <Modal {...props} bsSize="small" aria-labelledby="contained-modal-title-sm">
      <div className="close-button-wrap">
        <Button.CloseButton onClick={props.onHide} />
      </div>
      <div className="text-modal">
        <h4>{props.errorMessageText}</h4>
      </div>
    </Modal>
  );
};

const refreshPage = (props) => {
  const {history, clearErrorMessage} = props;
  clearErrorMessage();
  history.push('/temp');
  history.goBack();
};

export const ErrorModalWithBullet = props => {
  const errorDetails = props.errormessagetext.data !== undefined ? true : false;
  return (
    <Modal {...props} bsSize="small" aria-labelledby="contained-modal-title-sm">
      <div className="close-button-wrap">
        <Button.CloseButton onClick={props.onHide} />
      </div>
      <div className="text-modal">
        <h4>{props.errormessagetext.header}</h4>
        {
          errorDetails === true
          ? (
            props.errormessagetext.data.map((element, index) => {
              return <small key={index}>{element.title} (As of {transformRawDateForErrorModal(element.asof)})</small>;
            }
            )
          )
          : ""
        }
        {
          errorDetails === true
          ? (
            <button
              className={"client-detail-refresh-button"}
              onClick={() => refreshPage(props.props)}
            >
              Refresh
            </button>
          )
          : ""
        }
      </div>
    </Modal>
  );
};

export const SaveFundModal = props => {

  const onHandleClick = () => {
    props.onHide();
  };
  const actionText = props.fundAction === "edit" ? "saved" : "created";

  return (
    <Modal {...props} bsSize="small" aria-labelledby="contained-modal-title-sm">
      <div className="close-button-wrap">
        <Button.CloseButton onClick={onHandleClick} />
      </div>
      <div className="text-modal">
        <h4 color="green">Fund is successfully {actionText}!!!</h4>
      </div>
    </Modal>
  );
};

export const WarningModal = ({
  onHide,
  status,
  onHandleCancel,
  onHandleGo,
  title,
  body,
  textInModal,
  ...props
}) => {

  const finalTitle = JSON.stringify(title);
  const finalBody = JSON.stringify(body);
  return (
    <Modal {...props} bsSize="small" aria-labelledby="contained-modal-title-sm">
      <div className="close-button-wrap">
        <Button.CloseButton onClick={onHide} />
      </div>
      <div className="text-modal m-p">
        <h4 className="pb">{finalTitle}</h4>
          {finalBody ? <h5 className="text-modal-body-text">{finalBody}</h5> : null}
          {textInModal && <div><h5>{textInModal}</h5></div>}
      </div>
      <div className="button-modal-wrap">
        <ButtonPanel
          onHandleClickRedBorderButton={onHandleCancel}
          onHandleClickRedButton={() => onHandleGo(status)}
          contentRedBorderButton="NO"
          contentRedButton="YES"
          width_1="148px"
          width_2="148px"
          height="40px"
        />
      </div>
    </Modal>
  );
};


export const AlertModal = ({
  onHide,
  status,
  onHandleOK,
  onHandleExtend,
  title,
  body,
  showExtendButton = false,
  okButtonText = "OK",
  extendButtonText = "Extend",
  ...props
}) => {
  return (
    <Modal {...props} onHide={onHide} bsSize="small" aria-labelledby="contained-modal-title-sm">
      <div className="close-button-wrap">
        <Button.CloseButton onClick={onHide} />
      </div>
      <div className="text-modal m-p">
        <h4 className="pb1">{title}</h4>
        {body && <h5 className="text-modal-body-text">{body}</h5>}
        <div className="button-modal-wrap">
          <button
            className="btn-alert"
            onClick={onHandleOK}
            style={{ width: 148, height: 40, marginTop: 40, marginRight: showExtendButton ? 10 : 0 }}
          >
            {okButtonText}
          </button>
          {showExtendButton && (
            <button
              className="btn-alert btn-extend"
              onClick={onHandleExtend}
              style={{ width: 148, height: 40, marginTop: 40 }}
            >
              {extendButtonText}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export const WarningDeleteModal = ({
  onHide,
  type,
  status,
  onHandleCancel,
  onHandleGo,
  title,
  body,
  ...props
}) => {
  return (
    <Modal {...props} bsSize="small" aria-labelledby="contained-modal-title-sm"  >
      <div className="close-button-wrap">
        <Button.CloseButton onClick={onHide} />
      </div>
      <div className="text-modal m-p"  >
        <h4 className="pb">{ title ? title : `Are you sure you want to ${type.toLowerCase()} the
          Client KYC?`}</h4>
          {body ? <h5 className="text-modal-body-text">{body}</h5> : null}
      </div>
      <div className="button-modal-wrap">
        <ButtonPanel
          onHandleClickRedBorderButton={onHandleCancel}
          onHandleClickRedButton={() => onHandleGo(status)}
          contentRedBorderButton="NO"
          contentRedButton="YES"
          width_1="148px"
          width_2="148px"
          height="40px"
        />
      </div>
  
    </Modal>
  );
};

export const WarningLeaveEditModal = ({
  onHide,
  onHandleCancel,
  onHandleGo,
  ...props
}) => {
  return (
    <Modal {...props} bsSize="small" aria-labelledby="contained-modal-title-sm">
      <div className="close-button-wrap">
        <Button.CloseButton onClick={onHide} />
      </div>
      <div className="text-modal">
        <h4 className="pb">Do you want to save changes?</h4>
      </div>
      <div className="button-modal-wrap">
        <ButtonPanel
          onHandleClickRedBorderButton={onHandleGo}
          onHandleClickRedButton={onHandleCancel}
          contentRedBorderButton="NO"
          contentRedButton="YES"
          width_1="148px"
          width_2="148px"
          height="40px"
        />
      </div>
    </Modal>
  );
};
