import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { requestATSList } from "./redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import Images from "../../utils/styles/images";
import DateInput from "./DateInput";
import Pagination from "./Pagination";
import "./style.scss";

function RequestATSDetails(props) {
  const { requestATSList, requestingATSList, atsList, totalATS } = props;
  const columns = React.useMemo(() => list_of_columns, []);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [pageNo, setPageNo] = useState("1");
  const [sortDir, setSortDir] = useState("DESC");
  const [sortingNo, setSortingNo] = useState("0");
  const [showFilterInstructions, setShowFilterInstructions] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const getATS = () => {
    requestATSList({
      startDate,
      endDate,
      pageNo,
      sortDir,
      sortingNo,
      searchQuery
    });
  };

  useEffect(() => {
    // every time sort direction changes or page changes, we call api again (also only allow it to run if dates are set)
    if (!showFilterInstructions) {
      getATS();
    }
  }, [sortDir, pageNo]);

  const clearSearch = () => {
    setPageNo(1);
    setSearchValue("");
    setSearchQuery("");
    setSortDir("DESC");
    setSortingNo("0");
    !showFilterInstructions &&
      requestATSList({
        startDate,
        endDate,
        pageNo: "1",
        sortDir: "DESC",
        sortingNo: "0",
        searchQuery: ""
      });
  };

  const handlePageChange = page => {
    setPageNo(page);
  };

  const handleActionHeaderClick = cell => {
    /*
      sortingNo:
      0 : TransDate
      1 : UnitholderId
      2 : UnitholderName
      3 : BankName
      4 : Status
      5 : AccountNo
      6 : AccountName
    */
    if (
      cell === "UNITHOLDER ID" ||
      cell === "FULL NAME" ||
      cell === "BANK NAME" ||
      cell === "ACCOUNT NO." ||
      cell === "ACCOUNT NAME" ||
      cell === "REQUEST DATE" ||
      cell === "STATUS"
    ) {
      if (sortDir === "ASC") {
        setSortDir("DESC");
      } else if (sortDir === "DESC") {
        setSortDir("ASC");
      }

      if (cell === "REQUEST DATE") {
        setSortingNo("0");
      } else if (cell === "UNITHOLDER ID") {
        setSortingNo("1");
      } else if (cell === "FULL NAME") {
        setSortingNo("2");
      } else if (cell === "BANK NAME") {
        setSortingNo("3");
      } else if (cell === "STATUS") {
        setSortingNo("4");
      } else if (cell === "ACCOUNT NO.") {
        setSortingNo("5");
      } else if (cell === "ACCOUNT NAME") {
        setSortingNo("6");
      }
    }
  };

  return (
    <Fragment>
      {requestingATSList ? (
        <div className="ats-list-progress">
          <CircularProgress />
          <p className="ats-list-progress-text">
            {requestingATSList ? "Retrieving ATS list..." : null}
          </p>
        </div>
      ) : null}
      <div className="ats-search-header">
        <div className="ats-search-container">
          <div className="ats-search">
            <img
              src={Images.search.search}
              alt="search-icon"
              className="ats-list-begin-search"
              onClick={() =>
                searchQuery.length > 0 && !showFilterInstructions && getATS()
              }
            />
            <input
              placeholder="Search by Unit holder ID"
              className="ats-search-bar"
              value={searchValue}
              onChange={event => {
                setSearchValue(event.target.value.trim());
                setSearchQuery(event.target.value.trim());
              }}
              onKeyDown={event => {
                if (event.keyCode === 13 && !showFilterInstructions) {
                  setSearchQuery(event.target.value.trim());
                  getATS();
                }
              }}
            />
            {searchQuery && searchQuery.length > 0 ? (
              <img
                src={Images.closeButton.close}
                alt="cancel-search"
                className="ats-details-cancel-search"
                onClick={() => clearSearch()}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="ats-date-range-container">
        <p className="ats-date-range-filter-date">FILTER DATE</p>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
        />
        <p className="ats-date-range-separator">-</p>
        <DatePicker
          selected={endDate}
          onChange={date => moment(date).isAfter(startDate) && setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
        />
        <button
          onClick={() => {
            getATS();
            setShowFilterInstructions(false);
          }}
          className="ats-date-range-filter"
          disabled={!startDate || !endDate}
        >
          FILTER
        </button>
      </div>

      <div className="ats-list-container">
        {showFilterInstructions ? (
          <div className="ats-filter-date-instructions">
            <img src={Images.transactions.filter} alt="filter-date" />
            <p className="ats-Filter-date-to-view">
              Filter date to view request ATS details
            </p>
            <p className="ats-Select-the-date-rang">
              Select the date range using the date picker above to view request
              ATS details.
            </p>
          </div>
        ) : (
          <Table
            data={atsList}
            columns={columns}
            handleActionHeaderClick={handleActionHeaderClick}
            pageIndex={pageNo}
            pageSize={10}
          />
        )}
      </div>

      {!showFilterInstructions && totalATS > 10 ? (
        <Pagination
          handlePageChange={handlePageChange}
          total={totalATS}
          pageSize={10}
          defaultPage={1}
        />
      ) : null}
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.atsReducer
  }),
  {
    requestATSList
  }
);

export default compose(
  withConnect,
  withRouter
)(RequestATSDetails);
