export default {
  "app.components.SideMenu.LeftMenu.clients": "clients- TH",
  "app.components.SideMenu.LeftMenu.tag": "tag- TH",
  "app.components.SideMenu.LeftMenu.SearchRec ": "Search Recommend-TH",
  "app.components.SideMenu.LeftMenu.fundList": "fund list - TH",

  "app.components.SideMenu.LeftMenu.FundRec": "Fund Recommend -TH",
    "app.components.SideMenu.LeftMenu.FundRec_FundMan": "By Fund Manager-TH",
    "app.components.SideMenu.LeftMenu.FundRec_Condi": "By Condition-TH",
    "app.components.SideMenu.LeftMenu.FundRec_Risk": "SIMPLE ADVISORY-TH",

  "app.components.SideMenu.LeftMenu.transaction": "transaction - TH",
    "app.components.SideMenu.LeftMenu.transactionDetails": "Transaction details- TH",
    "app.components.SideMenu.LeftMenu.requestATSDetails": "Transaction details- TH",

  "app.components.SideMenu.LeftMenu.ConFd": "content feed- TH",
    "app.components.SideMenu.LeftMenu.ConFd_NewsOld": "ข่าวสาร",
    "app.components.SideMenu.LeftMenu.ConFd_Invest": "มุมมองการลงทุน",
    "app.components.SideMenu.LeftMenu.ConFd_News": "ข่าวสารและรางวัล",
    "app.components.SideMenu.LeftMenu.ConFd_ConInvest": "สาระการลงทุน",
    "app.components.SideMenu.LeftMenu.ConFd_ManualInvest": "คู่มือนักลงทุน",
    "app.components.SideMenu.LeftMenu.ConFd_NotiInvest": "ประกาศกองทุนรวม",
    "app.components.SideMenu.LeftMenu.ConFd_PVDNewsReport": "รายงาน ข่าวสารกองทุนสำรองเลี้ยงชีพ",
    "app.components.SideMenu.LeftMenu.ConFd_PVDPlaning": "คลังความรู้ด้านการวางแผนการเงิน",
    "app.components.SideMenu.LeftMenu.ConFd_PVDFactSheet": "หนังสือชี้ชวนส่วนสรุปกองทุนสำรองเลี้ยงชีพ",
    
    "app.components.SideMenu.LeftMenu.ContFS": "content feed sort- TH",
  
  "app.container.ClientDetail.Conservative": "อนุรักษ์นิยม (ระดับ 1)",
  "app.container.ClientDetail.ModerateConservative": "ยับยั้งปานกลาง (ระดับ 2)",
  "app.container.ClientDetail.Moderate": "ปานกลาง (ระดับ 3)",
  "app.container.ClientDetail.ModerateAggressive": "ปานกลางก้าวร้าว (ระดับ 4)",
  "app.container.ClientDetail.Aggressive5": "ก้าวร้าว (ระดับ 5)",
  "app.container.ClientDetail.Aggressive6": "ก้าวร้าว (ระดับ 6)"
};
