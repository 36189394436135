import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQFundRecList, CleanUpFundRecList } from "./redux/actions";
import { setModeFundDetails } from "../FundDetail/redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import { selectedObjectUuid } from "../../components/BreadCrumbs/redux/actions";
import UploadDocument from "./upload_document";
import "./style.scss";

function FundRecList(props) {
  const {
    REQFundRecList,
    requestingFundsList,
    fundsList,
    CleanUpFundRecList,
    history,
    setModeFundDetails,
    selectedObjectUuid
  } = props;
  const columns = React.useMemo(() => list_of_columns, []);
  const [showUploadDocs, setShowUploadDocs] = useState(false);
  const [sortOrder, setSortOrder] = useState({ key: "fund_no", direction: "ASC" });
  const [dataSort, setData] = useState(fundsList);

  const getFunds = () => {
    REQFundRecList();
  };
  useEffect(() => {
    setData(fundsList);
  }, [fundsList]);

  useEffect(() => {
    // on component did mount, we call the API
    getFunds();
    handleHeaderSort("FUND NO.")
    // on component unmount, do cleanup
    return () => {
      CleanUpFundRecList();
    };
  }, []);

  const handleActionClick = ({ mode, rowData, pageIndex }) => {
    if (mode === "view") {
      setModeFundDetails({
        mode: "VIEW",
        detailsFromList: rowData,
        PageCurrent: pageIndex
      });
      selectedObjectUuid(rowData.fund_code);
      localStorage.setItem('currentFundrecMan', JSON.stringify({
        mode: "VIEW",
        detailsFromList: rowData,
        PageCurrent: pageIndex,
        rowData: rowData,
        from: "FundRec"
      }))
      history.push(`/FundRec_FundManView/${rowData.fund_no}`);
    }

    if (mode === "edit") {
      setModeFundDetails({
        mode: "EDIT",
        detailsFromList: rowData,
        PageCurrent: pageIndex
      });
      selectedObjectUuid(rowData.fund_code);
      localStorage.setItem('currentFundrecMan', JSON.stringify({
        mode: "EDIT",
        detailsFromList: rowData,
        PageCurrent: pageIndex,
        rowData: rowData,
        from: "FundRec"
      }))
      history.push(`/FundRec_FundManEdit/${rowData.fund_no}`);
    }
  };
  const sortMappings = {
    "FUND NO.": { 
      ascending: "fund_no ASC", 
      descending: "fund_no DESC"
    },
    "FUND CODE": { 
        ascending: "fund_code ASC",
        descending: "fund_code DESC" 
    },
    "RISK LEVEL": { 
        ascending: "risk_level ASC",
        descending: "risk_level DESC" 
    },
    "FUND STATUS": { 
        ascending: "active ASC", 
        descending: "active DESC"
    },
    "ASSET CLASS": {
        ascending: "fund_category ASC",
        descending: "fund_category DESC"
    }
  };
  const handleHeaderSort = (header) => {
    if (sortMappings[header]) {
      console.log("SortMapping",sortMappings[header].ascending)
      console.log("sortorderTop",sortOrder)
      const newSortOrder = sortOrder === sortMappings[header].ascending 
        ? sortMappings[header].descending 
        : sortMappings[header].ascending;

      console.log("newSortOrder:", newSortOrder)
      setSortOrder(newSortOrder);
      sortData(newSortOrder);
    }
  };

  const sortData = (sortOrder) => {
    const [key, direction] = sortOrder.split(' ');
    console.log("key: ", key)
    console.log("direc: ", direction)
    const sortedData = [...dataSort].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ASC' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ASC' ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  return (
    <Fragment>
      <div className="fundrec-buttons-container" style={{ marginBottom: 50 }}>
      </div>

      {showUploadDocs ? (
        <UploadDocument setShowUploadDocs={setShowUploadDocs} />
      ) : null}
      {requestingFundsList ? (
        <div className="funds-list-progress">
          <CircularProgress />
          <p className="funds-list-progress-text">
            {requestingFundsList ? "Retrieving Funds list..." : null}
          </p>
        </div>
      ) : null}

      <Table
        data={dataSort}
        columns={columns}
        handleActionClick={handleActionClick}
        handleActionHeaderClick={handleHeaderSort}
        history={history}
      />
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.fundRecListReducer
  }),
  {
    REQFundRecList,
    CleanUpFundRecList,
    selectedObjectUuid,
    setModeFundDetails
  }
);

export default compose(
  withConnect,
  withRouter
)(FundRecList);
