import { put, call, all, takeLatest, select } from "redux-saga/effects";
import {
  // -----------------Import view-------
  REQImpRGT2B2CList,
  RESImpRGT2B2CList,
  FailImpRGT2B2CList,
  // -------------------UPD--------------
  REQImpRGT2B2CUPD,
  RESImpRGT2B2CUPD,
  FailImpRGT2B2CUPD
} from "./actions";
import moment from "moment";
import * as actions from "../../Authentication/redux/actions";

function* fetchIMPRGTtoB2CListWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { page, tStartD, tEndD, tSrch, tOpSrch, tSortH } = payload;
    const tStartD1 = moment(tStartD).format("YYYY-MM-DD");
    let tEndD1 = moment(tEndD).format("YYYY-MM-DD");
    tEndD1 = tEndD1 + " 23:59";

    let tFileNameSrch = "";

    switch (tOpSrch) {
      case "FileName":
        tFileNameSrch = tSrch;
        break;

      default:
        break;
    }
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin/impRGTtoB2C?page=${page}`,
      {
        method: "PUT",  
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body: JSON.stringify({
          // ---Search----
          fileNameSrch: tFileNameSrch,

          startDate: tStartD1,
          endDate: tEndD1,
          sortH: tSortH
        })
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    const response = yield request.json();
    
    if (response) {
      yield put(RESImpRGT2B2CList(response));
    } else {
            yield put(RESImpRGT2B2CList());
    }
  } catch (error) {
    
    yield put(FailImpRGT2B2CList());
  }
}
function* IMPxRGTtoB2C({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { oImpFileSND } = payload;
    const oFormData = new FormData();
    for (let i = 0; i < oImpFileSND.length; i++) {
      oFormData.append("ImpCst", oImpFileSND[i]);
    }
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin/impRGTtoB2CUPD`,
      {
        method: "PUT",  
        headers: {
          // "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body: oFormData
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    const response = yield request.json();
    
    if (response) {
      yield put(RESImpRGT2B2CUPD(response));
    } else {
            yield put(RESImpRGT2B2CUPD());
    }
  } catch (error) {
    
    yield put(FailImpRGT2B2CUPD());
  }
}
export function* impRGTtoB2CListSaga() {
  yield all([
    takeLatest(REQImpRGT2B2CList, fetchIMPRGTtoB2CListWorker),
    takeLatest(REQImpRGT2B2CUPD, IMPxRGTtoB2C)
  ]);
}
