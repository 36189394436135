import React from "react";
import "./style.scss";

export default function BasicButton({
  children,
  onClick = () => {},
  disabled = false,
  variant = "full", // "full" or "outlined"
  bgColor = "#0275b6",
  textColor = "#ffffff",
  borderColor = "#0275b6",
  textSize = "18px",
  width = "200px",
  height = "40px",
}) {
  const buttonStyles = {
    backgroundColor: variant === "full" ? bgColor : "transparent",
    color: textColor,
    border: variant === "outlined" ? `2px solid ${borderColor}` : "none",
    fontSize: textSize,
    width: width,
    height: height,
    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? "not-allowed" : "pointer",
  };

  return (
    <button
      className={`basic-button ${disabled ? "basic-button-disabled" : ""}`}
      onClick={onClick}
      style={buttonStyles}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
