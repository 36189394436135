// -------------------------Tab1--------------------
export const aColTab1 = [
  {
    Header: "NO.",
    disableSortBy: true,
     textAlign:"left",
     paddingLeft:10,
     width: 80,
     display:""
  },
  {
    Header: "UNITHOLDER TYPE",
    accessor: "unitholder_type",
    disableSortBy: false,
    textAlign:"left",
    paddingLeft:20,
    width: 175,
    display:""
  },
  {
    Header: "UNITHOLDER ID",
    accessor: "unitholder_id",
   disableSortBy: false,
    textAlign:"left",
    paddingLeft:5,
    width: 150,
    display:""
  },
  {
    Header: "STATUS",
    accessor: "unitStatus",
    disableSortBy: false,
    textAlign:"left",
    paddingLeft:0,
    width: 110,
    display:""
  },
  {
    Header: "FROM",
    accessor: "FTFrmTable",
    disableSortBy: true,
    textAlign:"left",
    paddingLeft:0,
    width: 100,
    display:"none"
  },
  {
    Header: "RISK LEVEL",
    accessor: "level",
    disableSortBy: false,
    textAlign:"left",
    paddingLeft:0,
    width: 120,
    display:""
  },
  {
    Header: "TYPE",
    accessor: "unitholder_channel",
    disableSortBy: false,
    textAlign:"left",
    width: 100,
    paddingLeft:0,
    display:""
  },
  {
    Header: "CREATED AT",
    accessor: "client_created_at",
    disableSortBy: false,
    textAlign:"left",
    paddingLeft:20,
    width: 180,
    display:""
  },
  {
    Header: "UPDATED DATE",
    accessor: "updated_at",
    disableSortBy: false,
    textAlign:"left",
    paddingLeft:20,
    width: 180,
    display:""
  }
  
];



// -------------------------Tab2--------------------
export const aColTab2 = [
  {
    Header: "NO.",
     disableSortBy: true,
     textAlign:"left",
     paddingLeft:5,
     width: 40
  },
  {
    Header: "DEVICE NAME",
    accessor: "deviceName",
    textAlign:"left",
    width: 140,
    paddingLeft:0
  },
  {
    Header: "APP VERSION",
    accessor: "appVersion",
    textAlign:"left",
    paddingLeft:0,
    width: 120
  },
  {
    Header: "REGISTER DATE",
    accessor: "registeredAt",
    textAlign:"left",
    paddingLeft:5,
    width: 130
  },
  {
    Header: "LAST LOGIN DATE",
    accessor: "deregisteredAt",
    textAlign:"left",
    paddingLeft:0,
    width: 160
  },
  {
    Header: "LAST LOGIN ISP",
    accessor: "ISP",
    textAlign:"left",
    paddingLeft:20,
    width: 160
  }
 
];


// -------------------------Tab3--------------------
export const aColTab3 = [
  {
    Header: "CONSENT DATE",
    accessor: "created_at",
   disableSortBy: false,
    textAlign:"left",
    paddingLeft:5,
    width: 140,
    display:""
  },
  {
    Header: "CONSENT TYPE",
    accessor: "consent_type",
    disableSortBy: false,
    textAlign:"left",
    paddingLeft:0,
    width: 150,
    display:""
  },
  {
    Header: "CONSENT VERSION",
    accessor: "version",
    disableSortBy: true,
    textAlign:"left",
    paddingLeft:10,
    width: 170,
    display:""
  },
  {
    Header: "CONSENT STATUS",
    accessor: "status",
    disableSortBy: false,
    textAlign:"left",
    width: 160,
    paddingLeft:10,
    display:""
  }, 
   {
    Header: "RECORD DATE(RGT)",
    accessor: "updated_at_rgt",
    disableSortBy: false,
    textAlign:"left",
    width: 170,
    paddingLeft:10,
    display:""
  },
  {
    Header: "RECORD DATE(SALESFORCE)",
    accessor: "saleforce_trans_at",
    disableSortBy: false,
    textAlign:"left",
    width: 185,
    paddingLeft:10,
    display:""
  },
  {
    Header: "CONSENT NO",
    accessor: "consent_no",
    disableSortBy: false,
    textAlign:"left",
    paddingLeft:10,
    width: 126,
    display:""
  },
  {
    Header: "CHANNEL",
    accessor: "channel",
    disableSortBy: false,
    textAlign:"left",
    width: 100,
    paddingLeft:10,
    display:""
  }

];