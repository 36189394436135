import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQUntHldList, requestDisapproveClient } from "./redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import { selectedObjectUuid } from "../../components/BreadCrumbs/redux/actions";
import {
  setCurrentCustomerUntHldDetail,
  CleanUpUntHldSrch,
} from "../UntHldDetail/redux/actions";

import Images from "../../utils/styles/images";
import { WarningModal } from "../../components/Modal";
import "./style.scss";
import Pagination from "./Pagination";
function UntHldList(props) {
  const {
    REQUntHldList,
    REQStrUntHldList,
    UntHldList,
    totalUntHldAct,
    requestDisapproveClient,
    setCurrentCustomerUntHldDetail,
    CleanUpUntHldSrch,
    history,
  } = props;
  const columns = React.useMemo(() => list_of_columns, []);
  const [tSrch, SETxSrch] = useState("");
  const [tOpSrch, SETxOpSrch] = React.useState("Email");
  const [nPageIndex, SETxPageIndex] = useState(0);

  //Filter doc states
  const [tFltDocSta, SETtFltDocSta] = useState("");
  const [isAllDocStaChecked, setIsAllDocStaChecked] = useState(true);
  const [tSortH, SETtSortH] = useState("submission_at DESC");

  //Filter acc states
  const [tFltAccSta, SETtFltAccSta] = useState("");
  const [isAllAccStaChecked, setIsAllAccStaChecked] = useState(true);
  //Filter convertuser states
  const [tFltConvertUsr, SETtFltConvertUsr] = useState("");
  const [isDefaultConvert, setIsDefaultConvert] = useState(true);

  const [isShow, setIsShow] = useState(false);
  const [clientRowData, setClientRowData] = useState({
    customerUuid: "",
    unitholderid: "",
    firstName: "",
    history: "",
  });
  // Handle docs
  const handleAllDocStatusChange = () => {
    if (isAllDocStaChecked == true) {
      SETtFltDocSta([]);
    } else if (isAllDocStaChecked == false) {
      SETtFltDocSta([]);
    }
    // CALLxUntHld()
    setIsAllDocStaChecked(!isAllDocStaChecked);
   
  };

  const handleDocStatusChange = (status) => {
    setIsAllDocStaChecked(false)
    let newStatuses = [...tFltDocSta];

    if (newStatuses.includes(status)) {
      newStatuses = newStatuses.filter((s) => s !== status);
    } else {
      newStatuses.push(status);
    }

    SETtFltDocSta(newStatuses);

    // if (newStatuses.length === 4&& isAllDocStaChecked == false) {
    //   setIsAllDocStaChecked(true);
    // } else if (isAllDocStaChecked == true) {
    //   SETtFltDocSta(["1", "2", "3", "0"])
    // } else {
    //   setIsAllDocStaChecked(false);
    // }

  };
  //Handle accounts
  const handleAllAccStatusChange = () => {
    if (isAllAccStaChecked == true) {
      SETtFltAccSta([]);
    } else if (isAllAccStaChecked == false) {
      SETtFltAccSta([]) 
    }
    // CALLxUntHld()
    setIsAllAccStaChecked(!isAllAccStaChecked);
  };

  const handleAccStatusChange = (status) => {
    setIsAllAccStaChecked(false)
    let newStatuses = [...tFltAccSta];

    if (newStatuses.includes(status)) {
      newStatuses = newStatuses.filter((s) => s !== status);
    } else {
      newStatuses.push(status);
    }

    SETtFltAccSta(newStatuses);

    // if (newStatuses.length === 6 && isAllAccStaChecked == false) {
    //   setIsAllAccStaChecked(true);
    // } else {
    //   setIsAllAccStaChecked(false);
    // }
  };

  // Handle Convert

  const handleConvertUsrChange = (status) => {
    let newStatuses = [...tFltConvertUsr];

    if (newStatuses.includes(status)) {
      newStatuses = newStatuses.filter((s) => s !== status);
    } else {
      newStatuses.push(status);
    }
    setIsDefaultConvert(false);
    SETtFltConvertUsr(newStatuses);
  };

  const CALLxUntHld = () => {
    CleanUpUntHldSrch();
    if (history.location.PageCurrent === undefined) {
      SETxPageIndex(0);
      REQUntHldList({
        page: "1",
        tSrch,
        tOpSrch,
        SortH: tSortH,
        FltDocSta: isAllDocStaChecked ? "1,2,3,0" : tFltDocSta.join(","),
        FltAccSta: isAllAccStaChecked ? "1,2,3,5,6,0" : tFltAccSta.join(","),
        FltConvertUsr: isDefaultConvert ? "0,1" : tFltConvertUsr.join(","),
      });
    } else {
      let nPage = history.location.PageCurrent + 1;
      // SET value UI
      SETxOpSrch(history.location.UISearch.tOpSrch);
      SETxSrch(history.location.UISearch.tSrch);

      SETxPageIndex(history.location.PageCurrent);
      REQUntHldList({
        page: nPage.toString(),
        tSrch: history.location.UISearch.tSrch,
        tOpSrch: history.location.UISearch.tOpSrch,
        SortH: tSortH,
        FltDocSta: isAllDocStaChecked ? "1,2,3,0" : tFltDocSta.join(","),
        FltAccSta: isAllAccStaChecked ? "1,2,3,5,6,0" : tFltAccSta.join(","),
        FltConvertUsr: isDefaultConvert ? "0,1" : tFltConvertUsr.join(","),
      });
    }
  };

  useEffect(() => {
    CALLxUntHld();
    console.log("initial state", isAllDocStaChecked)
  }, [tFltDocSta, tFltAccSta, tFltConvertUsr, tSortH]);

  const SRCHxUntHld = () => {
    SETxPageIndex(0);
    REQUntHldList({
      page: "1",
      tSrch,
      tOpSrch,
      FltDocSta: isAllDocStaChecked ? "1,2,3,0" : tFltDocSta.join(","),
      FltAccSta: isAllAccStaChecked ? "1,2,3,5,6,0" : tFltAccSta.join(","),
      FltConvertUsr: isDefaultConvert ? "0,1" : tFltConvertUsr.join(","),
    });
  };

  const SRCHxUsrActClr = () => {
    SETxSrch("");
    SETxPageIndex(0);
    REQUntHldList({
      page: "1",
      tSrch: "",
      tOpSrch,
      FltDocSta: isAllDocStaChecked ? "1,2,3,0" : tFltDocSta.join(","),
      FltAccSta: isAllAccStaChecked ? "1,2,3,5,6,0" : tFltAccSta.join(","),
      FltConvertUsr: isDefaultConvert ? "0,1" : tFltConvertUsr.join(","),
    });
  };

  const sortMappings = {
    "SUBMISSION DATE": { 
      ascending: "submission_at ASC", 
      descending: "submission_at DESC"
    },
    "UPDATED AT": { 
      ascending: "updated_at ASC",
      descending: "updated_at DESC" 
  }
};

  const setHeaderSort = cell => {
    if (sortMappings[cell]) {
      const newSortH = tSortH === sortMappings[cell].ascending 
                        ? sortMappings[cell].descending 
                        : sortMappings[cell].ascending;
      SETtSortH(newSortH);
    }
  };

  const RadioInput = ({ label, value, checked, setter }) => {
    return (
      <span>
        <input
          type="radio"
          checked={checked === value}
          onChange={() => {
            setter(value);
          }}
        />
        <span style={{ marginLeft: 0 }}>{label}</span>
      </span>
    );
  };

  const SETxActViewClick = (row) => {
    console.log("SETxActViewClick:", row.original);
    
    const unitholderDetails = {
      uuid: row.original.uuid,
      unitholderid: row.original?.unitholderId || row.original?.unitholder_id,
      UsrAccountType: row.original.accounttype,
      NameTH: row.original.firstNameTh,
      UserName: row.original.username,
      PageCurrent: nPageIndex,
      UISearch: {
        tSrch: tSrch,
        tOpSrch: tOpSrch,
      },
    };
  
    // Save data to localStorage
    localStorage.setItem('currentCustomerUntHldDetail', JSON.stringify(unitholderDetails));
    console.log("set in list", JSON.stringify(unitholderDetails))
    setCurrentCustomerUntHldDetail(unitholderDetails);
    selectedObjectUuid(row.original.firstNameEn);
    history.push(`/UnitHolderDetail/${row.original.uuid}/UnitHolderID=${
      row.original?.unitholderId || row.original?.unitholder_id
    }`);
  };
  

  const handleActionClick = (row, actionName) => {
    if (
      actionName === "APPROVE" ||
      actionName === "REJECT" ||
      actionName === "CANCEL" ||
      actionName === "CREATING"
    ) {
      setCurrentCustomerUntHldDetail({
        uuid: row.uuid,
        unitholderid: row.unitholderId,
        UsrAccountType: row.accounttype,
        NameTH: row.firstNameTh,
        UserName: row.username,
        PageCurrent: nPageIndex,
        UISearch: {
          tSrch: tSrch,
          tOpSrch: tOpSrch,
        },
      });
      selectedObjectUuid(row.firstNameEn);

      history.push(
        `/UnitHolderDetail/${row.uuid}/UnitHolderID=${row.unitholderId}`
      );
    } else if (actionName === "DISAPPROVE") {
      setClientRowData({
        customerUuid: row.uuid,
        unitholderid: row.unitholder_id,
        firstName: row.first_name,
        UserName: row.username,
        history: history,
      });
      setIsShow(true);
    }
  };

  const SETxPageGo = (pnPageAt) => {
    let nPageAt = pnPageAt + 1;
    SETxPageIndex(pnPageAt);
    REQUntHldList({
      page: nPageAt.toString(),
      tSrch,
      tOpSrch,
      SortH: tSortH,
      FltDocSta: isAllDocStaChecked ? "1,2,3,0" : tFltDocSta.join(","),
      FltAccSta: isAllAccStaChecked ? "1,2,3,5,6,0" : tFltAccSta.join(","),
      FltConvertUsr: isDefaultConvert ? "0,1" : tFltConvertUsr.join(","),
    });
  };
  const CALxPaging = () => {
    let nPageCnt = Math.ceil(totalUntHldAct / 10);
    return nPageCnt > 1 ? (
      <Pagination
        nPageIndex={nPageIndex}
        total={nPageCnt}
        gotoPage={SETxPageGo}
      />
    ) : null;
  };
  const GETxWidthSrch = () => {
    if (tOpSrch === "UUID") {
      return 450;
    } else {
      return 331;
    }
  };

  const SETxFltHeader = (cell) => {
    if (cell === "DOC. STATUS") {
      return (
        <div className="clients-header-sortby">
          <div className="clients-header-sortby-text-container">
            <p>{cell}</p>
            <img
              className="clients-header-sortby-img"
              src={Images.filer.filer}
              alt="down-arrow"
            />
          </div>

          <div
            className="clients-header-sortby-radio-container"
            style={{ height: 210 }}
          >
            <p>FILTER STATUS BY</p>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="ALL"
                name="all-doc"
                id="all-doc"
                checked={isAllDocStaChecked}
                onChange={handleAllDocStatusChange}
              />
              <label htmlFor="all-doc" className="checkbox-label">
                ALL
              </label>
            </div>

            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Approved"
                name="approved-doc"
                id="approved-doc"
                checked={tFltDocSta.includes("2")}
                onChange={() => handleDocStatusChange("2")}

                // end test
              />
              <label htmlFor="approved-doc">APPROVED</label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Pending"
                name="pending-doc"
                id="pending-doc"
                checked={tFltDocSta.includes("1")}
                onChange={() => handleDocStatusChange("1")}

                // end test
              />
              <label htmlFor="pending-doc">PENDING</label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Rejected"
                name="rejected-doc"
                id="rejected-doc"
                checked={tFltDocSta.includes("3")}
                onChange={() => handleDocStatusChange("3")}

                // end test
              />
              <label htmlFor="rejected-doc">REJECTED</label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="No Document"
                name="approved-doc"
                id="approved-doc"
                checked={tFltDocSta.includes("0")}
                onChange={() => handleDocStatusChange("0")}

                // end test
              />
              <label htmlFor="approved-doc">NO DOCUMENT</label>
            </div>
          </div>
        </div>
      );
    } else if (cell === "ACC. STATUS") {
      return (
        <div className="clients-header-sortby-accStatus">
          <div className="clients-header-sortby-text-container-accStatus">
            <p>{cell}</p>
            <img
              className="clients-header-sortby-img-accStatus"
              src={Images.filer.filer}
              alt="down-arrow"
            />
          </div>
          <div
            className="clients-header-sortby-radio-container-accStatus"
            style={{ height: 270 }}
          >
            <p>FILTER STATUS BY</p>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="ALL"
                checked={isAllAccStaChecked}
                onChange={handleAllAccStatusChange}
              />
              <label htmlFor="Verified by-user" className="checkbox-label">
                ALL
              </label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="APPROVED"
                checked={tFltAccSta.includes("2")}
                onChange={() => handleAccStatusChange("2")}
              />
              <label htmlFor="Verified by-user" className="checkbox-label">
                APPROVED
              </label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="PENDING"
                checked={tFltAccSta.includes("1")}
                onChange={() => handleAccStatusChange("1")}
              />
              <label htmlFor="Verified by-user" className="checkbox-label">
                PENDING
              </label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="REJECTED"
                checked={tFltAccSta.includes("3")}
                onChange={() => handleAccStatusChange("3")}
              />
              <label htmlFor="Verified by-user" className="checkbox-label">
                REJECTED
              </label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="CREATING"
                checked={tFltAccSta.includes("5")}
                onChange={() => handleAccStatusChange("5")}
              />
              <label htmlFor="Verified by-user" className="checkbox-label">
                CREATING
              </label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="JUST CONVERT"
                checked={tFltAccSta.includes("6")}
                onChange={() => handleAccStatusChange("6")}
              />
              <label
                htmlFor="Verified by-user"
                className="checkbox-label"
                style={{ marginRight: -43 }}
              >
                JUST CONVERT
              </label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="CREATING"
                checked={tFltAccSta.includes("0")}
                onChange={() => handleAccStatusChange("0")}
              />
              <label htmlFor="Verified by-user" className="checkbox-label">
                NO STATUS
              </label>
            </div>
          </div>
        </div>
      );
    } else if (cell === "CONVERTED USER") {
      return (
        <div className="clients-header-sortby-converted-user">
          <div className="clients-header-sortby-text-container-converted-user">
            <p>{cell}</p>
            <img
              className="clients-header-sortby-img-converted-user"
              src={Images.filer.filer}
              alt="down-arrow"
            />
          </div>
          <div className="clients-header-sortby-radio-container-converted-user">
            <p>FILTER USER BY</p>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Yes"
                name="yes-user"
                id="yes-user"
                checked={tFltConvertUsr.includes("1")}
                onChange={() => handleConvertUsrChange("1")}
              />
              <label htmlFor="yes-user" className="checkbox-label">
                Yes
              </label>
            </div>

            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="No"
                name="no-user"
                id="no-user"
                checked={tFltConvertUsr.includes("0")}
                onChange={() => handleConvertUsrChange("0")}
              />
              <label htmlFor="no-user" className="checkbox-label">
                No
              </label>
            </div>
          </div>
        </div>
      );
    }
  };
  const handleModalGo = () => {
    if (isShow) {
      setIsShow(false);
      // Request this client's account status to be reset to Pending
      requestDisapproveClient(clientRowData);
      CALLxUntHld()
    }
  };
  return (
    <Fragment>
      <WarningModal
        show={isShow}
        onHide={() => {
          setIsShow(false);
          setClientRowData({
            customerUuid: "",
            unitholderid: "",
            firstName: "",
            history: "",
          });
        }}
        onHandleGo={() => handleModalGo()}
        onHandleCancel={() => {
          setIsShow(false);
          setClientRowData({
            customerUuid: "",
            unitholderid: "",
            firstName: "",
            history: "",
          });
        }}
        type={"Disapprove"}
        status={"1"}
        title={"Are you sure you want to disapprove this unitholder?"}
      />

      <div className="button-create-container" style={{ marginBottom: 10 }}>
        <div style={{ height: 40 }} />

        {REQStrUntHldList ? (
          <div className="regisDevice-list-progress">
            <CircularProgress />
            <p className="regisDevice-list-progress-text">
              {REQStrUntHldList ? "Retrieving UnitHolder list..." : null}
            </p>
          </div>
        ) : null}
      </div>

      <div className="labelInput" style={{ marginLeft: 20, marginTop: 50 }}>
        <span className="labelInputText" style={{ width: 100 }}>
          Search By
        </span>
        <span style={{ paddingRight: 10 }}>:</span>
        <div
          className="usrAct-search"
          style={{ marginTop: 0, width: GETxWidthSrch() }}
        >
          <img
            src={Images.search.search}
            alt="search-icon"
            className="transactions-list-begin-search"
            onClick={() => SRCHxUntHld()}
          />
          <input
            placeholder={"Search by " + tOpSrch}
            className="usrAct-search-bar"
            value={tSrch}
            onChange={(event) => {
              SETxSrch(event.target.value.trim());
            }}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                SRCHxUntHld();
              }
            }}
          />
          {tSrch && tSrch.length > 0 ? (
            <img
              src={Images.closeButton.close}
              alt="cancel-search"
              className="transactions-details-cancel-search"
              onClick={() => SRCHxUsrActClr()}
            />
          ) : null}
        </div>
      </div>
      <div
        className="clients-radio-container"
        style={{ marginLeft: 145, marginBottom: 20 }}
      >
        <RadioInput
          label="E-mail"
          value="Email"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />{" "}
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Username"
          value="Username"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="English Name"
          value="English Name"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Thai Name"
          value="Thai Name"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="ID No. / Passport No."
          value="ID No. / Passport No."
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Mobile"
          value="Mobile"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
      </div>
      {}
      {REQStrUntHldList ? null : (
        <Table
          columns={columns}
          data={UntHldList || []}
          handleViewClick={SETxActViewClick}
          handleFltHeader={SETxFltHeader}
          handleActionClick={handleActionClick}
          handleActionHeaderClick={setHeaderSort}
          pageSelect={nPageIndex}
        />
      )}
      {REQStrUntHldList ? null : <CALxPaging />}
    </Fragment>
  );
}

const withConnect = connect(
  (state) => ({
    ...state.untHldListReducer,
  }),
  {
    REQUntHldList,
    setCurrentCustomerUntHldDetail,
    requestDisapproveClient,
    // clear state in UntHldDdetail
    CleanUpUntHldSrch,
  }
);

export default compose(withConnect, withRouter)(UntHldList);
