import { SAVE_PAGE_NUMBER } from "../constants";

const initialState = {
  page: 1
};

export function pagingReducer(state = initialState, action = {}) {
  
  switch (action.type) {
    case SAVE_PAGE_NUMBER:
      return {
        ...state,
        page: action.page
      };
    default:
      return state;
  }
}
