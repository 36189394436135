import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  // -----FundCondition---Risk
  REQFundRecRiskList,
  RESFundRecRiskList,
  FailFundRecRiskList,
  // ---Chage status-------
  REQFundRiskChgSta,
  RESFundRiskChgSta,
  FailFundRiskChgSta
} from "./actions";
import * as actions from "../../Authentication/redux/actions";

// --------------FundRecommend Risk---------------------------------
function* fetchFundRecRiskListWorker() {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds/recommend_by_fund_risk`,
      {
        method: "GET",  
        headers: {
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    const response = yield request.json();
    if (response && response.data) {
      yield put(RESFundRecRiskList(response));
    } else {
            yield put(FailFundRecRiskList());
    }
  } catch (error) {
    
    yield put(FailFundRecRiskList());
  }
}
function* EDTxByRiskChgSta({ payload }) {
  try {
    console.log("EDTxByRiskChgSta >>>",payload);
    const { riskId, Visible } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let body = {
      riskId: riskId,
      Visible: Visible
    };
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds/recommend_by_fund_risk/visible`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    console.log("recommend_by_fund_risk/visible:");
    console.log("RESFundRiskChgSta:",response.data);
    if (response && response.data && response.status === 200) {
      yield put(RESFundRiskChgSta(response));
    } else {
      yield put(FailFundRiskChgSta());
    }
  } catch (error) {
    
    yield put(FailFundRiskChgSta());
  }
}

export function* fundRecRiskListSaga() {
  yield all([
    // ------FundRecommend Risk------
    takeLatest(REQFundRecRiskList, fetchFundRecRiskListWorker),
    takeLatest(REQFundRiskChgSta, EDTxByRiskChgSta)
  ]);
}
