import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  requestClientProfile,
  receiveClientProfile,
  failClientProfile,
  requestClientDocuments,
  receiveClientDocuments,
  failClientDocuments,
  requestUserAccountLockedStatus,
  receiveUserAccountLockedStatus,
  failUserAccountLockedStatus,
  requestDocumentApprove,
  receiveDocumentApprove,
  failDocumentApprove,
  requestDocumentReject,
  receiveDocumentReject,
  failDocumentReject,
  requestDocumentUndo,
  receiveDocumentUndo,
  failDocumentUndo,
  requestClientApprove,
  receiveClientApprove,
  failClientApprove,
  requestClientReject,
  receiveClientReject,
  failClientReject,
  requestCancelStatus,
  receiveCancelStatus,
  failCancelStatus,
  requestClientFunds,
  receiveClientFunds,
  failClientFunds,
  requestUserAccountUnlock,
  receiveUserAccountUnlock,
  failUserAccountUnlock,
  receiveCustomerChannel,
  // -----User Account------------
  REQUsrActSrchCli,
  RESUsrActSrchCli,
  FailUsrActSrchCli
} from "./actions";
import { requestDistricts } from "../../../globalRedux/actions";
import { getApplicants } from "../../Clients/redux/actions";
import { aConfigUrl as ConfigData } from "../../../utils/ConfigUrl";
import * as actions from "../../Authentication/redux/actions";


function* fetchClientDetail() {
  // Get the client's particulars like first name, last name, avatar
  try {
    // HARDCODE
    const { customerUuid } = yield select(state => state.clientDetailReducer);
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/customers/${customerUuid}?fields=${encodeURIComponent("bank{accountName,accountNo,bankCode,updatedAt,createdAt},children{firstName,birthDate,fullName,identificationNo,lastName,updatedAt,createdAt},contactAddress{address1,address2,countryCode,districtCode,postCode,provinceCode,subDistrict,updatedAt,createdAt},detail{avatar{url},profilePic{url},title,titleOther,birthDate,email,firstName,firstNameTh,lastNameTh,lastName,genderCode,identificationExpireDate,identificationNo,identificationTypeCode,lazerCode,maritalStatusCode,mobile,nationalityCode,taxOption,updatedAt,createdAt},occupation{businessOther,incomeCode,occupationCode,occupationOther,sourceOfFundCode,sourceOfIncomeCode,businessType,totalAssetValue,sourceOfIncomeOther,sourceOfFundOther,position,objectiveInvestmentCode,objectiveInvestmentOther,updatedAt,createdAt},residentialAddress{address1,address2,countryCode,districtCode,postCode,provinceCode,subDistrict,updatedAt,createdAt},risk{level},spouse{firstName,fullName,identificationNo,lastName,updatedAt,createdAt},statusCode,workplaceAddress{address1,address2,countryCode,districtCode,name,postCode,provinceCode,subDistrict,updatedAt,createdAt},verifyChannel,mailingAddress{mailingAddressType,address1,address2,countryCode,districtCode,postCode,provinceCode,subDistrict,updatedAt,createdAt},canApprove")}`,
      {
        headers: {
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    if (response && response.data) {
      // Now that we got the customer details, let's get the district lists pertaining to that customer's
      const {
        workplaceAddress,
        residentialAddress,
        contactAddress,
        mailingAddress
      } = response.data.data;
      if (mailingAddress) {
        yield all([
          put(
            requestDistricts({
              provinceCode: mailingAddress.provinceCode,
              type: "districtMailingAddress"
            })
          )
        ]);
      }
      yield all([
        put(
          requestDistricts({
            provinceCode:
              residentialAddress != null
                ? residentialAddress.provinceCode
                : null,
            type: "districtsResidential"
          })
        ),
        put(
          requestDistricts({
            provinceCode:
              workplaceAddress != null ? workplaceAddress.provinceCode : null,
            type: "districtWork"
          })
        ),
        put(
          requestDistricts({
            provinceCode:
              contactAddress != null ? contactAddress.provinceCode : null,
            type: "districtContactAddress"
          })
        )
      ]);
      yield put(receiveCustomerChannel(response.data.data.verifyChannel));
      yield put(receiveClientProfile(response.data.data));
    } else {
      yield put(failClientProfile());
    }
  } catch (error) {
    
    yield put(failClientProfile());
  }
}

function* fetchDocumentDetail() {
  try {
    console.log("fetchDocumentDetail start");
    const { customerUuid } = yield select(state => state.clientDetailReducer);
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/customers/${customerUuid}/documents?fields=lastModifiedAt%2CstatusCode%2CtypeCode%2Curl%2Cuuid`,
      {
        headers: {
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    console.log("fetchDocumentDetail response:",response);
    console.log("fetchDocumentDetail response.data:",response.data);
    console.log("fetchDocumentDetail response.data.data:",response.data.data);
    if (response && response.data) {
      yield put(receiveClientDocuments(response.data.data));
    } else {
      yield put(failClientDocuments());
    }
  } catch (error) {
    yield put(failClientDocuments());
  }
}

function* fetchClientFunds() {
  try {
    const { unitholderId } = yield select(state => state.clientDetailReducer);
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/portfolio?fields=unitholderId%2CfundNo%2CfundCode%2CnavDate%2CnavPrice%2CcostInvest%2CaverageCost%2Camount%2Cunit%2CprofitLoss%2CprofitPercent%2CfirstSubscription&fundNo=0&unitholderId=${unitholderId}`,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    if (response && response.data && response.data.data) {
      yield put(receiveClientFunds(response.data.data));
    } else {
      yield put(failClientFunds());
    }
  } catch (error) {
    
    yield put(failClientFunds());
  }
}

function* fetchIsUserAccountLockedStatusSaga() {
  // Get the user's account locked status. If they've entered their credentials wrong three times, then this will tell us that it's locked
  const { customerUuid } = yield select(state => state.clientDetailReducer);
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  try {
    const userDetailRequest = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/customers/${customerUuid}?fields=${encodeURIComponent("bank{accountName,accountNo,bankCode},children{firstName,birthDate,fullName,identificationNo,lastName},contactAddress{address1,address2,countryCode,districtCode,postCode,provinceCode,subDistrict},detail{avatar{url},profilePic{url},birthDate,email,firstName,firstNameTh,lastNameTh,lastName,genderCode,identificationExpireDate,identificationNo,identificationTypeCode,lazerCode,maritalStatusCode,mobile,nationalityCode,taxOption},occupation{businessOther,incomeCode,occupationCode,occupationOther,sourceOfFundCode,sourceOfIncomeCode},residentialAddress{address1,address2,countryCode,districtCode,postCode,provinceCode,subDistrict},risk{level},spouse{firstName,fullName,identificationNo,lastName},statusCode,workplaceAddress{address1,address2,countryCode,districtCode,name,postCode,provinceCode,subDistrict}")}`,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (userDetailRequest.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    const userEmail = userDetailRequest.data.detail.email;
    const request = yield call(api.get, 
      `${process.env.REACT_APP_API_URL}/sdkservice/listUsers`, {
      headers: {
        userid: tGUID,
        id_token: "Bearer " + accessToken
      }
    });
    // * Find my account status
    console.log("accounts:",equest.data.users);
    const accounts = request.data.users;
    const lockStatus = accounts.filter(
      account => account["Email Address"] === userEmail
    );
    console.log("lockStatus:",lockStatus);
    var userLockStatus;
    if (lockStatus.length > 0) {
      if (lockStatus[0].status === "LOCKED_OUT") {
        userLockStatus = "LOCKED";
      } else {
        userLockStatus = "UNLOCKED";
      }
    }

    if (userLockStatus) {
      yield put(
        receiveUserAccountLockedStatus(
          userLockStatus
        )
      );
    } else {
      yield put(failUserAccountLockedStatus());
    }
  } catch (error) {
    
    yield put(failUserAccountLockedStatus(error));
  }
}

function* fetchUserAccountUnlock(action) {
  try {
    const { payload } = action;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const userDetail = yield select(state => state.clientDetailReducer);
    var clientUserId = "";
    if (payload) {
      clientUserId = payload.customerId;
    } else {
      try {
        clientUserId = userDetail.clientDetail.detail.email;
      } catch (e) {
        console.log('fetchUserAccountUnlock catch', e);
      }
    }
    const body = {};
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/unlockUser/${clientUserId}`,
      body,
      {
        headers: {
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    if (response && (response.status === 204 || response.status === 200)) {
      yield put(getApplicants({ offset: 0, limit: 10 }));
      yield put(receiveUserAccountUnlock());
    } else {
      yield put(failUserAccountUnlock());
    }
  } catch (error) {
    
    yield put(failUserAccountUnlock(error));
  }
}

function* fetchDocumentApproval({ payload }) {
  try {
    /*
      Updates the document's status to Approved.
    */
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { documentID } = payload;

    const body = {
      statusCode: "2"
    };

    const response = yield call(
      api.patch,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/documents/${documentID}/status?fields=lastModified`,
      body,
      {
        headers: {
          "Content-Type": `application/json`,
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }

    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.lastModified
    ) {
      yield put(receiveDocumentApprove());
      yield put(requestClientDocuments());
    } else {
      yield put(failDocumentApprove());
    }
  } catch (error) {
    
    yield put(failDocumentApprove());
  }
}

function* fetchDocumentRejection({ payload }) {
  try {
    /*
      Updates the document's status to Rejected.
    */
    const { documentID } = payload;

    const body = {
      statusCode: "3"
    };
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);

    const response = yield call(
      api.patch,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/documents/${documentID}/status?fields=lastModified`,
      body,
      {
        headers: {
          "Content-Type": `application/json`,
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }

    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.lastModified
    ) {
      yield put(receiveDocumentReject());
      yield put(requestClientDocuments());
    } else {
      yield put(failDocumentReject());
    }
  } catch (error) {
    
    yield put(failDocumentReject());
  }
}

function* fetchDocumentUndo({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    /*
      Updates the document's status to Pending (essentially undoing an Approve or Rejection).
    */
    const { documentID } = payload;

    const body = {
      statusCode: "1"
    };

    const response = yield call(
      api.patch,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/documents/${documentID}/status?fields=lastModified`,
      body,
      {
        headers: {
          "Content-Type": `application/json`,
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }

    console.log("fetchDocumentUndo response:",response);
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.lastModified
    ) {
      yield put(receiveDocumentUndo());
      yield put(requestClientDocuments());
    } else {
      yield put(failDocumentUndo());
    }
  } catch (error) {
    
    yield put(failDocumentUndo());
  }
}

function* fetchClientApprove() {
  try {
    console.log("fetchClientApprove start:");
    const tGUID = yield select(state => state.auth.GUID);
    const { customerUuid } = yield select(state => state.clientDetailReducer);
    const body = {
      userId: customerUuid
    };
    console.log("fetchClientApprove body:",JSON.stringify(body));
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/approve-user`,
      body,
      {
        headers: {
           userid: tGUID,
           id_token: "Bearer " + accessToken
         }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    console.log("fetchClientApprove clientDetailReducer:",response); 
    if (response && response.data.status === "success") {
      yield put(requestClientProfile());
      yield put(receiveClientApprove());
    } else {
      yield put(failClientApprove());
    }
  } catch (error) {
    console.error("Catch fetchClientApprove error:",error);
    yield handleClientApproveError(error);
  }
}

function* handleClientApproveError(error) {
  console.log("handleClientApproveError start:");
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  const { customerUuid, clientDetail } = yield select(state => state.clientDetailReducer);
  const body = yield buildRequestPayload(clientDetail);
  const responseData = yield call(
    api.put,
    `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/compareDataTimestamp/${customerUuid}`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        userid: tGUID,
          id_token: "Bearer " + accessToken
      }
    },
  );
  if (responseData.status === 401) {
    yield put(actions.autoSignOut()); 
    return; 
  }

  console.log("compareDataTimestamp responseData:",responseData.data);
  if (responseData.data.length > 0) {
    let alertData = {
      header:
        "The information is updated, the system will refresh itself. Please check the information once again.",
      data: responseData.data
    };
    yield put(failClientApprove(alertData));
  } else {
    if (error.response.error.message.message.errorCode === "EPV") {
      yield put(
        failClientApprove({
          header:
            error.response.error.message.message.errorDetails[0].errorDesc,
          data: []
        })
      );
    }
  }
}

function buildRequestPayload(clientDetail) {
  const payload = {
    customer_detail: getUpdatedAt(clientDetail.detail),
    residential_address: getUpdatedAt(clientDetail.residentialAddress),
    customer_bank: getUpdatedAt(clientDetail.bank),
    contact_address: getUpdatedAt(clientDetail.contactAddress),
    occupation_detail: getUpdatedAt(clientDetail.occupation),
    workplace_address: getUpdatedAt(clientDetail.workplaceAddress),
    mailing_address: getUpdatedAt(clientDetail.mailingAddress)
  };
  return payload;
}

function getUpdatedAt(data) {
  return data.updatedAt ? data.updatedAt : null;
}

// end test

function* fetchClientReject() {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { customerUuid } = yield select(state => state.clientDetailReducer);
    const body = {
      userId: customerUuid
    };
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/reject-user`,
      body,
      { 
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }

    if (response && response.data) {
      yield put(requestClientProfile());
      yield put(receiveClientReject());
    } else {
      yield put(failClientReject());
    }
  } catch (error) {
    
    yield put(failClientReject());
  }
}

function* fetchCancelStatus() {
  // This resets an Approved or Rejected user to Pending status
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { customerUuid } = yield select(state => state.clientDetailReducer);

    const body = { statusCode: "1" };
    const response = yield call(
      api.patch,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/customers/${customerUuid}/status`,
      {
        headers: {
          "Content-Type": `application/json`,
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }

    if (response && response.data && response.data.data) {
      yield put(requestClientProfile());
      yield put(receiveCancelStatus());
    } else {
      yield put(failCancelStatus());
    }
  } catch (error) {
    
    yield put(failCancelStatus());
  }
}
function* fetchUsrActSrchWorker({ payload }) {
  try {
    console.log("fetchUsrActSrchWorker start");
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { page, tSrch, tOpSrch, tSortH, tFltActStage } = payload;

    let tEmail = "";
    let tUsrName = "";
    let tUUID = "";
    let tActStg = "";
    let tActStgVerify = "";

    switch (tOpSrch) {
      case "Email":
        tEmail = tSrch;
        break;
      case "Username":
        tUsrName = tSrch;
        break;
      case "UUID":
        tUUID = tSrch;
        break;
      default:
        break;
    }
    // ----Filter AccountStage----
    let bChk = tFltActStage.includes("Verified by");

    if (bChk === true) {
      let tFlt = tFltActStage.filter(f => f !== "Verified by");
      tActStg = tFlt.map(m => "'" + m + "'").toString();
      tActStg = tActStg === "" ? "''" : tActStg + ",''";
      tActStgVerify = "Verified by";
    } else if (bChk === false) {
      tActStg = tFltActStage.map(m => "'" + m + "'").toString();
      tActStg = tActStg === "" ? "''" : tActStg + ",''";
      tActStgVerify = "";
    }

    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/userAccount?page=${page}`,
      {
        method: "POST",  
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        },
        body: JSON.stringify({
          email: tEmail,
          username: tUsrName,
          uuid: tUUID,
          sortH: tSortH,
          FltAccountStage: tActStg,
          FltAccountStageByVerify: tActStgVerify
        })
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    const response = yield request.json();
    console.log("fetchUsrActSrchWorker response:",response);
    if (response) {
      yield put(RESUsrActSrchCli(response));
    } else {
            yield put(RESUsrActSrchCli());
    }
  } catch (error) {
    
    yield put(FailUsrActSrchCli());
  }
}
export function* clientDetailSagas() {
  yield all([
    takeLatest(requestClientProfile, fetchClientDetail),
    takeLatest(requestClientDocuments, fetchDocumentDetail),
    takeLatest(
      requestUserAccountLockedStatus,
      fetchIsUserAccountLockedStatusSaga
    ),
    takeLatest(requestUserAccountUnlock, fetchUserAccountUnlock),
    takeLatest(requestDocumentApprove, fetchDocumentApproval),
    takeLatest(requestDocumentReject, fetchDocumentRejection),
    takeLatest(requestDocumentUndo, fetchDocumentUndo),
    takeLatest(requestClientApprove, fetchClientApprove),
    takeLatest(requestClientReject, fetchClientReject),
    takeLatest(requestCancelStatus, fetchCancelStatus),
    takeLatest(requestClientFunds, fetchClientFunds),
    takeLatest(REQUsrActSrchCli, fetchUsrActSrchWorker)
  ]);
}
