import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import "./style.scss";

function RegisDeviceDetail(poPrp) {
  // const [tSrch, SETxSrch] = useState("");
  const { RowDataAt, history, PageCurrent, UISearch, } = poPrp;
  const [isPageReload, setIsPageReload] = useState(false);
  const [isUnloading, setIsUnloading] = useState(false);

  useEffect(() => {
    const savePageState = () => {
      const dataFromList = JSON.parse(localStorage.getItem('currentRegisDeviceDetail'))
      console.log("dataFromList", dataFromList)
      localStorage.setItem('currentRegisDeviceDetail', JSON.stringify(dataFromList));
    };

    // Save initial state
    savePageState();
    
    const handleBeforeUnload = () => {
      savePageState();
    };
  
    const handlePageShow = (event) => {
      if (!event.persisted) {
        const storedData = JSON.parse(localStorage.getItem('currentRegisDeviceDetail'));
        if (storedData) {
          console.log("stored", storedData)
        }
      }
    };

    // Check for page reload using Performance API
    const navigationType = window.performance.getEntriesByType("navigation")[0]?.type;
    if (navigationType === 'reload') {
      setIsPageReload(true);
      const storedData = JSON.parse(localStorage.getItem('currentRegisDeviceDetail'));
      console.log("StoredData out", storedData)
      if (storedData) {
        console.log("storedData", storedData)
        history.push(`/RegisDeviceDetail/${RowDataAt.id}`);
       
      }
    }

    // Handle navigation events
    const unlisten = history.listen((location, action) => {
      if (action === 'POP' && !isPageReload && !isUnloading) {
        history.replace({
          pathname: "/RegisDevice",
          PageCurrent: PageCurrent,
          UISearch: UISearch
        });
      }
    });

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('pageshow', handlePageShow);
      unlisten();

      if (!isPageReload && !isUnloading) {
        localStorage.removeItem('currentRegisDeviceDetail');
      }
    };
  }, []);


  return (
    <Fragment>
      <div className="tag-feed-form-container">
        <div className="tag-feed-form-buttons-container">
          <button
            className="tag-detail-cancel"
            onClick={() =>
              history.push({
                pathname: "/RegisDevice",
                PageCurrent: PageCurrent,
                UISearch: UISearch
              })
            }
          >
            CANCEL
          </button>
        </div>

        <div
          className="regisDevice-feed-form-inputs-container"
          style={{ height: 900 }}
        >
          <div className="regisDevice-feed-form-input-spacer" />

          <div className="labelInput">
            <span className="labelInputText ">Customer UUID</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.customer_uuid.toString()}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Firebase User ID</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.firebaseFID}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">App Version</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.appVersion}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">App Platform</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.appPlatform}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Device Name</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.deviceName}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Registration Status</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.registerStatus}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Registered Date</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.registeredAt}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Deregistered Date</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.deregisteredAt}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">IP Address</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.registerIpAddress}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Verified Status</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.verifyStatus}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Verified Type</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.verifyType}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Verified By</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.verifyBy}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Internet Service Provider</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.ISP}
              disabled
            />
          </div>

          <div className="regisDevice-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Device Version</span>
            <input
              className="textInput"
              defaultValue={RowDataAt.deviceVersion}
              disabled
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.regisDeviceDetailReducer
  }),
  {}
);

export default compose(
  withConnect,
  withRouter
)(RegisDeviceDetail);
