import { createAction } from "redux-act";

export const SetFullImgUrl = createAction("SetFullImgUrl");

export const REQUntHldList = createAction("REQUEST_UNTHLD_LIST");
export const RESUntHldList = createAction("RECEIVE_UNTHLD_LIST");
export const FailUntHldList = createAction("FAILED_UNTHLD_LIST");
export const CleanUpUntHldList= createAction("CLEANUP_UNTHLD_LIST");

// ---DisApprove--
export const requestDisapproveClient = createAction("REQUEST_DISAPPROVE_CLIENT1");
export const receiveDisapproveClient = createAction( "RECEIVE_DISAPPROVE_CLIENT1");
export const failDisapproveClient = createAction("FAIL_DISAPPROVE_CLIENT1");