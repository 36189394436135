import { createAction } from "redux-act";

export const requestSaveAndPublishContentAction = createAction(
  "REQUEST_SAVE_PUBLISH_CONTENT"
);
export const receiveSaveAndPublishContentAction = createAction(
  "RECEIVE_SAVE_PUBLISH_CONTENT"
);
export const failSaveAndPublishContentAction = createAction(
  "FAIL_SAVE_PUBLISH_ACTION"
);
export const setContentFeedModeAction = createAction("SET_CONTENT_FEED_MODE");
export const requestFeedContentAction = createAction("REQUEST_FEED_CONTENT");
export const receiveFeedContentAction = createAction("RECEIVE_FEED_CONTENT");
export const failFeedContentAction = createAction("FAIL_FEED_ACTION");
export const clearFeedContentAction = createAction("CLEAR_FEED_CONTENT");
export const requestDeleteFeedContentAction = createAction(
  "REQUEST_DELETE_FEED_CONTENT"
);
export const receiveDeleteFeedContentAction = createAction(
  "RECEIVE_DELETE_FEED_CONTENT"
);
export const failDeleteFeedContentAction = createAction(
  "FAIL_DELETE_FEED_ACTION"
);
export const clearLocalCopyRemoteImage = createAction("CLEAR_LOCAL_COPY_REMOVE_IMAGE");


// -----------------New-------------------
export const MEMConFdEdt = createAction("MEM_CONFDDETAIL_EDIT");
// --------Get ArticleTag------------
export const REQArticleTag = createAction("REQUEST_CONFDDETAIL_ARTTAG");
export const RESArticleTag = createAction("RECEIVE_CONFDDETAIL_ARTTAG");
export const FailArticleTag = createAction("FAILED_CONFDDETAIL_ARTTAG");
// --------Get FundR------------
export const REQFund = createAction("REQUEST_CONFDDETAIL_FUND");
export const RESFund = createAction("RECEIVE_CONFDDETAIL_FUND");
export const FailFund = createAction("FAILED_CONFDDETAIL_FUND");
// fund code 
export const REQFundCodeList = createAction("REQUEST_FUNCODE_Detail");
export const RESFundCodeList = createAction("RECEIVE_FUNCODE_Detail");
export const FailFundCodeList = createAction("FAILED_FUNCODE_Detail");
// ----------Edit ContentFeed----
export const REQContFdEdt = createAction("REQUEST_CONFDDETAIL_EDIT");
export const RESContFdEdt = createAction("RECEIVE_CONFDDETAIL_EDIT");
export const FailContFdEdt = createAction("FAILED_CONFDDETAIL_EDIT");
// ----------Creat ContentFeed----
export const REQContFdCrt = createAction("REQUEST_CONFDDETAIL_CRT");
export const RESContFdCrt = createAction("RECEIVE_CONFDDETAIL_CRT");
export const FailContFdCrt = createAction("FAILED_CONFDDETAIL_CRT");
// ----------Del ContentFeed------
export const REQContFdDel = createAction("REQUEST_CONFDDETAIL_DEL");
export const RESContFdDel = createAction("RECEIVE_CONFDDETAIL_DEL");
export const FailContFdDel= createAction("FAILED_CONFDDETAIL_DEL");

// ----------Edit ContentFeed Noti Invest----
export const REQContFdNotiInvestEdt = createAction("REQUEST_CONFDDETAIL_NOTIINVEST_EDIT");
export const RESContFdNotiInvestEdt = createAction("RECEIVE_CONFDDETAIL_NOTIINVEST_EDIT");
export const FailContFdNotiInvestEdt = createAction("FAILED_CONFDDETAIL_NOTIINVEST_EDIT");

// ----------Creat ContentFeed Noti Invest----
export const REQContFdNotiInvestCrt = createAction("REQUEST_CONFDDETAIL_NOTIINVEST_CRT");
export const RESContFdNotiInvestCrt = createAction("RECEIVE_CONFDDETAIL_NOTIINVEST_CRT");
export const FailContFdNotiInvestCrt = createAction("FAILED_CONFDDETAIL_NOTIINVEST_CRT");
// ----------Del ContentFeed Noti Invest------
export const REQContFdNotiInvestDel = createAction("REQUEST_CONFDDETAIL_NOTIINVEST_DEL");
export const RESContFdNotiInvestDel = createAction("RECEIVE_CONFDDETAIL_NOTIINVEST_DEL");
export const FailContFdNotiInvestDel= createAction("FAILED_CONFDDETAIL_NOTIINVEST_DEL");


export const CleanConFdDetail = createAction("CLEANUP_CONFDDETAIL_LIST");
