import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import {
  REQFundRecRiskList,
  REQFundRiskChgSta,
  CleanUpFundRiskChgSta
} from "./FundRecRisk_redux/actions";
import { MEMFundRecRiskDetailEdt } from "../FundRecommendDetail/FundRecRisk_redux/actions";
import { WarningModal } from "../../components/Modal";
import Table_FundRecRisk from "./table_FundRecRisk";
import { col_FundRecRisk } from "./list_of_columns";
import "./style.scss";
function FundRecRiskList(props) {
  const {
    REQFundRecRiskList,
    REQStrFundRecRiskList,
    FundRecRiskList,
    MEMFundRecRiskDetailEdt,
    REQFundRiskChgSta,
    REQStrFundRiskChgStaList,
    FundRiskChgStaList,
    CleanUpFundRiskChgSta,
    history
  } = props;

  const columns = React.useMemo(() => col_FundRecRisk, []);
  const [oShwModelAlert, SETxShwModelAlert] = useState(false);
  const [oValSta, SETxValSta] = useState();

  useEffect(() => {
    GETxFundRecRisk();
  }, []);

  const GETxFundRecRisk = () => {
    REQFundRecRiskList();
  };

  // --------------Click With Table------------------
  const SETxActTableColClick = ({ mode, rowData }) => {
        if (mode === "edit") {
      MEMFundRecRiskDetailEdt({ mode: "EDIT", RowDataAt: rowData });
      localStorage.setItem('currentFundrecRisk', JSON.stringify({ mode: "EDIT", rowData: rowData }))
      history.push(`/FundRec_RiskDetail/${rowData.id}`);
    } else if (mode === "show" || mode === "hide") {
      SETxValSta({
        riskId: rowData.id,
        Visible: mode,
        history
      });
      SETxShwModelAlert(true);
    }
  };

  const SHWxShowHide = () => {
    if (REQStrFundRiskChgStaList === false) {

      console.log("FundRiskChgStaList:",FundRiskChgStaList);
      if (FundRiskChgStaList.transactionResult === "Success") {
        // -------- Show,Hide -------------------
        CleanUpFundRiskChgSta();
        setTimeout(function() {
          GETxFundRecRisk();
        }, 100);
      } 
    } 
  };
  return (
    <Fragment>
      {REQStrFundRecRiskList ? (
        <div className="feed-list-progress">
          <CircularProgress />
          <p className="feed-list-progress-text">
            {REQStrFundRecRiskList
              ? "Retrieving data to get Fund Recommend By Risk"
              : null}
            {REQStrFundRecRiskList ? "..." : null}
          </p>
        </div>
      ) : null}

      {/*--------------Use Chage Hide and Show-------------------*/}
      {REQStrFundRiskChgStaList === true ? (
        <div className="usrAct-list-progress">
          <CircularProgress />
          <p className="usrAct-list-progress-text">
            {REQStrFundRiskChgStaList === true
              ? "Retrieving data to get Status..."
              : null}
          </p>
        </div>
      ) : (
        SHWxShowHide()
      )}

      <WarningModal
        show={oShwModelAlert}
        onHide={() => SETxShwModelAlert(false)}
        onHandleGo={() => {
          SETxShwModelAlert(false);
          // ----------- ดูจาก spect ----
          REQFundRiskChgSta(oValSta);
        }}
        onHandleCancel={() => {
          SETxShwModelAlert(false);
        }}
        type={"Disapprove"}
        status={"1"}
        title={
          oValSta != null
            ? `Are you sure you want to ${
                oValSta.Visible === "show" ? "Show" : "Hide"
              }?`
            : "null"
        }
      />
        {FundRecRiskList && (
          <Table_FundRecRisk
            data={FundRecRiskList}
            columns={columns}
            handleActionClick={SETxActTableColClick}
          />
        )}
      </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.fundRecRiskListReducer
  }),
  {
    REQFundRecRiskList,
    MEMFundRecRiskDetailEdt,
    REQFundRiskChgSta,
    CleanUpFundRiskChgSta
  }
);
export default compose(
  withConnect,
  withRouter
)(FundRecRiskList);
