import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import DateInput from "./DateInput";
import { CircularProgress } from "@material-ui/core";
import {
  REQImpRGT2B2CList,
  REQImpRGT2B2CUPD,
  CleanUpImpRGT2B2CUPD
} from "./redux/actions";
import Table from "./table";
import { col_ImpRGTView, col_ImpRGTUpd } from "./list_of_columns";
import Images from "../../utils/styles/images";
import { AlertModal } from "../../components/Modal";
import "./style.scss";

import Pagination from "./Pagination";
function ImpRGTtoB2CList(props) {
  const {
    // ---------------Import view-------
    REQImpRGT2B2CList,
    REQStrImpRGT2B2CList,
    ImpRGT2B2CList,
    totalImpRGT2B2CList,

    // --------------UPD----------------
    REQImpRGT2B2CUPD,
    REQStrImpRGT2B2CUPD,
    ImpRGT2B2CUPD,
    CleanUpImpRGT2B2CUPD,
    history
  } = props;
  const [tCurrentTab, SETxCurrentTab] = useState("Tab1");
  const oColImpRGTUpd = React.useMemo(() => col_ImpRGTUpd, []);
  const oColImpRGTView = React.useMemo(() => col_ImpRGTView, []);
  const oRefImpFile = React.useRef(null);

  const [tStartD, SETxStartD] = useState(new Date());
  const [tEndD, SETxEndD] = useState(new Date());
  const [tSrch, SETxSrch] = useState("");
  const [tOpSrch, SETxOpSrch] = React.useState("FileName");
  const [nPageIndex, SETxPageIndex] = useState(0);
  const [tSortH] = useState("created_at DESC");
  const [oImpFileShw, SEToImpFileShw] = useState([]);
  const [oImpFileSND, SEToImpFileND] = useState();
  const [bCleanStg1Time, SETbCleanStg1Time] = useState(true);
  const [oAlertType, SETxAlertType] = useState({
    AlertType: "",
    ShowAlert: false,
    Message: ""
  });


  const CALLxImpRGT2B2C = () => {
    if (history.location.PageCurrent === undefined) {
      SETxPageIndex(0);
      REQImpRGT2B2CList({
        page: "1",
        tStartD,
        tEndD,
        tSrch,
        tOpSrch,
        tSortH
      });
    } else {
      let nPage = history.location.PageCurrent + 1;
      // SET value UI
      SETxOpSrch(history.location.UISearch.tOpSrch);
      SETxSrch(history.location.UISearch.tSrch);

      SETxPageIndex(history.location.PageCurrent);
      REQImpRGT2B2CList({
        page: nPage.toString(),
        tStartD,
        tEndD,
        tSrch: history.location.UISearch.tSrch,
        tOpSrch: history.location.UISearch.tOpSrch,
        tSortH
      });
    }
  };

  useEffect(() => {
    if (bCleanStg1Time === true) {
      
      CleanUpImpRGT2B2CUPD();
      SETbCleanStg1Time(false);
    }
    
    if (tCurrentTab === "Tab1") {
      CALLxImpRGT2B2C();
    }
    // ---------browser back button fires---------
  }, [tCurrentTab]);

  const SRCHxUsrAct = () => {
    SETxPageIndex(0);
    REQImpRGT2B2CList({
      page: "1",
      tStartD,
      tEndD,
      tSrch,
      tOpSrch,
      tSortH
    });
  };
  const SRCHxUsrActClr = () => {
    SETxSrch("");
    SETxPageIndex(0);
    REQImpRGT2B2CList({
      page: "1",
      tStartD,
      tEndD,
      tSrch: "",
      tOpSrch,
      tSortH
    });
  };
  const RadioInput = ({ label, value, checked, setter }) => {
    return (
      <span>
        <input
          type="radio"
          checked={checked === value}
          onChange={() =>
            {
              setter(value);
            }
          }
        />
        <span style={{ marginLeft: 0 }}>{label}</span>
      </span>
    );
  };
  const GETxWidthSrch = () => {
    if (tOpSrch === "UUID") {
      return 450;
    } else {
      return 331;
    }
  };
  const SETxPageGo = pnPageAt => {
    let nPageAt = pnPageAt + 1;
    SETxPageIndex(pnPageAt);
    REQImpRGT2B2CList({
      page: nPageAt.toString(),
      tSrch,
      tStartD,
      tEndD,
      tOpSrch,
      tSortH
    });
    
  };
  const CALxPaging = () => {
    let nPageCnt = Math.ceil(totalImpRGT2B2CList / 10);

    return nPageCnt > 1 ? (
      <Pagination
        nPageIndex={nPageIndex}
        total={nPageCnt}
        gotoPage={SETxPageGo}
      />
    ) : null;
  };

  const BTNxFile = () => {
    oRefImpFile.current.value = "";
    oRefImpFile.current.click();
  };

  const isCSVContent = (content) => {
    const lines = content.split('\n');
    if (lines.length < 2) {
      return false; // Not enough lines to be a valid CSV
    }

    if (lines[0].split(',').length < 69) {
      console.log("lines[0].split(',').length ",lines[0].split(',').length);
      console.log("isCSVContent false");
      return false; // Inconsistent column count
    }
    console.log("isCSVContent true");
    return true;
  };

  const SETxFile = e => {
    SEToImpFileND(e.target.files);
    let oFile = [];
    if (e.target.files.length > 3) {
      // Check limit file
      SETxAlertType({
        AlertType: "OverSize",
        ShowAlert: true,
        Message: "Limit file only 3 file"
      });
      SEToImpFileShw([]);
      return;
    }
    
    const allowedTypes = ['text/csv'];

    for (let i = 0; i < e.target.files.length; i++) {
      let selectedFile = e.target.files[i];
      if (selectedFile.size > 1048576) {
        // Check size file
        SETxAlertType({
          AlertType: "OverSize",
          ShowAlert: true,
          Message:
            "File name '" +
            selectedFile.name +
            "' limit size 1024 KB. Please check."
        });
        // showAlert = true; << what ?
      } else if (!allowedTypes.includes(selectedFile.type)) {
        SETxAlertType({
          AlertType: "NotCSV",
          ShowAlert: true,
          Message:
            "File name '" + selectedFile.name + "' is not CSV."
        });
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result;
          if (isCSVContent(content)) {
            oFile.push({
              lastModified: selectedFile.lastModified,
              lastModifiedDate: selectedFile.lastModifiedDate,
              name: selectedFile.name,
              size: selectedFile.size,
              type: selectedFile.type,
              webkitRelativePath: selectedFile.webkitRelativePath
            });
            SEToImpFileShw(oFile);
          
          } else {
           
            SETxAlertType({
              AlertType: "NotCSV",
              ShowAlert: true,
              Message:
                "File name '" + selectedFile.name + "' is not CSV."
            });
            SEToImpFileShw(oFile);
          }
        };
        reader.readAsText(selectedFile);
      }
    }

  };
  const SNDxFile = () => {
    SEToImpFileShw([]);
    REQImpRGT2B2CUPD({ oImpFileSND });
  };
  const SHWxUpFile = () => {
    if (REQStrImpRGT2B2CUPD === true) {
      return null;
    } else {
      if (ImpRGT2B2CUPD.length !== 0) {
        if (ImpRGT2B2CUPD.errorCode) {
          SETxAlertType({
            AlertType: "ImpErr",
            ShowAlert: true,
            Message: ImpRGT2B2CUPD.message
          });
          CleanUpImpRGT2B2CUPD();
          return null;
        } else {
          return (
            <div>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <h4
                  style={{
                    textAlign: "left",
                    color: "#000000",
                    fontWeight: "bold",
                    fontFamily: "Lato"
                  }}
                >
                  <span>Group send file : </span>
                  {ImpRGT2B2CUPD.SendGroup}
                </h4>
                <h4
                  style={{
                    textAlign: "left",
                    color: "#000000",
                    fontWeight: "bold",
                    fontFamily: "Lato"
                  }}
                >
                  <span>Message</span>
                  <span style={{ paddingLeft: 56 }}>: </span>
                  {ImpRGT2B2CUPD.MessageStep}
                </h4>
              </div>
              <Table
                columns={oColImpRGTUpd}
                data={ImpRGT2B2CUPD.File}
                pageSelect={0}
              />
            </div>
          );
        }
      } else {
        return null;
      }
    }
  };

  const SHWxTab1 = () => {
    return (
      <div className="client-detail-profile-container">
        {REQStrImpRGT2B2CList ? (
          <div className="regisDevice-list-progress">
            <CircularProgress />
            <p className="regisDevice-list-progress-text">
              {REQStrImpRGT2B2CList ? "Retrieving UserAccount list..." : null}
            </p>
          </div>
        ) : null}

        <div className="labelInput" style={{ marginLeft: 20, marginTop: 50 }}>
          <span className="labelInputText" style={{ width: 100 }}>
            Search By
          </span>
          <span style={{ paddingRight: 10 }}>:</span>
          <div
            className="usrAct-search"
            style={{ marginTop: 0, width: GETxWidthSrch() }}
          >
            <img
              src={Images.search.search}
              alt="search-icon"
              className="transactions-list-begin-search"
              onClick={() => SRCHxUsrAct()}
            />
            <input
              placeholder={"Search by " + tOpSrch}
              className="usrAct-search-bar"
              value={tSrch}
              onChange={event => {
                SETxSrch(event.target.value.trim());
              }}
              onKeyDown={event => {
                
                if (event.keyCode === 13) {
                  // enter
                  SRCHxUsrAct();
                }
              }}
            />
            {tSrch && tSrch.length > 0 ? (
              <img
                src={Images.closeButton.close}
                alt="cancel-search"
                className="transactions-details-cancel-search"
                onClick={() => SRCHxUsrActClr()}
              />
            ) : null}
          </div>
        </div>
        <div
          className="clients-radio-container"
          style={{ marginLeft: 145, marginBottom: 20 }}
        >
          <RadioInput
            label="File Name"
            value="FileName"
            checked={tOpSrch}
            setter={SETxOpSrch}
          />
          <span style={{ marginRight: 20 }} />
        </div>
        <div className="date-range-container" style={{ width: 400 }}>
          <p className="labelInputText" style={{ width: 290 }}>
            Date
            <span style={{ color: "#FF0000" }}>*</span>
          </p>
          <p style={{ paddingRight: 10 }}>:</p>
          <DatePicker
            selected={tStartD}
            onChange={date => SETxStartD(date)}
            selectsStart
            startDate={tStartD}
            endDate={tEndD}
            dateFormat="dd/MM/yy"
            customInput={<DateInput />}
          />
          <p className="date-range-separator">-</p>
          <DatePicker
            selected={tEndD}
            onChange={date => moment(date).isAfter(tStartD) && SETxEndD(date)}
            selectsEnd
            startDate={tStartD}
            endDate={tEndD}
            dateFormat="dd/MM/yy"
            customInput={<DateInput />}
          />
        </div>
        <button
          style={{ marginLeft: 20, marginBottom: 20 }}
          onClick={() => {
            SRCHxUsrAct();
          }}
          className="conFd-synce-button"
        >
          Search
        </button>
        {REQStrImpRGT2B2CList ? null : (
          <Table
            columns={oColImpRGTView}
            data={ImpRGT2B2CList}
            pageSelect={nPageIndex}
          />
        )}
        {REQStrImpRGT2B2CList ? null : <CALxPaging />}
      </div>
    );
  };
  const SHWxTab2 = () => {
    return (
      <div className="client-detail-profile-container">
        {REQStrImpRGT2B2CUPD ? (
          <div className="regisDevice-list-progress">
            <CircularProgress />
            <p className="regisDevice-list-progress-text">
              {REQStrImpRGT2B2CUPD ? "Retrieving Import RGT to B2C..." : null}
            </p>
          </div>
        ) : null}
        <AlertModal
          show={oAlertType.ShowAlert}
          onHide={() =>
            SETxAlertType({
              AlertType: "",
              ShowAlert: false,
              Message: ""
            })
          }
          onHandleOK={() => {
            SETxAlertType({
              AlertType: "",
              ShowAlert: false,
              Message: ""
            });
          }}
          type={"Disapprove"}
          status={"1"}
          title={oAlertType.Message}
        />
        <h3
          style={{
            textAlign: "center",
            color: "#000000",
            fontWeight: "bold",
            fontFamily: "Lato"
          }}
        >
          Upload file multiple
        </h3>

        <div className="labelInput" style={{ marginTop: 50 }}>
          <span className="labelInputText" style={{ width: 150 }}>
            <button className="conFd-synce-button" onClick={BTNxFile}>
              Choose file
            </button>
          </span>
          <span style={{ paddingRight: 10 }} />
          <span
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingRight: 40
            }}
          >
            <button
              style={{
                cursor: oImpFileShw.length !== 0 ? "pointer" : "default",
                width: 150
              }}
              className={
                oImpFileShw.length !== 0
                  ? "client-detail-header-button"
                  : "client-detail-header-button-disabled"
              }
              disabled={oImpFileShw.length !== 0 ? false : true}
              onClick={SNDxFile}
            >
              Send file
            </button>
          </span>
        </div>
        <input
          type="file"
          accept=".csv"
          style={{ display: "none" }}
          onChange={SETxFile}
          multiple
          ref={oRefImpFile}
        />
        {oImpFileShw.length !== 0 ? (
          <table
            className="TbShw"
            style={{
              marginTop: 10,
              marginBottom: 0,
              marginRight: 40,
              height: 0
            }}
          >
            <tbody>
              <tr>
                <th style={{ paddingLeft: 10, width: 10 }}>NO.</th>
                <th style={{ paddingLeft: 35, width: 485 }}>Name</th>
                <th>Size</th>
              </tr>
            </tbody>
          </table>
        ) : null}
        <ul
          className="ListShw"
          style={{ marginTop: 20, marginBottom: 20, marginRight: 40 }}
        >
          {oImpFileShw.length !== 0 &&
            oImpFileShw.map((oFi, index) => (
              <li
                className="ListShw-li"
                key={index}
                style={{ paddingLeft: 10, height: 35 }}
              >
                <span>{index + 1}</span>
                <span style={{ paddingLeft: 50, width: 500 }}>{oFi.name}</span>
                <span>{(oFi.size / 1024).toFixed(2)} KB</span>
              </li>
            ))}
        </ul>
        <SHWxUpFile />
      </div>
    );
  };
  return (
    <Fragment>
      <div className="client-detail-header" />
      <div className="client-detail-container">
        <div className="client-detail-tabs-container">
          <button
            className={
              tCurrentTab === "Tab1"
                ? "client-detail-tab-profile"
                : "client-detail-tab-profile-unselected"
            }
            onClick={() => SETxCurrentTab("Tab1")}
          >
            View file
          </button>
          <button
            className={
              tCurrentTab === "Tab2"
                ? "client-detail-tab-funds"
                : "client-detail-tab-funds-unselected"
            }
            onClick={() => SETxCurrentTab("Tab2")}
          >
            Import RGT file
          </button>
        </div>
        {tCurrentTab === "Tab1" ? SHWxTab1() : null}
        {tCurrentTab === "Tab2" ? SHWxTab2() : null}
      </div>
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.impRGTtoB2CListReducer
  }),
  {
    REQImpRGT2B2CList,
    REQImpRGT2B2CUPD,
    CleanUpImpRGT2B2CUPD
  }
);

export default compose(
  withConnect,
  withRouter
)(ImpRGTtoB2CList);
