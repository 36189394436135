import { createReducer } from "redux-act";

import {
  requestFundsList,
  receiveFundsList,
  failFundsList,
  cleanupFundsList,
  requestUploadDocuments,
  receiveUploadDocuments,
  failUploadDocuments,
  resetUploadDocuments,
  // ----Sync NAV---
  REQSyncNAV,
  RESSyncNAV,
  FailSyncNAV

} from "./actions";

const initialState = {
  requestingFundsList: false,
  fundsList: [],
  errorMessage: false,
  totalFunds: 0,
  requestingDocumentUpload: false,
  uploadDocStatus: null,
    // ----Sync NAV---
  REQSyncStrNAV: false,
  SyncListNAV:[],
  totalSyncNAV:0,
  REQSyncStrMsg:"",
  syncTime: null
};


const fundsListReducer = createReducer(
  {
  
    [requestFundsList]: (state) => {
      return {
        ...state,
        requestingFundsList: true
      };
    },
   
    [receiveFundsList]: (state, payload) => {
      return {
        ...state,
        requestingFundsList: false,
        fundsList: payload.data,
        totalFunds: payload.total
      };
    },
     
    [failFundsList]: (state) => {
      return {
        ...state,
        requestingFundsList: false,
        fundsList: [],
        totalFunds: 0
      };
    },
    [cleanupFundsList]: () => {
      return initialState;
    },
    [requestUploadDocuments]: (state) => {
      return {
        ...state,
        requestingDocumentUpload: true
      };
    },
    [receiveUploadDocuments]: (state) => {
      return {
        ...state,
    requestingDocumentUpload: false,
    uploadDocStatus: "SUCCESS"
      };
    },
    [failUploadDocuments]: (state) => {
      return {
        ...state,
    requestingDocumentUpload: false,
    uploadDocStatus: "FAILED"
      };
    },
    [resetUploadDocuments]: (state) => {
      return {
        ...state,
        requestingDocumentUpload: false,
        uploadDocStatus: null
      };
    } ,
   // -------Synce NAV------
   [REQSyncNAV]: (state) => {
     
     return {
       ...state,
       REQSyncStrNAV: true,
       REQSyncStrMsg:""
     };
   },   
  [RESSyncNAV]: (state, payload) => {
     return {
       ...state,
       REQSyncStrNAV: false,
       SyncListNAV: payload.data,
       totalSyncNAV: payload.data.length,
       syncTime: new Date(),
       REQSyncStrMsg:"success"
     };
   },      
  [FailSyncNAV]: (state) => {
     return {
       ...state,
       REQSyncStrNAV: false,
       SyncListNAV: [],
       totalSyncNAV: 0,
       REQSyncStrMsg:"failure connect api server"
     };
   }


  },
  initialState
);

export default fundsListReducer;
