import React from "react";
import moment from "moment";
import Images from "../../utils/styles/images";

// start test



function formatNumberCell(cellValue, rowNumber , rowData, columnName, pageIndex,pageSelect, handleActionClick) {
  if(pageSelect!==0)
    {
     return rowNumber+(pageSelect*10);
    }
    else
    {
      return rowNumber;
    }
  
}

function formatDeviceNameCell(cellValue, rowNumber , rowData, columnName, pageIndex,pageSelect, handleActionClick) {
    return (
      <div>
        <button
          onClick={() => handleActionClick(cellValue, rowNumber ,rowData, columnName, pageIndex)}
          className="feed-list-article-button"
        >
          {cellValue}
        </button>      
      </div>
    );
   
}


function formatDateCell(cellValue, rowNumber , rowData, columnName, pageIndex,pageSelect, handleActionClick) {
   if (cellValue) {
      return moment(cellValue).format("YYYY-MM-DD");
    } else {
      return "-";
    }
}

function formatVerifiedBy(cellValue) {
  if (cellValue) {
    return (
      <span className="regisDevice-textOverflow">
        {cellValue}
      </span>
    )
  }
}

export function modifyCell(cell, pageIndex, pageSize, pageSelect, handleActionClick) {
  const { props } = cell;
  const columnName = props?.cell?.column?.Header || null;
  const cellValue = props?.cell?.value || null;
  const rowNumber = props?.row?.index + 1;
  const rowData = props?.row?.original || null;

  const formatters = {
    "NO.": formatNumberCell,
    "DEVICE NAME": formatDeviceNameCell,
    "REGISTERED DATE": formatDateCell,
    "DEREGISTERED DATE": formatDateCell,
    "VERIFIED BY": formatVerifiedBy,
  };

  // Early return for known columns
  if (formatters[columnName]) return formatters[columnName](cellValue, rowNumber , rowData, columnName, pageIndex,pageSelect, handleActionClick);

  // Default: return the cell as-is
  return cell; 
}
// end test

// This is just for the header cells
export function modifyCellHeader(cell, handleActionHeaderClick, handleSortHeader) {
  if (cell === "DEVICE NAME") {
    return (
      <button
        className="regisDevice-header-with-sort"
      >
        DEVICE NAME
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }
  else if (cell === "VERIFIED BY") {
    return (
      <button
        className="regisDevice-header-with-sort"
      >
        VERIFIED BY
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }  else if (cell === "CREATE DATE") {
    return (
      <button
        className="regisDevice-header-with-sort"
      >
        CREATE DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }else if (cell === "REGISTERED DATE") {
    return (
      <button
        className="regisDevice-header-with-sort"
      >
        REGISTERED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }else if (cell === "DEREGISTERED DATE") {
    return (
      <button
        className="regisDevice-header-with-sort"
      >
        DEREGISTERED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }
  
  
  else if (cell === "TYPE") {
    return (
      <button
        className="regisDevice-header-with-sort"
      >
        TYPE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }else if (cell === "STATUS") {
    return (
      <button
        className="regisDevice-header-with-sort"
      >
        STATUS
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }
  else {
    return cell;
  }
}
