import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQActiList } from "./redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import DateInput from "./DateInput";
import { MEMActiDetail } from "../ActiDetail/redux/actions";
import Images from "../../utils/styles/images";
import "./style.scss";

function ActiList(props) {
  const {
    REQActiList,
    REQStrActivityList,
    ActivityList,
    MEMActiDetail,
    history
  } = props;
  const columns = React.useMemo(() => list_of_columns, []);
  const [tStartD, SETxStartD] = useState(new Date());
  const [tEndD, SETxEndD] = useState(new Date());
  const [tUsrType, SETxUsrType] = useState("");

  const [tSrch, SETxSrch] = useState("");
  const [tOpSrch, SETxOpSrch] = useState("E-Mail");

  // console.log("Actilist: ", ActivityList[2].activityData)
  // const parsedActivityData = JSON.parse(ActivityList[2].activityData);
  // console.log("parsed: ", parsedActivityData)
  // const tableData = {
  //   ...ActivityList,
  //   ...parsedActivityData
  // };
  // console.log("merged data:", tableData)
  const parsedData = ActivityList.map((ActivityList) => {
    if (ActivityList.activityData === "-") {
      return ActivityList
    } else {
    try {
      const parsedActivityData = JSON.parse(ActivityList.activityData);
  
      return {
        ...ActivityList,
        ...parsedActivityData, 
      };
    } catch (error) {
      console.error(`Error parsing activityData for record ID ${ActivityList.id}:`, error);
      return ActivityList;
    }
  }
  });
  
  const CALLxActi = () => {
    let tUserType1 = "";
    if (
      history.location.pathname === "/Activity" ||
      history.location.pathname === "/Activity_Usr"
    ) {
      SETxUsrType("user");
      tUserType1 = "user";
    } else if (history.location.pathname === "/Activity_Admin") {
      SETxUsrType("Admin");
      tUserType1 = "Admin";
    } else if (history.location.pathname === "/UsrActDetail_ViewLog") {
      // มาจาก  Button UsrActDetail
      // fix lint
      let tSrch1

      if (history.location.search !== null && history.location.search !== "") {
        tSrch1 = history.location.search.substring(
          6,
          history.location.search.length
        );

        SETxUsrType("user");
        SETxSrch(tSrch1);
        SETxOpSrch("E-Mail");
        tUserType1 = "user";
      } else {
        
        tSrch1 = "";
        SETxUsrType("user");
        SETxSrch(tSrch1);
        SETxOpSrch("E-Mail");
        tUserType1 = "user";
      }

      REQActiList({
        page: "1",
        tStartD,
        tEndD,
        tSrch: tSrch1,
        tOpSrch: "E-Mail",
        tUserType: tUserType1
      });
    }

    if (history.location.pathname !== "/UsrActDetail_ViewLog") {
      if (history.location.UISearch !== undefined) {
        // SET value UI
        SETxStartD(new Date(history.location.UISearch.tStartD));
        SETxEndD(new Date(history.location.UISearch.tEndD));
        SETxOpSrch(history.location.UISearch.tOpSrch);
        SETxSrch(history.location.UISearch.tSrch);

        REQActiList({
          page: (history.location.PageCurrent + 1).toString(),
          tStartD: history.location.UISearch.tStartD,
          tEndD: history.location.UISearch.tEndD,
          tSrch: history.location.UISearch.tSrch,
          tOpSrch: history.location.UISearch.tOpSrch,
          tUserType: tUserType1
        });
      } else {
        REQActiList({
          page: "1",
          tStartD,
          tEndD,
          tSrch,
          tOpSrch,
          tUserType: tUserType1
        });
      }
    }
  };

  useEffect(() => {
    CALLxActi();
  }, []);

  const SRCHxActi = () => {
    
    if (history.location.pathname === "/UsrActDetail_ViewLog") {
      // fix lint
      let tSrch
      if (history.location.search !== null && history.location.search !== "") {
        tSrch = history.location.search.substring(
          6,
          history.location.search.length
        );
      } else {
        tSrch = "";
      }
      REQActiList({
        page: "1",
        tStartD,
        tEndD,
        tSrch,
        tOpSrch,
        tUserType: tUsrType
      });
    } else {
      REQActiList({
        page: "1",
        tStartD,
        tEndD,
        tSrch,
        tOpSrch,
        tUserType: tUsrType
      });
    }
  };
  const SRCHxActiClr = () => {
    SETxSrch("");
    history.push({
      pathname: "/Activity_Usr",
      PageCurrent: 0,
      UISearch: {
        tStartD: tStartD,
        tEndD: tEndD,
        tSrch: tSrch,
        tOpSrch: tOpSrch
      }
    });
    REQActiList({
      page: "1",
      tStartD,
      tEndD,
      tSrch: "",
      tOpSrch,
      tUserType: tUsrType
    });
  };
  const SHWxTxtSrchFrmUsrActDetail = () => {
    if (history.location.pathname === "/UsrActDetail_ViewLog") {
      let tSrch = history.location.search;
      if (history.location.search !== null && history.location.search !== "") {
        tSrch = history.location.search.substring(
          6,
          history.location.search.length
        );
      } else {
        tSrch = "";
      }
      return (
        <div className="labelInput" style={{ marginTop: 70, marginBottom: 20 }}>
          <span
            className="labelInputText"
            style={{ marginLeft: 21, width: 100 }}
          >
            Search By
          </span>
          <span style={{ paddingRight: 10 }}>:</span>
          <span>{tSrch}</span>
        </div>
      );
    }
  };
  const RadioInput = ({ label, value, checked, setter }) => {
    return (
      <span>
        <input
          type="radio"
          checked={checked === value}
          onChange={() =>
            {
              setter(value);
            }
          }
        />
        <span style={{ marginLeft: 0 }}>{label}</span>
      </span>
    );
  };

  const SETxActTableClick = (cellValue, rowData, columnName, pageIndex) => {
    if (columnName === "DEVICE NAME") {
      if (
        history.location.pathname === "/Activity" ||
        history.location.pathname === "/Activity_Usr"
      ) {
        MEMActiDetail({
          RowDataAt: rowData,
          PageCurrent: pageIndex,
          UISearch: {
            tStartD: tStartD,
            tEndD: tEndD,
            tSrch: tSrch,
            tOpSrch: tOpSrch
          }
        });
        localStorage.setItem('currentActivityDetail', JSON.stringify({
          RowDataAt: rowData,
          PageCurrent: pageIndex,
          From: 'User',
          UISearch: {
            tStartD: tStartD,
            tEndD: tEndD,
            tSrch: tSrch,
            tOpSrch: tOpSrch
          }
        }))
        history.push(`/ActivityDetail_Usr/${rowData.id}`);
      } else if (history.location.pathname === "/UsrActDetail_ViewLog") {
        MEMActiDetail({ RowDataAt: rowData, PageCurrent: pageIndex });
        window.addEventListener("popstate", function() {
          history.push({
            pathname: "/UsrActDetail_ViewLog",
            PageCurrent: pageIndex
          });
        });
        history.push(`/UsrActDetail_ViewLogDetail/${rowData.id}`);
      } else if (history.location.pathname === "/Activity_Admin") {
        MEMActiDetail({ RowDataAt: rowData, PageCurrent: pageIndex });
        localStorage.setItem('currentActivityDetail', JSON.stringify({
          RowDataAt: rowData,
          PageCurrent: pageIndex,
          From: 'Admin',
          UISearch: {
            tStartD: tStartD,
            tEndD: tEndD,
            tSrch: tSrch,
            tOpSrch: tOpSrch
          }
        }))        
        history.push(`/ActivityDetail_Admin/${rowData.id}`);
      }
    } else if (columnName === "EXPORT") {
      let oLog = JSON.parse(rowData.activityData);
      let tDateCreate = "";

      tDateCreate = rowData.createdAt.replace("T", " ").replace(".000Z", "");
      console.log('rowData', rowData);
      let tJson = {
        id: rowData.id,
        customerUuid: rowData.customerUuid,
        firebaseFid: rowData.firebaseFid,
        appVersion: rowData.appVersion,
        appPlatform: rowData.appPlatform,
        deviceName: rowData.deviceName,
        ipAddress: rowData.ipAddress,
        userType: rowData.userType,
        email: rowData.email,
        mobile: rowData.mobile,
        activityType: rowData.activityType,
        createdAt: tDateCreate,
        userName: rowData.userName,
        lastName: rowData.lastName,
        activityData: oLog
      };
      let oElement = document.createElement("a");
      let oFile = new Blob(
        [JSON.stringify(tJson, null, 2)],
        {
          fileType: "text/json"
        }
      );
      oElement.href = URL.createObjectURL(oFile);
      oElement.download =
        "ActivityLog[" +
        tUsrType +
        "]-[" +
        rowData.id +
        "]-[" +
        rowData.customerUuid +
        "]-[" +
        tDateCreate +
        "].json";
      document.body.appendChild(oElement);
      oElement.click();
    }
  };

  return (
    <Fragment>
      <div
        className="button-create-container"
        style={{ marginBottom: 1, paddingRight: 70 }}
      >
        <div style={{ height: 40 }} />
      </div>

      {REQStrActivityList ? (
        <div className="regisDevice-list-progress">
          <CircularProgress />
          <p className="regisDevice-list-progress-text">
            {REQStrActivityList ? "Retrieving Activity list..." : null}
          </p>
        </div>
      ) : null}
      {history.location.pathname !== "/UsrActDetail_ViewLog" ? (
        <div className="labelInput" style={{ marginLeft: 20, marginTop: 50 }}>
          <span className="labelInputText" style={{ width: 100 }}>
            Search By
          </span>
          <span style={{ paddingRight: 10 }}>:</span>
          <span>
            <div className="acti-search" style={{ marginTop: 0 }}>
              <img
                src={Images.search.search}
                alt="search-icon"
                className="transactions-list-begin-search"
                onClick={() => SRCHxActi()}
              />
              <input
                placeholder={"Search by " + tOpSrch}
                className="acti-search-bar"
                onChange={event => {
                  SETxSrch(event.target.value.trim());
                }}
                value={tSrch}
                onKeyDown={event => {
                  if (event.keyCode === 13) {
                    SETxSrch(event.target.value.trim());
                    SRCHxActi();
                  }
                }}
              />
              {tSrch && tSrch.length > 0 ? (
                <img
                  src={Images.closeButton.close}
                  alt="cancel-search"
                  className="transactions-details-cancel-search"
                  onClick={() => SRCHxActiClr()}
                />
              ) : null}
            </div>
          </span>
        </div>
      ) : (
        <SHWxTxtSrchFrmUsrActDetail />
      )}

      {history.location.pathname !== "/UsrActDetail_ViewLog" ? (
        <div
          className="clients-radio-container"
          style={{ marginLeft: 145, marginBottom: 20 }}
        >
          <RadioInput
            label="E-Mail"
            value="E-Mail"
            checked={tOpSrch}
            setter={SETxOpSrch}
          />
          <span style={{ marginRight: 20 }} />
          <RadioInput
            label="Customer ID"
            value="Customer ID"
            checked={tOpSrch}
            setter={SETxOpSrch}
          />
          <span style={{ marginRight: 20 }} />
          <RadioInput
            label="Username"
            value="Username"
            checked={tOpSrch}
            setter={SETxOpSrch}
          />
        </div>
      ) : null}

      <div className="date-range-container" style={{ width: 400 }}>
        <p className="labelInputText" style={{ width: 290 }}>
          Date
          <span style={{ color: "#FF0000" }}>*</span>
        </p>
        <p style={{ paddingRight: 10 }}>:</p>
        <DatePicker
          selected={tStartD}
          onChange={date => SETxStartD(date)}
          selectsStart
          startDate={tStartD}
          endDate={tEndD}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
        />
        <p className="date-range-separator">-</p>
        <DatePicker
          selected={tEndD}
          onChange={date => moment(date).isAfter(tStartD) && SETxEndD(date)}
          selectsEnd
          startDate={tStartD}
          endDate={tEndD}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
        />
      </div>
      <button
        style={{ marginLeft: 20, marginBottom: 20 }}
        onClick={() => {
          SRCHxActi();
        }}
        className="conFd-synce-button"
        disabled={!tStartD || !tEndD}
      >
        Filter
      </button>

      <Table
        data={parsedData}
        HightTable={780}
        columns={columns}
        handleActionClick={SETxActTableClick}
        history={history}
      />
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.actiListReducer
  }),
  {
    REQActiList,
    MEMActiDetail
  }
);

export default compose(
  withConnect,
  withRouter
)(ActiList);
