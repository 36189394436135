import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setFullImgUrl } from "./redux/actions";


const FullImage = (props) => {
  
  // const [ setFullImgUrl, fullImgUrl ] = useState("");
  function useQueryParam(param) {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    return params.get(param);
  }
  // const authorizedUrls = [
  //   "https://example.com/image1.jpg",
  //   "https://example.com/image2.jpg",
  //   // Add more authorized URLs here
  // ];
  const imgUrl = useQueryParam('imgUrl');
  const rotateAngle = useQueryParam('rotate');
  const rotateValue = rotateAngle * 90;
  // const isValidUrl = authorizedUrls.includes(imgUrl);


  
    // useEffect(() => {
    //     // clearing fullImgUrl
    //     setFullImgUrl('');

    // }, [])
  //   const { state } = location;
//   const queryParams = React.useMemo(() => queryStringToObject(search, { imgUrl: "" }), [search]);
return (
  <div style={{ 
    display: 'flex', 
    width: '100vw', 
    height: '100vh',
    overflow: 'hidden'
  }}>
    <img
      style={{
        width: 'auto',
        height: 'auto',
        maxWidth: '80vw',
        maxHeight: '80vh',
        objectFit: 'contain',
        transform: `rotate(${rotateValue}deg)`
      }}
      src={imgUrl}
      alt=""
    />
  </div>
);
};
const withConnect = connect(
  (state) => ({
    ...state.untHldDetailReducer,
  }),
  {
    setFullImgUrl
  }
);
export default compose(withConnect, withRouter)(FullImage);
