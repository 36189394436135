import pkg from "../../package.json";
export const aConfigUrl = () => {
  // config use for OKTA Admin Dashboard
  if (process.env.REACT_APP_ENV.trim() === "dev_sit") {
    return {
      Phase: "localhost",
      TextPhase: "localhost_sit",
      issuer: "https://principal-poc.okta.com/oauth2/ausf2c9hxS0KHQmKl3l6",
      clientId: "0oaj5mu94YmngL2t13l6",
      discoveryUri:
        "https://principal-poc.okta.com/oauth2/ausf2c9hxS0KHQmKl3l6",
      pkce: true,
      redirectUri: "http://localhost:3000/authService/callback",
      logoutOktaUri: "https://principal-poc.okta.com/login/signout",
      logoutUri: "https://app.dev.principal.th/logout",
      Version: `v.${pkg.version}` 
    };
  } else if (process.env.REACT_APP_ENV.trim() === "dev_uat") {
    return {
      Phase: "localhost",
      TextPhase: "localhost_uat",
      issuer: "https://principal.oktapreview.com/oauth2/ausx7pbotj5nvGlol0h7",
      clientId: "0oaygjhktbnymeZ3e0h7",
      discoveryUri:
        "https://principal.oktapreview.com/oauth2/ausx7pbotj5nvGlol0h7",
      pkce: true,
      redirectUri: "http://localhost:3000/authService/callback",
      logoutOktaUri: "https://principal.oktapreview.com/login/signout",
      logoutUri: "https://app.staging.principal.th/logout",
      Version: `v.${pkg.version}` 
    };
  } else if (process.env.REACT_APP_ENV.trim() === "production") {
     //real production 
    return {
      Phase: "PRO",
      TextPhase: "production",
      issuer: "https://principal-ciam1.okta.com/oauth2/ausjqe9nwgvp5evcg3l6",
      clientId: "0oajqit868r4rniZM3l6",
      discoveryUri:
        "https://principal-ciam1.okta.com/oauth2/ausjqe9nwgvp5evcg3l6",
      pkce: true,
      redirectUri: "https://app.principal.th/authService/callback",
      logoutOktaUri: "https://principal-ciam1.okta.com/login/signout",
      logoutUri: "https://app.principal.th/logout",
      Version: `v.${pkg.version}`
    };
  } else if (process.env.REACT_APP_ENV.trim() === "uat") {
    return {
      Phase: "UAT",
      TextPhase: "UAT",
      issuer: "https://principal.oktapreview.com/oauth2/ausx7pbotj5nvGlol0h7",
      clientId: "0oaygjhktbnymeZ3e0h7",
      discoveryUri:
        "https://principal.oktapreview.com/oauth2/ausx7pbotj5nvGlol0h7",
      pkce: true,
      redirectUri:
        "https://app.staging.principal.th/authService/callback",
      logoutOktaUri: "https://principal.oktapreview.com/login/signout",
      logoutUri: "https://app.staging.principal.th/logout",
      Version: `v.${pkg.version}` 
    };
  } else {
      return {
        Phase: "SIT",
        TextPhase: "SIT",
        issuer: "https://principal-poc.okta.com/oauth2/ausf2c9hxS0KHQmKl3l6",
        clientId: "0oaj5mu94YmngL2t13l6",
        discoveryUri:
          "https://principal-poc.okta.com/oauth2/ausf2c9hxS0KHQmKl3l6",
        pkce: true,
        redirectUri:
          "https://app.dev.principal.th/authService/callback",
          logoutOktaUri: "https://principal-poc.okta.com/login/signout",
        logoutUri: "https://app.dev.principal.th/logout",
        Version: `v.${pkg.version}` 
      };
  }
};
