import { createReducer } from "redux-act";

import {
  requestContentFeedListAction,
  receiveContentFeedListAction,
  failContentFeedListAction,
  requestInterestsListAction,
  receiveInterestsListAction,
  failInterestsListAction,
  // ----GET ConFd---
  REQConFdList,
  RESConFdList,
  FailConFdList,
  // -----GET SyncEN---
  REQSyncEN,
  RESSyncEN,
  FailSyncEN,
  // -----GET SyncTH---
  REQSyncTH,
  RESSyncTH,
  FailSyncTH,

  // -----GET Sync PDF EN---
  REQSyncPDFEN,
  RESSyncPDFEN,
  FailSyncPDFEN,
  // -----GET Sync PDF TH---
  REQSyncPDFTH,
  RESSyncPDFTH,
  FailSyncPDFTH,
  CleanUpConFdList,

  //
  REQFundCodeList,
  RESFundCodeList,
  FailFundCodeList
} from "./actions";

const initialState = {
  requestingContentFeedList: false,
  requestingInterestsList: false,
  articlesList: [],
  interestsList: [],
  totalArticles: 0,
  // ---Get Confd--
  REQStrConFdList: false,
  ConFdList: [],
  totalConFd: 0,
  // ---Get SynceEN--
  SyncListEN: [],
  totalSyncEN: 0,
  // ---Get SynceTH--
  SyncListTH: [],
  totalSyncTH: 0,

  // ---Get Synce PDF EN--
  SyncListPDFEN: [],
  totalSyncPDFEN: 0,
  // ---Get SyncePDF TH--
  SyncListPDFTH: [],
  totalSyncPDFTH: 0
};

const contentFeedListReducer = createReducer(
  {
    // ----------------OLD-------------------
    [requestContentFeedListAction]: (state) => {
      return {
        ...state,
        requestingContentFeedList: true
      };
    },
    [receiveContentFeedListAction]: (state, payload) => {
      return {
        ...state,
        requestingContentFeedList: false,
        articlesList: payload.articles,
        totalArticles: payload.total
      };
    },
    [failContentFeedListAction]: (state) => {
      return {
        ...state,
        requestingContentFeedList: false,
        articlesList: [],
        totalArticles: 0
      };
    },
    [requestInterestsListAction]: (state) => {
      return {
        ...state,
        requestingInterestsList: true
      };
    },
    [receiveInterestsListAction]: (state, payload) => {
      return {
        ...state,
        requestingInterestsList: false,
        interestsList: payload
      };
    },
    [failInterestsListAction]: (state) => {
      return {
        ...state,
        requestingInterestsList: false,
        interestsList: []
      };
    },

    // -------GET------
    [REQConFdList]: (state) => {
      return {
        ...state,
        REQStrConFdList: true
      };
    },
    [RESConFdList]: (state, payload) => {
      return {
        ...state,
        REQStrConFdList: false,
        ConFdList: payload.body,
        totalConFd: payload.total
      };
    },
    [FailConFdList]: (state) => {
      return {
        ...state,
        REQStrConFdList: false,
        ConFdList: [],
        totalConFd: 0
      };
    },
    // -------Synce EN------
    [REQSyncEN]: (state) => {
      return {
        ...state,
        REQStrConFdList: true
      };
    },
    [RESSyncEN]: (state, payload) => {
      
      return {
        ...state,
        REQStrConFdList: false,
        SyncListEN: payload,
        totalSyncEN: payload.total
      };
    },
    [FailSyncEN]: (state) => {
      return {
        ...state,
        REQStrConFdList: false,
        SyncListEN: [],
        totalSynceEN: 0
      };
    },

    // -------Synce TH------
    [REQSyncTH]: (state) => {
      return {
        ...state,
        REQStrConFdList: true
      };
    },
    [RESSyncTH]: (state, payload) => {
      
      return {
        ...state,
        REQStrConFdList: false,
        SyncListTH: payload,
        totalSyncTH: payload.total
      };
    },
    [FailSyncTH]: (state) => {
      return {
        ...state,
        REQStrConFdList: false,
        SyncListTH: [],
        totalSyncTH: 0
      };
    },

    // -------Synce PDF EN------
    [REQSyncPDFEN]: (state) => {
      return {
        ...state,
        REQStrConFdList: true
      };
    },
    [RESSyncPDFEN]: (state, payload) => {
      
      return {
        ...state,
        REQStrConFdList: false,
        SyncListPDFEN: payload,
        totalSyncPDFEN: payload.total
      };
    },
    [FailSyncPDFEN]: (state) => {
      return {
        ...state,
        REQStrConFdList: false,
        SyncListPDFEN: [],
        totalSyncPDFEN: 0
      };
    },

    // -------Synce PDF TH------
    [REQSyncPDFTH]: (state) => {
      return {
        ...state,
        REQStrConFdList: true
      };
    },
    [RESSyncPDFTH]: (state, payload) => {
      
      return {
        ...state,
        REQStrConFdList: false,
        SyncListPDFTH: payload,
        totalSyncPDFTH: payload.total
      };
    },
    [FailSyncPDFTH]: (state) => {
      return {
        ...state,
        REQStrConFdList: false,
        SyncListPDFTH: [],
        totalSyncPDFTH: 0
      };
    },
    [REQFundCodeList]: (state) => {
      return {
        ...state,
        REQStrFundCodeList: true
      };
    },
    [RESFundCodeList]: (state, payload) => {
      
      return {
        ...state,
        REQStrFundCodeList: false,
        FundCodeList: payload.data,
        totalFundCode: payload.total
      };
    },
    [FailFundCodeList]: (state) => {
      return {
        ...state,
        REQStrFundCodeList: false,
        FundCodeList: [],
        totalFundCode: 0
      };
    },

    [CleanUpConFdList]: () => {
      return initialState;
    }
  },
  initialState
);

export default contentFeedListReducer;
