import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQEasyInv } from "./redux/actions";
import Table from "./table";
import { aColEayInv } from "./list_of_columns";
import Images from "../../utils/styles/images";
import "./style.scss";
import Pagination from "./Pagination";
function AnalyticDetail(props) {
  const {
    REQEasyInv,
    REQStrEasyInvList,
    EasyInvList,
    UISearch,
    totalEasyInvList,
    history
  } = props;

  const tColumeEayInv = React.useMemo(() => aColEayInv, []);
  const [tSrch, SETxSrch] = useState("");
  const [tOpSrch, SETxOpSrch] = React.useState("Email");
  const [nPageIndex, SETxPageIndex] = useState(0);
  const [tSortH, SETtSortH] = useState("created_at DESC");

  const [isPageReload, setIsPageReload] = useState(false);
  const [isUnloading, setIsUnloading] = useState(false);

  useEffect(() => {
    const savePageState = () => {
      const dataFromList = JSON.parse(localStorage.getItem('currentAnalyticDetail'))
      console.log("dataFromList", dataFromList)
      localStorage.setItem('currentAnalyticDetail', JSON.stringify(dataFromList));
     
    };

    // Save initial state
    savePageState();
    
    const handleBeforeUnload = () => {
      savePageState();
    };
  
    const handlePageShow = (event) => {
      if (!event.persisted) {
        const storedData = JSON.parse(localStorage.getItem('currentAnalyticDetail'));
        if (storedData) {
          console.log("stored", storedData)
        }
      }
    };

    // Check for page reload using Performance API
    const navigationType = window.performance.getEntriesByType("navigation")[0]?.type;
    if (navigationType === 'reload') {
      setIsPageReload(true);
      const storedData = JSON.parse(localStorage.getItem('currentAnalyticDetail'));
      if (storedData) {
        console.log("storedData", storedData)
        history.push(`/AnalyticDetail`);
      }
    }

    // Handle navigation events
    const unlisten = history.listen((location, action) => {
      if (action === 'POP' && !isPageReload && !isUnloading) {
        history.replace({
          pathname:
            "Analytic_EsyInv",
          PageCurrent: PageCurrent,
          UISearch: UISearch
        });
      }
    });

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('pageshow', handlePageShow);
      unlisten();

      if (!isPageReload && !isUnloading) {
        localStorage.removeItem('currentAnalyticDetail');
      }
    };
  }, []);

  const CALLxAnalyticDetail = () => {
    const dataFromList = JSON.parse(localStorage.getItem('currentAnalyticDetail'))
    if (history.location.PageCurrent === undefined) {
      SETxPageIndex(0);
      REQEasyInv({
        page: "1",
        tEasyInvType: dataFromList.EasyInvestType,
        tStartD: dataFromList.UISearch.tStartD,
        tEndD: dataFromList.UISearch.tEndD,
        bAllD: dataFromList.UISearch.bAllD,
        tSrch,
        tOpSrch,
        tSortH
      });
    } else {
      let nPage = history.location.PageCurrent + 1;

      if (history.location.pathname === "/Analytic") {
        // SET value UI
        SETxOpSrch(history.location.UISearch.tOpSrch);
        SETxSrch(history.location.UISearch.tSrch);

        SETxPageIndex(history.location.PageCurrent);
        REQEasyInv({
          page: nPage.toString(),
          tEasyInvType: dataFromList.EasyInvestType,
          tStartD: dataFromList.UISearch.tStartD,
          tEndD: dataFromList.UISearch.tEndD,
          bAllD: dataFromList.UISearch.bAllD,
          tSrch: history.location.UISearch.tSrch,
          tOpSrch: history.location.UISearch.tOpSrch,
          tSortH: history.location.UISearch.tSortH
        });
      }
    }
  };

  const SRCHxEasyInvAct = () => {
    SETxPageIndex(0);
    REQEasyInv({
      page: "1",
      tEasyInvType: history.location.EasyInvestType,
      tStartD: history.location.UISearch.tStartD,
      tEndD: history.location.UISearch.tEndD,
      bAllD: history.location.UISearch.bAllD,
      tSrch,
      tOpSrch,
      tSortH
    });
  };

  const SRCHxEasyInvActClr = () => {
    SETxSrch("");
    SETxPageIndex(0);
    REQEasyInv({
      page: "1",
      tEasyInvType: history.location.EasyInvestType,
      tStartD: history.location.UISearch.tStartD,
      tEndD: history.location.UISearch.tEndD,
      bAllD: history.location.UISearch.bAllD,
      tSrch: "",
      tOpSrch,
      tSortH
    });
  };

  const RadioInput = ({ label, value, checked, setter }) => {
    return (
      <span>
        <input
          type="radio"
          checked={checked === value}
          onChange={() =>
            {
              setter(value);
            }
          }
        />
        <span style={{ marginLeft: 0 }}>{label}</span>
      </span>
    );
  };

  // start test pass
  const toggleSortDirection = (currentSort, ascendingValue, descendingValue) => {
    if (currentSort === ascendingValue) {
      return descendingValue;
    } else if (currentSort === descendingValue) {
      return ascendingValue;
    } else {
      return descendingValue;
    }
  };
  
  const SETxActHeaderClick = (cell) => {
    let newSortDirection = tSortH;
  
    switch (cell) {
      case "REGISTER DATE":
        newSortDirection = toggleSortDirection(tSortH, "created_at ASC", "created_at DESC");
        break;
      case "USERNAME":
        newSortDirection = toggleSortDirection(tSortH, "username ASC", "username DESC");
        break;
      case "E-MAIL":
        newSortDirection = toggleSortDirection(tSortH, "email ASC", "email DESC");
        break;
      case "ACCOUNT TYPE":
        newSortDirection = toggleSortDirection(tSortH, "hasConverted ASC", "hasConverted DESC");
        break;
      case "UNITHOLDER":
        newSortDirection = toggleSortDirection(tSortH, "UnitCount ASC", "UnitCount DESC");
        break;
      default:
        break;
    }
  
    SETtSortH(newSortDirection);
  };
  // end test

  const SETxActTableClick = (cellValue, rowData, columnName) => {
    if (columnName === "USERNAME") {
      console.log('SETxActTableClick > columnName === "USERNAME"');
    }
  };

  const SETxPageGo = pnPageAt => {
    let nPageAt = pnPageAt + 1;
    SETxPageIndex(pnPageAt);
    REQEasyInv({
      page: nPageAt.toString(),
      tEasyInvType: history.location.EasyInvestType,
      tStartD: history.location.UISearch.tStartD,
      tEndD: history.location.UISearch.tEndD,
      bAllD: history.location.UISearch.bAllD,
      tSrch,
      tOpSrch,
      tSortH
    });
  };

  const CALxPaging = () => {
    let nPageCnt = Math.ceil(totalEasyInvList / 10);
    return nPageCnt > 1 ? (
      <Pagination
        nPageIndex={nPageIndex}
        total={nPageCnt}
        gotoPage={SETxPageGo}
      />
    ) : null;
  };

  const GETxWidthSrch = () => {
    if (tOpSrch === "UUID") {
      return 450;
    } else {
      return 331;
    }
  };

  useEffect(() => {
    CALLxAnalyticDetail();
    
  }, [tSortH]);

  return (
    <Fragment>
      <div className="button-create-container" style={{ marginBottom: 10 }}>
        <div style={{ height: 40 }} />
        {REQStrEasyInvList ? (
          <div className="regisDevice-list-progress">
            <CircularProgress />
            <p className="regisDevice-list-progress-text">
              {REQStrEasyInvList ? "Retrieving EasyInvest list..." : null}
            </p>
          </div>
        ) : null}
      </div>

      <div className="labelInput" style={{ marginLeft: 20, marginTop: 50 }}>
        <span className="labelInputText" style={{ width: 100 }}>
          Search By
        </span>
        <span style={{ paddingRight: 10 }}>:</span>
        <div
          className="usrAct-search"
          style={{ marginTop: 0, width: GETxWidthSrch() }}
        >
          <img
            src={Images.search.search}
            alt="search-icon"
            className="transactions-list-begin-search"
            onClick={() => SRCHxEasyInvAct()}
          />
          <input
            placeholder={"Search by " + tOpSrch}
            className="usrAct-search-bar"
            value={tSrch}
            onChange={event => {
              SETxSrch(event.target.value);
            }}
            onKeyDown={event => {
              
              if (event.keyCode === 13) {
                SETxSrch(event.target.value);
                SRCHxEasyInvAct();
              }
            }}
          />
          {tSrch && tSrch.length > 0 ? (
            <img
              src={Images.closeButton.close}
              alt="cancel-search"
              className="transactions-details-cancel-search"
              onClick={() => SRCHxEasyInvActClr()}
            />
          ) : null}
        </div>
      </div>
      <div
        className="clients-radio-container"
        style={{ marginLeft: 145, marginBottom: 20 }}
      >
        <RadioInput
          label="E-mail"
          value="Email"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Username"
          value="Username"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="English Name"
          value="English Name"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Thai Name"
          value="Thai Name"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="ID No. / Passport No."
          value="ID No. / Passport No."
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Mobile"
          value="Mobile"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
      </div>
      <button
        style={{ marginLeft: 20, marginBottom: 20 }}
        onClick={() => {
          SRCHxEasyInvAct();
        }}
        className="conFd-synce-button"
      >
        Search
      </button>
      {}

      {REQStrEasyInvList ? null : (
        <Table
          columns={tColumeEayInv}
          data={EasyInvList === null ? [] : EasyInvList}
          handleActionHeaderClick={SETxActHeaderClick}
          handleActionClick={SETxActTableClick}
          pageSelect={nPageIndex}
        />
      )}
      {REQStrEasyInvList ? null : <CALxPaging />}
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.analyticDetailReducer
  }),
  {
    REQEasyInv
  }
);

export default compose(
  withConnect,
  withRouter
)(AnalyticDetail);
