export const list_of_columns = [
  {
    Header: "NO.",
    accessor: "no",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:10,
    width: 60
  },
  {
    Header: "CREATED AT",
    accessor: "created_at",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:0
  }, 
  {
    Header: "USERNAME",
    accessor: "username",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 160,
    paddingLeft:10
  },
  {
    Header: "E-MAIL",
    accessor: "email",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 180,
    paddingLeft:0
  }
   ,{
    Header: "ACCOUNT STAGE",
    accessor: "AccountStage",
    disableSortBy: true,
    display:"none",
    textAlign:"left",
    width: 120,
    paddingLeft:10
  }  
 ,{
    Header: "ACCOUNT TYPE",
    accessor: "accounttype",
    disableSortBy: false,
    display:"",
    textAlign:"left",
    width: 180,
    paddingLeft:30

  } 
  ,{
    Header: "UNITHOLDER",
    accessor: "UnitCount",
    display:"",
    disableSortBy: true,
    textAlign:"left",
    width: 130,
    paddingLeft:10
  },
  {
    Header: "CUSTOMER STATUS",
    accessor: (row) => row.CUS_UnitHolder?.[0]?.customer_status || "N/A", 
    display: "CUSTOMER STATUS",
    disableSortBy: true,
    textAlign: "left",
    width: 130,
    paddingLeft: 10,
}
  ,{
    Header: "NAME (EN)",
    accessor: "first_name",
    display:"",
    disableSortBy: true,
    textAlign:"left",
    width: 120,
    paddingLeft:10
  }
  ,{
    Header: "NAME (TH)",
    accessor: "first_name_th",
    display:"",
    disableSortBy: true,
    textAlign:"left",
    width: 120,
    paddingLeft:10
  }
  ,{
    Header: "ID / PASSPORT NO.",
    accessor: "identification_no",
    display:"",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:10
  }
  ,{
    Header: "MOBILE NO.",
    accessor: "mobile",
    display:"",
    disableSortBy: true,
    textAlign:"left",
    width: 150,
    paddingLeft:10
  }
  


];
