import { createAction } from "redux-act";

export const SetFullImgUrlAuto = createAction("SetFullImgUrl_AUTO");

export const REQUntHldListAuto = createAction("REQUEST_UNTHLD_LIST_AUTO");
export const RESUntHldListAuto = createAction("RECEIVE_UNTHLD_LIST_AUTO");
export const FailUntHldListAuto = createAction("FAILED_UNTHLD_LIST_AUTO");
export const CleanUpUntHldListAuto = createAction("CLEANUP_UNTHLD_LIST_AUTO");

// ---DisApprove--
export const requestDisapproveClientAuto = createAction("REQUEST_DISAPPROVE_CLIENT1_AUTO");
export const receiveDisapproveClientAuto = createAction( "RECEIVE_DISAPPROVE_CLIENT1_AUTO");
export const failDisapproveClientAuto = createAction("FAIL_DISAPPROVE_CLIENT1_AUTO");