import React from "react";
import moment from "moment";
import Images from "../../utils/styles/images";

const statusMapping = {
  Draft: "1",
  Published: "2"
};

// start test
function formatNumberCell(cellValue, rowNumber, pageSize, pageIndex) {
  return rowNumber && pageSize ? rowNumber + pageSize * (pageIndex - 1) : cellValue; 
}

function formatRequestDateCell(cellValue) {
  if (cellValue) {
    return moment(cellValue).format("DD MMM YYYY");
  } else {
    return cellValue;
  }
}

function formatStatusCell(cellValue) {
  if (cellValue === statusMapping.Published) {
    return (
      <div className="feed-list-cell-account-status">
        <img src={Images.statusIcon.approved} alt="published-icon" />
        <p className="feed-list-cell-account-status-published">Publish</p>
      </div>
    );
  } else if (cellValue === statusMapping.Draft) {
    return (
      <div className="feed-list-cell-account-status">
        <img src={Images.statusIcon.pending} alt="unpublished-icon" />
        <p className="feed-list-cell-account-status-unpublished">Unpublish</p>
      </div>
    );
  } else {
    return cellValue; 
  }
}
export function modifyCell(cell, pageIndex, pageSize) {
  const { props } = cell;
  const columnName = props?.cell?.column?.Header || null;
  const cellValue = props?.cell?.value || null;
  const rowNumber = props?.row?.index + 1;

  const formatters = {
    "NO.": formatNumberCell,
    "REQUEST DATE": formatRequestDateCell,
    "STATUS": formatStatusCell
  };

  return formatters[columnName] ? formatters[columnName](cellValue, rowNumber, pageSize, pageIndex) : cell;
}
// end test

// This is just for the header cells
export function modifyCellHeader(cell, handleActionHeaderClick, handleSortHeader) {
  if (cell === "UNITHOLDER ID") {
    return (
      <button
        className="ats-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        UNITHOLDER ID
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "FULL NAME") {
    return (
      <button
        className="ats-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        FULL NAME
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "BANK NAME") {
    return (
      <button
        className="ats-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        BANK NAME
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "ACCOUNT NO.") {
    return (
      <button
        className="ats-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        ACCOUNT NO.
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "ACCOUNT NAME") {
    return (
      <button
        className="ats-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        ACCOUNT NAME
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "REQUEST DATE") {
    return (
      <button
        className="ats-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        REQUEST DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "STATUS") {
    return (
      <button
        className="ats-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        STATUS
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else {
    return cell;
  }
}
