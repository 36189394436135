import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import {
  requestFundsList,
  cleanupFundsList,
  REQSyncNAV,
} from "./redux/actions";
import { WarningModal } from "../../components/Modal";
import { setModeFundDetails } from "../FundDetail/redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import { selectedObjectUuid } from "../../components/BreadCrumbs/redux/actions";
import UploadDocument from "./upload_document";
import Images from "../../utils/styles/images";
import "./style.scss";
import Pagination from "./Pagination";



function FundsList(props) {
  const {
    requestFundsList,
    requestingFundsList,
    fundsList,
    totalFunds,
    cleanupFundsList,
    history,
    setModeFundDetails,
    selectedObjectUuid,
    // -----Sync NAV---
    REQSyncStrNAV,
    REQSyncNAV,
    syncTime,
  } = props;
  const columns = React.useMemo(() => list_of_columns, []);
  const [showUploadDocs, setShowUploadDocs] = useState(false);
  const [oShwSyncAlr, SETxShwSyncAlr] = useState(false);
  const [dataSort, setData] = useState(fundsList);
  const [searchValue, setSearchValue] = useState("");
  const [sortOrder, setSortOrder] = useState({ key: "fund_no", direction: "ASC" });
  const [pageIndex, setPageIndex] = useState(0);

  const sortMappings = {
    "FUND NO.": { 
      ascending: "fund_no ASC", 
      descending: "fund_no DESC"
    },
    "FUND CODE": { 
        ascending: "fund_code ASC",
        descending: "fund_code DESC" 
    },
    "RISK LEVEL": { 
        ascending: "risk_level ASC",
        descending: "risk_level DESC" 
    },
    "FUND STATUS": { 
        ascending: "active ASC", 
        descending: "active DESC"
    },
    "ASSET CLASS": {
        ascending: "fund_category ASC",
        descending: "fund_category DESC"
    }
  };
  
  // console.log('fundsList', fundsList)
  const getFunds = () => {
    if (history.location.PageCurrent === undefined) {
      console.log("undefined pagecurrent")
      setPageIndex(0);
      requestFundsList({
        page: "1",
        searchValue,
      });
    } else {
      console.log("else pagecurrent")
      let nPage = history.location.PageCurrent + 1;
      setSearchValue(history.location.UISearch);
      setPageIndex(history.location.PageCurrent);
      requestFundsList({
        page: nPage.toString(),
        searchValue: searchValue,
      });
    }
  };
  const CALxPaging = () => {
    let nPageCnt = Math.ceil(totalFunds / 10);
    return nPageCnt > 1 ? (
      <Pagination
        nPageIndex={pageIndex}
        total={nPageCnt}
        gotoPage={setPageNavigate}
      />
    ) : null;
  };

  const setPageNavigate = (pnPageAt) => {
    let nPageAt = pnPageAt + 1;
    setPageIndex(pnPageAt);
    requestFundsList({
      page: nPageAt.toString(),
      searchValue,
    });
  };

  const searchFunds = () => {
    setPageIndex(0);
    requestFundsList({
      page: "1",
      searchValue,
    });
  };
  
  const searchFundsCancel = () => {
    setSearchValue("");
    setPageIndex(0);
    requestFundsList({
      page: "1",
      searchValue: "",
    });
  };

  useEffect(() => {
    setData(fundsList);
  }, [fundsList]);
  

  useEffect(() => {
    getFunds();
    handleHeaderSort("FUND NO.")
    return () => {
      cleanupFundsList();
    };

  }, [syncTime]);

  const handleActionClick = ({ mode, rowData, pageIndex }) => {
    if (mode === "view") {
      setModeFundDetails({
        mode: "VIEW",
        detailsFromList: rowData,
        PageCurrent: pageIndex
      });
      selectedObjectUuid(rowData.fund_code);
      localStorage.setItem('currentFundList', JSON.stringify({
        mode: "VIEW",
        detailsFromList: rowData,
        PageCurrent: pageIndex,
        rowData: rowData,
        from: "fundList"
      }))
      history.push("/fund_listDetail/" + rowData.fund_no);
    }

    if (mode === "edit") {
      setModeFundDetails({
        mode: "EDIT",
        detailsFromList: rowData,
        PageCurrent: pageIndex
      });
      selectedObjectUuid(rowData.fund_code);
      localStorage.setItem('currentFundList', JSON.stringify({
        mode: "EDIT",
        detailsFromList: rowData,
        PageCurrent: pageIndex,
        rowData: rowData,
        from: "fundList"
      }))
      history.push("/fund_listDetail/" + rowData.fund_no);
    }
  };
  const GETxSynceNAV = () => {
    SETxShwSyncAlr(true);
  };

  
  const handleHeaderSort = (header) => {
    if (sortMappings[header]) {
      console.log("SortMapping",sortMappings[header].ascending)
      console.log("sortorderTop",sortOrder)
      const newSortOrder = sortOrder === sortMappings[header].ascending 
        ? sortMappings[header].descending 
        : sortMappings[header].ascending;

      console.log("newSortOrder:", newSortOrder)
      setSortOrder(newSortOrder);
      sortData(newSortOrder);
    }
  };

  const sortData = (sortOrder) => {
    const [key, direction] = sortOrder.split(' ');
    console.log("key: ", key)
    console.log("direc: ", direction)
    const sortedData = [...dataSort].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ASC' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ASC' ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  
 
  return (
    <Fragment>
      
      {showUploadDocs ? (
        <UploadDocument setShowUploadDocs={setShowUploadDocs} />
      ) : null}

      {requestingFundsList ? (
        <div className="funds-list-progress">
          <CircularProgress />
          <p className="funds-list-progress-text">
            {requestingFundsList ? "Retrieving Funds list..." : null}
          </p>
        </div>
      ) : null}

      {REQSyncStrNAV === true ? (
        <div className="funds-list-progress">
          <CircularProgress />
          <p className="funds-list-progress-text">
            {REQSyncStrNAV === true ? "Retrieving Sync NAV..." : null}
          </p>
        </div>
      ) : null
      }
      
      <div className="funds-buttons-container">
      
        <button
          className="funds-create-fund-button"
          style={{ marginRight: 14 }}
          onClick={GETxSynceNAV}
        >
          Sync NAV
        </button>
        <button
          className="funds-upload-doc-button"
          onClick={() => setShowUploadDocs(true)}
        >
          <img
            src={Images.fundListIcon.document}
            style={{ marginBottom: 3 }}
            alt="document"
          />
          UPLOAD DOCUMENT
        </button>
        <button
          className="funds-create-fund-button"
          onClick={() => {
            setModeFundDetails({ mode: "CREATE" });
            selectedObjectUuid("CREATE NEW FUND");
            history.push("/fund_listDetail/0");
          }}
        >
          <img
            src={Images.fundListIcon.plus}
            style={{ marginBottom: 3 }}
            alt="plus"
          />
          CREATE NEW FUND
        </button>
        
      </div>
      <div className="funds-search-header" >
        <div className="funds-search-container">
          <div className="funds-search">
            <img
              src={Images.search.search}
              alt="search-icon"
              className="funds-list-begin-search"
              onClick={() =>
                searchFunds()
              }
            />
            <input
              placeholder="Search by Fund Name"
              className="funds-search-bar"
              value={searchValue}
              onChange={event => {
                setSearchValue(event.target.value);
              }}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  searchFunds();
                }
              }}
            />
            {searchValue && searchValue.length > 0 ? (
              <img
                src={Images.closeButton.close}
                alt="cancel-search"
                className="funds-details-cancel-search"
                onClick={() => {
                  searchFundsCancel();
                }}
              />
            ) : null}
          </div>
        </div>
      </div>

      <WarningModal
        show={oShwSyncAlr}
        onHide={() => SETxShwSyncAlr(false)}
        onHandleGo={() => {
          SETxShwSyncAlr(false);
          try {
            REQSyncNAV({ history });
          } catch (ex) {
            console.log('catch onHandleGo', ex);
          }
        }}
        onHandleCancel={() => {
          SETxShwSyncAlr(false);
        }}
        type={"Disapprove"}
        status={"1"}
        title={"Are you sure you want to sync NAV from website?"}
      />

      <Table
        data={dataSort}
        columns={columns}
        handleActionHeaderClick={handleHeaderSort}
        handleActionClick={handleActionClick}
        history={history}
      />
      {requestingFundsList ? null : <CALxPaging />}
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.fundsListReducer
  }),
  {
    requestFundsList,
    cleanupFundsList,
    selectedObjectUuid,
    setModeFundDetails,

    REQSyncNAV,
  }
);
export default compose(
  withConnect,
  withRouter
)(FundsList);
