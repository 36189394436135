import { createReducer } from "redux-act";

import {
  MEMUsrActDetail,
    // ----Tab2 RegisDevice----
  REQTab2_RegisDeviceList,
  RESTab2_RegisDeviceList,
  FailTab2_RegisDeviceList,
    // -----------Change Status Detail-----------
    REQChgSta,
    RESChgSta,
    FailChgSta,
    // ----Get Image----
    REQImgCus,
    RESImgCus,
    FailImgCus,
    // ----Get Stat----
    REQSta,
    RESSta,
    FailSta,
    // ----Delete UserAccount----
    REQDelUsrAct,
    RESDelUsrAct,
    FailDelUsrAct,
    cleanupDelUsrAct,
    // Check Progress
    RequestProgress,
    ResponseProgress,
    FailProgress
} from "./actions";

const initialState = {
  // ----Tab2 RegisDevice----
  REQTab2_StrRegisDeviceList: false,
  Tab2_RegisDeviceList: [],
  totalTab2_RegisDevice: 0,
  // -----------Change Status Detail-----------
  REQStrChgSta:false,
  ChgStaList:[],
  totalChgSta:0,
  // ----Get Image----
  REQStrImgCus:false,
  ImgCusList:[],
  totalImgCus:0,
  // ----Get Sta----
  REQStrSta:false,
  StaList:[],
  totalSta:0,
  // ----Get Sta----
  REQStrDelUsrAct:false,
  DelUsrActList:[],
  totalDelUsrAct:0,
  // ----Check Progress----
  RequestingProgress: false,
  CustomerProgress: [],
};

const usrActDetailReducer = createReducer(
  {
 
    [MEMUsrActDetail]: (state, payload) => {
    
      return {
        ...state,
        RowDataAt:payload.RowDataAt,
        PageCurrent:payload.PageCurrent,
        UISearch:payload.UISearch
      };
    },

    // ----- Tab2 RegisDevice -------
    [REQTab2_RegisDeviceList]: (state) => {
      
       return {
         ...state,
         REQTab2_StrRegisDeviceList: true
       };
     },    
     [RESTab2_RegisDeviceList]: (state, payload) => {
      
       return {
         ...state,
         REQTab2_StrRegisDeviceList: false,
         Tab2_RegisDeviceList: payload,
         totalTab2_RegisDevice: payload.total
       };
     },      
     [FailTab2_RegisDeviceList]: (state) => {
       return {
         ...state,
         REQTab2_StrRegisDeviceList: false,
         Tab2_RegisDeviceList: [],
         totalTab2_RegisDevice: 0
       };
     },

  // ------------------Chage Status-------------------------
  [REQChgSta]: (state) => {
    //
    return {
      ...state,
      REQStrChgSta: true
    };
  }, 
  [RESChgSta]: (state, payload) => {
    return {
      ...state,
      REQStrChgSta: false,
      ChgStaList: payload.status
    };
  },  
  [FailChgSta]: (state) => {
    return {
      ...state,
      REQStrChgSta: false,
      ChgStaList: [],
      totalChgSta: 0
    };
  } , 
  // ------------------Get Image-------------------------
  [REQImgCus]: (state) => {
    return {
      ...state,
      REQStrImgCus: true
    };
  }, 
  [RESImgCus]: (state, payload) => {
    return {
      ...state,
      REQStrImgCus: false,
      ImgCusList: payload,
      totalImgCus: payload.total
    };
  },  
  [FailImgCus]: (state) => {
    return {
      ...state,
      REQStrImgCus: false,
      ImgCusList: [],
      totalImgCus: 0
    };
  } ,
    // ------------------Get Sta-------------------------
    [REQSta]: (state) => {
        return {
          ...state,
          REQStrSta: true
        };
      }, 
      [RESSta]: (state, payload) => {
        return {
          ...state,
          REQStrSta: false,
          StaList: payload
        };
      },  
      [FailSta]: (state) => {
        return {
          ...state,
          REQStrSta: false,
          StaList: [],
          totalSta: 0
        };
      },
    // ------------------Delete UserAccount-------------------------
    [REQDelUsrAct]: (state) => {
        return {
          ...state,
          REQStrDelUsrAct: true
        };
      }, 
      [RESDelUsrAct]: (state, payload) => {
        return {
          ...state,
          REQStrDelUsrAct: false,
          DelUsrActList: payload
        };
      },  
      [FailDelUsrAct]: (state) => {
        return {
          ...state,
          REQStrDelUsrAct: false,
          totalDelUsrAct: 0
        };
      } ,
      [cleanupDelUsrAct]: () => {
        return initialState;
      },
      // ------------------- Check Progress -------------------
      [RequestProgress]: (state) => {
        return {
          ...state,
          RequestingProgress: true,
        };
      },
      [ResponseProgress]: (state, payload) => {
        return {
          ...state,
          RequestingProgress: true,
          CustomerProgress: payload
        };
      },
      [FailProgress]: (state) => {
        return {
          ...state,
          RequestingProgress: false,
        };
      },
  },
  
  initialState
);

export default usrActDetailReducer;
