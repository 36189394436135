import { createAction } from "redux-act";

export const requestContentFeedListAction = createAction("REQUEST_CONTENT_FEED_LIST");
export const receiveContentFeedListAction = createAction("RECEIVE_CONTENT_FEED_LIST");
export const failContentFeedListAction = createAction("FAILED_CONTENT_FEED_LIST");

export const requestInterestsListAction = createAction("REQUEST_INTERESTS_LIST");
export const receiveInterestsListAction = createAction("RECEIVE_INTERESTS_LIST");
export const failInterestsListAction = createAction("FAILED_INTERESTS_LIST");
// ----- GetConFeed -------
export const REQConFdList = createAction("REQUEST_CONFD_LIST");
export const RESConFdList = createAction("RECEIVE_CONFD_LIST");
export const FailConFdList = createAction("FAILED_CONFD_LIST");

// ----- GetSynceEN -------
export const REQSyncEN = createAction("REQUEST_CONFD_SYNC_EN");
export const RESSyncEN = createAction("RECEIVE_CONFD_SYNC_EN");
export const FailSyncEN = createAction("FAILED_CONFD_SYNC_EN");
// ----- GetSynce -------
export const REQSyncTH = createAction("REQUEST_CONFD_SYNC_TH");
export const RESSyncTH = createAction("RECEIVE_CONFD_SYNC_TH");
export const FailSyncTH = createAction("FAILED_CONFD_SYNC_TH");


// ----- GetSynce PDF EN -------
export const REQSyncPDFEN = createAction("REQUEST_CONFD_SYNC_PDF_EN");
export const RESSyncPDFEN = createAction("RECEIVE_CONFD_SYNC_PDF_EN");
export const FailSyncPDFEN = createAction("FAILED_CONFD_SYNC_PDF_EN");
// ----- GetSynce PDF TH -------
export const REQSyncPDFTH = createAction("REQUEST_CONFD_SYNC_PDF_TH");
export const RESSyncPDFTH = createAction("RECEIVE_CONFD_SYNC_PDF_TH");
export const FailSyncPDFTH = createAction("FAILED_CONFD_SYNC_PDF_TH");

export const CleanUpConFdList  = createAction("CLEANUP_CONFD_LIST");

export const REQFundCodeList = createAction("REQUEST_FUNCODE_Detail");
export const RESFundCodeList = createAction("RECEIVE_FUNCODE_Detail");
export const FailFundCodeList = createAction("FAILED_FUNCODE_Detail");


