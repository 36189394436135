import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQSrchRecList } from "./redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import { MEMSrchRecDetailEdt } from "../SrchRecDetail/redux/actions";
import "./style.scss";
function SrchRecList(props) {
  const {
    REQSrchRecList,
    SrchRecList,
    REQStrSrchRecList,
    MEMSrchRecDetailEdt,
    history
  } = props;

  const columns = React.useMemo(() => list_of_columns, []);

  const GETxGETxSrchRec = () => {
    REQSrchRecList();
  };

  useEffect(() => {
    GETxGETxSrchRec();
  }, []);

  function SETxFundEdt() {
    if (history.location.pathname === "/SearchRec") {
      MEMSrchRecDetailEdt({ mode: "OLD_CONTENT", RowDataAt: SrchRecList });
      localStorage.setItem('currentSearchRec', JSON.stringify({ mode: "OLD_CONTENT", RowDataAt: SrchRecList }))
      history.push(`/SrchRecDetail`);
    }
  }

  return (
    <Fragment>
      <div className="button-create-container">
        <button
          type="button"
          className="srchRec-create-fund-button"
          onClick={SETxFundEdt}
        >
          EDIT
        </button>
      </div>
      {REQStrSrchRecList ? (
        <div className="funds-list-progress">
          <CircularProgress />
          <p className="funds-list-progress-text">
            {REQStrSrchRecList ? "Retrieving SearchRecommend list..." : null}
          </p>
        </div>
      ) : null}

      {
        <Table
          data={SrchRecList}
          HightTable={900}
          columns={columns}
        />
      }
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.srchRecListReducer
  }),
  {
    REQSrchRecList,
    MEMSrchRecDetailEdt
  }
);

export default compose(
  withConnect,
  withRouter
)(SrchRecList);
