import { call, all, takeLatest, put } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  requestForgotPasswordAction,
  receiveForgotPasswordAction,
  failForgotPasswordAction
} from "./actions";
import * as actions from "../../Authentication/redux/actions";

function* resetPasswordWorker({ payload }) {
  // Sends out a request to reset password for this user, which results in an email that gets sent to user
  // and from that email it will send them to the resetpassword screen.
  try {
    const { emailInput } = payload;
    const body = {
      email: emailInput
    };
    //Not login > no token 
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/AdminUsers/reset`,
      {
        method: "POST", 
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body) 
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    const response = yield request.json();
    if (response && !response.error) {
      yield put(receiveForgotPasswordAction());
    } else {
      if(response.error[0].reason === 'dataError.unKnownValue.400'){
        //user not found but need to force success because of security
        yield put(receiveForgotPasswordAction());
      }else{
        yield put(failForgotPasswordAction(response.error[0].message));
      }
    }
  } catch (error) {
    console.error("failForgotPasswordAction error:",error);
    yield put(failForgotPasswordAction("Failed to request password reset"));
  }
}

export function* forgotPasswordSagas() {
  yield all([takeLatest(requestForgotPasswordAction, resetPasswordWorker)]);
}
