import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  requestUntHldDetailDocuments,
  FETCH_UPLOAD_FILE_TO_S3_REQUEST,
  FETCH_SAVE_TO_DB_REQUEST,
  savingtodb,
  uploadingToS3,
} from "./redux/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { select } from "redux-saga/effects";


const ManualUpload = (props) => {
  const {
    FETCH_UPLOAD_FILE_TO_S3_REQUEST,
    documentDetails,
    s3posturl,
    s3formdata,
    FETCH_SAVE_TO_DB_REQUEST,
    savingtodb,
    avatar,
    requestingClientDocuments,
    onUploadComplete,
    uploadingToS3,
  } = props;
  const [showForm, setShowForm] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);

  const handleButtonClick = () => {
    setShowForm(true);
  };

  const handleCancel = () => {
    setShowForm(false);
    setSelectedFile(null);
    setSelectedDocumentType(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "image/jpeg") {
      setSelectedFile(file);
    } else {
      alert("Please select a .jpg file");
    }
  };

  const documentNameMapping = {
    1: "Thai ID Front",
    2: "Thai ID Back",
    4: "Bankbook",
    7: "Passport",
    8: "Profile Picture",
    9: "Selfie With ID (NDID / Counter Service)",
    10: "ID card 7-11",
  };

  const documentTypeMapping = {
    1: "front",
    2: "back",
    4: "bankbook",
    7: "passportFront",
    8: "profile",
    9: "selfie",
    10: "imageOnIdCard",
  };

    useEffect(() => {
    requestUntHldDetailDocuments(avatar);
  }, []);
  const documentOptions = Object.entries(documentNameMapping).map(
    ([key, value]) => ({
      value: Number(key),
      label: value,
    })
  );

  const handleChange = (selectedOption) => {
    setSelectedDocumentType(selectedOption);
  };

  const uploadFile = async (position, file) => {
    try {
      FETCH_UPLOAD_FILE_TO_S3_REQUEST(position);
      const formData = new FormData();
      await s3formdata;
      s3formdata.forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append("file", file, `identification-${position}.jpg`);

      const response = await fetch(s3posturl, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Upload failed");
      }

      console.log("File uploaded successfully");
      setShowForm(false);
      FETCH_SAVE_TO_DB_REQUEST(position);

      setTimeout(() => {
        requestUntHldDetailDocuments(avatar);
        console.log("requested new documents")
      }, 5000);
      if (onUploadComplete) {
        onUploadComplete();

      }
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  const handleUpload = () => {
    if (selectedFile && selectedDocumentType) {
        const positionString = documentTypeMapping[selectedDocumentType.value];
    
        const selectedDocument = documentDetails.find(doc => doc.typeCode === String(selectedDocumentType.value));
        if (selectedDocument) {
          console.log("selectedDoc", selectedDocument);
          if (selectedDocument.statusCode === "2" || selectedDocument.statusCode === "4") {
            alert("Can't update approved document.");
          } else {
            if (positionString) {
              uploadFile(positionString, selectedFile);
            } else {
              alert("Invalid document type.");
            }
          }
        } else {
          alert("Document type not found.");
        }
      } else {
        alert("Please select both a file and a document type.");
      }
  };

  return (
    <>
      <button
        className={"client-detail-profile-document-refresh"}
        onClick={handleButtonClick}
      >
        Upload File
      </button>
      {showForm && (
        <div className="upload-doc-container">
            
          <div className="upload-doc-modal">
          {uploadingToS3 && (
              <div className="document-circular-loader">
                <CircularProgress className="document-status-progress" />
                <p className="document-status-text-progress">Saving document...</p>
              </div>
            )}
            <div className="upload-doc-header">
              <h1>Upload Document</h1>
            </div>
            

            <div className="upload-doc-input-container-unitholder">
              <label htmlFor="documentType">Select Document Type</label>
              <Select
                options={documentOptions}
                placeholder="Select Document Type"
                onChange={handleChange}
                value={selectedDocumentType}
              />
            </div>
            <div style={{ marginTop: 50 }}></div>
            <div className="upload-doc-input-container-unitholder">
              <label htmlFor="fileInput">Select File</label>
              <div className="upload-doc-input-unitholder">
                <input
                  id="fileInput"
                  type="file"
                  accept=".jpg, .jpeg"
                  className="upload-doc-input-hidden"
                  onChange={handleFileChange}
                />
                <label className="upload-doc-label-inner" htmlFor="fileInput">
                  <p>{selectedFile ? selectedFile.name : "No file selected"}</p>
                  <div className="upload-doc-upload-icon-unitholder">
                    Browse
                  </div>
                </label>
              </div>
            </div>
            <div style={{ marginTop: 60 }}></div>
            <div className="upload-doc-buttons">
              <button className="upload-doc-cancel" onClick={handleCancel}>
                Cancel
              </button>
              <button
                className="upload-doc-submit"
                disabled={!selectedFile || !selectedDocumentType}
                onClick={handleUpload}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const withConnect = connect(
    state => ({
      ...state.untHldDetailReducer
    }),
    {
      requestUntHldDetailDocuments,
      FETCH_UPLOAD_FILE_TO_S3_REQUEST,
      FETCH_SAVE_TO_DB_REQUEST,
    }
  );

  export default compose(
    withConnect,
    withRouter
  )(ManualUpload);