import { createReducer } from "redux-act";
import {
  userSignedIn,
  RESOkta,
  userSignedOut,
  resetFailedRequestProps,
  signInRequestFailedAction,
  // updateLastActivityTime // Import the new action
} from "./actions";

const initialState = {
  accessToken: null,
  oktaAccessToken: null,
  b2cOktaAccessToken: null,
  ttl: null,
  tokenCreatedAt: null,
  userId: null,
  b2cUserId: null,
  refreshToken: null,
  errorMessage: false,
  isLoading: false,
  GUID: null,
  OKTAStatus: null,
  // lastActivityTime: Date.now(), // Add initial value for lastActivityTime
};

const authReducer = createReducer(
  {
    [userSignedIn]: (state, payload) => {
      return {
        ...state,
        accessToken: payload.id || state.idToken,
        ttl: payload.ttl,
        tokenCreatedAt: payload.created,
        userId: payload.userId,
        oktaAccessToken: payload.oktaAccessToken,
        GUID: payload.GUID
      };
    },
    [RESOkta]: (state, payload) => {
      return {
        ...state,
        OKTAStatus: payload
      };
    },
    [resetFailedRequestProps]: (state) => {
      return {
        ...state,
        errorMessage: false,
        isLoading: false
      };
    },
    [signInRequestFailedAction]: (state) => {
      return {
        ...state,
        errorMessage: true,
        isLoading: false
      };
    },
    [userSignedOut]: () => ({
      ...initialState
    }),
    // [updateLastActivityTime]: (state, payload) => {
    //   return {
    //     ...state,
    //     lastActivityTime: Date.now() // Update lastActivityTime
    //   };
    // }
  },
  initialState
);

export default authReducer;
