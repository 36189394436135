import { OktaAuth } from "@okta/okta-auth-js";
import React, { Component } from "react";
import "./style.scss";
import { aConfigUrl } from "../../utils/ConfigUrl";
import { connect } from 'react-redux';
import { userSignedIn } from "../Authentication/redux/actions";
import { requestDropDownData, stopLoading } from "../../globalRedux/actions";
import * as api from "../../utils/api";
class CallbackScreen extends Component {

  componentDidMount() {
    this._initScreen();
  }

  async setAccessTokenToHeader() {
    const token = localStorage.getItem("NeedAuth");
    if (token) {
      // Assuming `api.setAuthHeader` is a helper to set headers for API calls
      await api.setAuthHeader(JSON.parse(token).Token);
      return token;
    }
    return null;
  }

  async _initScreen() {
    let authClient = new OktaAuth(aConfigUrl());
    
    try {
      const res = await authClient.token.getWithoutPrompt({
        responseType: ["id_token", "token"]
      });
      
      const tokens = res.tokens;
      authClient.tokenManager.setTokens(tokens);

      const oktaAccessToken = tokens.accessToken.accessToken;
      const userClaims = await authClient.token.getUserInfo(tokens.accessToken, tokens.idToken);

      const oNeedAuth = {
        Email: userClaims.email,
        Token: oktaAccessToken,
        GUID: userClaims.customerId, // Ensure this claim exists
        OKTAExpireUTC: new Date(Number(tokens.accessToken.expiresAt) * 1000),
        OKTAToken: oktaAccessToken,
        b2cAccessToken: oktaAccessToken,
        b2cUserId: userClaims.customerId // Ensure this claim exists
      };

      console.log("oNeedAuth:", oNeedAuth);
      localStorage.setItem("NeedAuth", JSON.stringify(oNeedAuth));

      // Dispatch the action to handle user sign-in
      this.props.userSignedIn({
        id: oktaAccessToken,
        ttl: tokens.accessToken.expiresAt,
        created: tokens.accessToken.expiresAt,
        userId: userClaims.sub, // Ensure this claim exists
        accessToken: oktaAccessToken,
        GUID: userClaims.customerId // Ensure this claim exists
      });

      // Set the access token in the header
      await this.setAccessTokenToHeader();
      
      // Request dropdown data and stop the loading state
      this.props.requestDropDownData();
      this.props.stopLoading();
      
    } catch (err) {
      console.log('Error during token retrieval', err);
    }
  }

  render() {
    return (
      <div className="wrapper">
        <div className="imageBackgroundWrapDefaultPage" />
      </div>
    );
  }
}

// Map the userSignedIn, requestDropDownData, and stopLoading actions to component props
const mapDispatchToProps = {
  userSignedIn,
  requestDropDownData,
  stopLoading
};

// Connect the component to Redux
export default connect(null, mapDispatchToProps)(CallbackScreen);
