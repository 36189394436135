import { createAction } from "redux-act";
import {
  REQUEST_COUNTRIES,
  RECEIVE_COUNTRIES,
  FAILED_COUNTRIES,
  REQUEST_GENDERS,
  RECEIVE_GENDERS,
  FAILED_GENDERS,
  REQUEST_DOCTYPES,
  RECEIVE_DOCTYPES,
  FAILED_DOCTYPES,
  REQUEST_MARITAL,
  RECEIVE_MARITAL,
  FAILED_MARITAL,
  REQUEST_PROVINCES,
  RECEIVE_PROVINCES,
  FAILED_PROVINCES,
  REQUEST_POSTCODE,
  RECEIVE_POSTCODE,
  FAILED_POSTCODE,
  REQUEST_OCCUPATIONS,
  RECEIVE_OCCUPATIONS,
  FAILED_OCCUPATIONS,
  REQUEST_MONTHLY_INCOMES,
  RECEIVE_MONTHLY_INCOMES,
  FAILED_MONTHLY_INCOMES,
  REQUEST_INCOME_SOURCES,
  RECEIVE_INCOME_SOURCES,
  FAILED_INCOME_SOURCES,
  REQUEST_BANKS,
  RECEIVE_BANKS,
  FAILED_BANKS,
  RECEIVE_NATIONALITIES,
  RECEIVE_DATA_FOR_CONTACT_FORM,
  REQUEST_DATA_FOR_CONTACT_FORM,
  REQUEST_DROPDOWN_DATA,
  REQUEST_DISTRICTS,
  SAVE_PAGE_NUMBER,
  RECEIVE_DISTRICTS,
  REQUEST_BUSINESSTYPES,
  RECEIVE_BUSINESSTYPES,
  UPDATE_LAST_ACTIVITY_TIME
} from "../constants";

export const startLoading = createAction("START_LOADING");
export const stopLoading = createAction("STOP_LOADING");

export const fundCategoriesReceived = createAction('FUND_CATEGORIES_RECIEVED');

export const requestDistricts = createAction(REQUEST_DISTRICTS);
export const receiveDistricts = createAction(RECEIVE_DISTRICTS);
export const updateActivityTime = createAction(UPDATE_LAST_ACTIVITY_TIME);


export function requestDropDownData() {
  return {
    type: REQUEST_DROPDOWN_DATA
  };
}
export function requestCountries() {
  return {
    type: REQUEST_COUNTRIES,
    retrievingCountries: true
  };
}

export function receiveCountries(data) {
  return {
    type: RECEIVE_COUNTRIES,
    retrievingCountries: false,
    countries: data
  };
}

export function failedCountries() {
  return {
    type: FAILED_COUNTRIES,
    retrievingCountries: false
  };
}

export function requestGenders() {
  return {
    type: REQUEST_GENDERS,
    retrievingGenders: true
  };
}

export function receiveGenders(data) {
  return {
    type: RECEIVE_GENDERS,
    retrievingGenders: false,
    genders: data
  };
}

export function requestBusinessTypes() {
  return {
    type: REQUEST_BUSINESSTYPES,
    retrievingBusinessTypes: true
  };  
}

export function receiveBusinessTypes(data) {
  return {
    type: RECEIVE_BUSINESSTYPES,
    retrievingBusinessTypes: false,
    businessTypes: data
  };
}

export function failedGenders() {
  return {
    type: FAILED_GENDERS,
    retrievingGenders: false
  };
}

// Request array of document types (e.g. ID Card, Passport ID)
export function requestDoctypes() {
  return {
    type: REQUEST_DOCTYPES,
    retrievingDoctypes: true
  };
}

export function receiveDoctypes(data) {
  return {
    type: RECEIVE_DOCTYPES,
    retrievingDoctypes: false,
    doctypes: data
  };
}

export function failedDoctypes() {
  return {
    type: FAILED_DOCTYPES,
    retrievingDoctypes: false
  };
}

// Request array of marital statuses from Pentacle API

export function requestMaritalStatus() {
  return {
    type: REQUEST_MARITAL,
    retrievingMaritalStatuses: true
  };
}

export function receiveMaritalStatus(data) {
  return {
    type: RECEIVE_MARITAL,
    retrievingMaritalStatuses: false,
    maritalStatuses: data
  };
}

export function failedMaritalStatus() {
  return {
    type: FAILED_MARITAL,
    retrievingMaritalStatuses: false
  };
}

// Request array of provinces

export function requestProvinces() {
  return {
    type: REQUEST_PROVINCES,
    retrievingProvinces: true
  };
}

export function receiveProvinces(data) {
  return {
    type: RECEIVE_PROVINCES,
    retrievingProvinces: false,
    provinces: data
  };
}

export function failedProvinces() {
  return {
    type: FAILED_PROVINCES,
    retrievingProvinces: false
  };
}

// request an array of postcodes

export function requestPostcode(
  givenProvinceCode,
  givenDistrictCode,
  form = ""
) {
  return {
    type: REQUEST_POSTCODE,
    retrievingPostcodes: true,
    provinceCode: givenProvinceCode,
    districtCode: givenDistrictCode,
    form
  };
}

export function receivePostcode(data) {
  return {
    type: RECEIVE_POSTCODE,
    retrievingPostcodes: false,
    postcodes: data
  };
}

export function failedPostcode() {
  return {
    type: FAILED_POSTCODE,
    retrievingPostcodes: false
  };
}

// request an array of occupations

export function requestOccupations() {
  return {
    type: REQUEST_OCCUPATIONS,
    retrievingOccupations: true
  };
}

export function receiveOccupations(data) {
  return {
    type: RECEIVE_OCCUPATIONS,
    retrievingOccupations: false,
    occupations: data
  };
}

export function failedOccupations() {
  return {
    type: FAILED_OCCUPATIONS,
    retrievingOccupations: false
  };
}

// request an array of monthly incomes

export function requestMonthlyIncomes() {
  return {
    type: REQUEST_MONTHLY_INCOMES,
    retrievingMonthlyIncomes: true
  };
}

export function receiveMonthlyIncomes(data) {
  return {
    type: RECEIVE_MONTHLY_INCOMES,
    retrievingMonthlyIncomes: false,
    monthlyIncomes: data
  };
}

export function failedMonthlyIncomes() {
  return {
    type: FAILED_MONTHLY_INCOMES,
    retrievingMonthlyIncomes: false
  };
}

// request an array of source of incomes

export function requestIncomeSources() {
  return {
    type: REQUEST_INCOME_SOURCES,
    retrievingIncomeSources: true
  };
}

export function receiveIncomeSources(data) {
  return {
    type: RECEIVE_INCOME_SOURCES,
    retrievingIncomeSources: false,
    incomeSources: data
  };
}

export function failedIncomeSources() {
  return {
    type: FAILED_INCOME_SOURCES,
    retrievingIncomeSources: false
  };
}

export function receiveBanks(payload) {
  return {
    type: RECEIVE_BANKS,
    payload
  };
}
export function requestBanks(payload) {
  return {
    type: REQUEST_BANKS,
    payload
  };
}

export function failedBanks() {
  return {
    type: FAILED_BANKS,
    retrievingBanks: false
  };
}

export function receiveNationalities(payload) {
  return {
    type: RECEIVE_NATIONALITIES,
    payload
  };
}

export function requestDataForContactForm(payload) {
  return {
    type: REQUEST_DATA_FOR_CONTACT_FORM,
    payload
  };
}

export function receiveDataForContactForm(payload) {
  return {
    type: RECEIVE_DATA_FOR_CONTACT_FORM,
    payload
  };
}

export function savePageNumber(page) {
  return {
    type: SAVE_PAGE_NUMBER,
    page
  };
}

export function updateLastActivityTime(time) {
  return {
    type: UPDATE_LAST_ACTIVITY_TIME,
    payload: time
  };
}
