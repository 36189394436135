import React from "react";
import moment from "moment";
import Images from "../../utils/styles/images";
export function modifyCell(cell, pageIndex, pageSize, handleActionClick) {
  // Format the cell data here
  const { props } = cell;
  let columnName =
    props && props.cell && props.cell.column && props.cell.column.Header
      ? props.cell.column.Header
      : null;
  let cellValue =
    props && props.cell && props.cell.value ? props.cell.value : null;
  let rowData =
    props && props.row && props.row.original ? props.row.original : null;

  if (columnName === "ACTION") {
    if (rowData) {
      return (
        <div className="funds-action-buttons">
          <button
            className="funds-action-view"
            onClick={() =>
              handleActionClick({ mode: "view", rowData: rowData, pageIndex })
            }
          >
            <img src={Images.fundListIcon.view} alt="eye" />
            View
          </button>
          <button
            className="funds-action-edit"
            onClick={() =>
              handleActionClick({ mode: "edit", rowData: rowData, pageIndex })
            }
          >
            <img src={Images.fundListIcon.edit} alt="pencil" />
            Edit
          </button>
        </div>
      );
    } else {
      return cell;
    }
  } else if (columnName === "NAV DATE") {
    if (cellValue) {
      const dateObject = new Date(cellValue);
      dateObject.setHours(dateObject.getHours());
    
      const tDate = `${dateObject.toISOString().slice(0, 19).replace("T", " ")}`;
      return tDate;
    } else {
      return cell;
    }
  } else if (columnName === "CREATE DATE") {
    if (cellValue) {
      return moment(cellValue[0]).format("YYYY-MM-DD");
    } else {
      return cell;
    }
  } else if (columnName === "MODIFIED DATE") {
    if (cellValue) {
      return moment(cellValue[0]).format("YYYY-MM-DD");
    } else {
      return cell;
    }
  } else if (columnName === "FUND STATUS") {
    if (typeof cellValue === "boolean" && cellValue) {
      return (
        <div className="funds-status-active">
          <p>Active</p>
        </div>
      );
    } else if (cellValue === false || cellValue === null) {
      return (
        <div className="funds-status-inactive">
          <p>Inactive</p>
        </div>
      );
    } else {
      return cell;
    }
  } else {
    return cell;
  }
}
export function modifyCellHeader(cell, handleActionHeaderClick, handleSortHeader) {
  if (cell === "FUND NO.") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        FUND NO.
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "FUND CODE") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        FUND CODE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "ASSET CLASS") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        ASSET CLASS
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "RISK LEVEL") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        RISK LEVEL
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "NAV (THB)") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        NAV (THB)
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "MODIFIED DATE") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        MODIFIED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "FUND STATUS") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        FUND STATUS
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else {
    return cell;
  }
}
// ---------------------------FundRecommend Condition ---------------------------
function formatActionCell(cellValue, rowNumber, rowData, handleActionClick) {
  if (rowData) {
    return (
      <div className="funds-action-buttons">
        <button
          className="funds-action-edit"
          onClick={() =>
            handleActionClick({ mode: "edit", rowData: rowData })
          }
        >
          <img src={Images.fundListIcon.edit} alt="pencil" />
          Edit
        </button>
      </div>
    );
  } else {
    return cellValue; 
  }
}

function formatDate(cellValue) {
  if (cellValue) {
    return moment(cellValue).format("YYYY-MM-DD");
  } else {
    return cellValue; 
  }
}

function formatNumberCell(cellValue, rowNumber, rowData, handleActionClick) {
  return rowNumber; 
}

function formatStatusCell(cellValue, rowNumber, rowData, handleActionClick) {
  const statusMap = {
    "Publish": { icon: Images.statusIcon.approved, className: "clients-table-cell-account-status-approved" },
    "Unpublish": { icon: Images.statusIcon.rejected, className: "clients-table-cell-account-status-rejected" },
  };

  // Default if not found
  const statusEntry = statusMap[cellValue] || {};

  return (
    <div className="clients-table-cell-account-status">
      <img src={statusEntry.icon} alt={cellValue} />
      <p className={statusEntry.className}>{cellValue}</p>
    </div>
  );
}

export function modiCelFundRecCodi(cell, pageIndex, pageSize, handleActionClick) {
  const { props } = cell;
  const columnName = props?.cell?.column?.Header || null;
  const cellValue = props?.cell?.value || null;
  const rowNumber = props?.row.index + 1;
  const rowData = props?.row?.original || null;
  const formatters = {
    "ACTION": formatActionCell,
    "CREATED DATE": formatDate,
    "MODIFIED DATE": formatDate,
    "NO.": formatNumberCell,
    "STATUS": formatStatusCell
  };

  return formatters[columnName] ? formatters[columnName](cellValue, rowNumber, rowData, handleActionClick) : cell;
}
// end test
export function modiCelHFundRecCodi(cell) {
  if (cell === "FUND NO.") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        FUND NO.
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "CONDITION NAME") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        CONDITION NAME
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "MODIFIED BY") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        MODIFIED BY
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "CREATED DATE") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        CREATED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "MODIFIED DATE") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        MODIFIED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "STATUS") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        STATUS
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else {
    return cell;
  }
}

// ---------------------------FundRecommend Risk ---------------------------

// start test
export function modiCelFundRecRisk(cell, pageIndex, pageSize, handleActionClick) {
  const { props } = cell;
  const columnName = props?.cell?.column?.Header || null;
  const cellValue = props?.cell?.value || null;
  const rowNumber = props?.row.index + 1;
  const rowData = props?.row?.original || null;

  const formatDate = (cellValue) => {
    if (cellValue) {
      const dateObject = new Date(cellValue);
      dateObject.setHours(dateObject.getHours() - 1); 
      return dateObject.toISOString().slice(0, 19).replace("T", " ");
    } else {
      return cellValue;
    }
  };

  const formatStatus = (cellValue) => { 
    if (cellValue != null) {
          return (
            <div style={{ maxWidth: 120 }}>
              <div
                className={
                  cellValue === "hide" ? "fundrec-lock-bar" : "fundrec-unlock-bar"
                }
                style={{ paddingRight: 10 }}
              >
                <button
                  className={
                    cellValue === "show"
                      ? "fundrec-active-unlock"
                      : "fundrec-unlock"
                  }
                  onClick={() => SETxTab("show")}
                  disabled={cellValue === "show" ? true : false}
                >
                  SHOW
                </button>
                <button
                  className={
                    cellValue === "hide" ? "fundrec-active-lock" : "fundrec-lock"
                  }
                  onClick={() => SETxTab("hide")}
                  disabled={cellValue === "hide" ? true : false}
                >
                  HIDE
                </button>
              </div>
            </div>
          );
        } else {
          return "ไม่ได้อยู่ใน Web Service Email";
        }
  };

  const formatFundStatus = (cellValue) => {
    if (typeof cellValue === "boolean" && cellValue) {
      return (
        <div className="funds-status-active">
          <p>Active</p>
        </div>
      );
    } else if (cellValue === false || cellValue === null) {
      return (
        <div className="funds-status-inactive">
          <p>Inactive</p>
        </div>
      );
    } else {
      return cellValue;
    }
  };

  const formatters = {
    "ACTION": formatActionCell,
    "CREATED DATE": formatDate,
    "MODIFIED DATE": formatDate,
    "STATUS": formatStatus,
    "FUND STATUS": formatFundStatus,
  };

  return formatters[columnName] ? formatters[columnName](cellValue, rowNumber, rowData, handleActionClick) : cell;
}

// end test
export function modiCelHFundRecRisk(cell) {
  if (cell === "DESCRIPTION") {
    return (
      <button className="transactions-header-with-sort">
        DESCRIPTION
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "PORT NAME EN") {
    return (
      <button className="transactions-header-with-sort">
        PORT NAME EN
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "MODIFIED BY") {
    return (
      <button className="transactions-header-with-sort">
        MODIFIED BY
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "CREATED DATE") {
    return (
      <button className="transactions-header-with-sort">
        CREATED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "MODIFIED DATE") {
    return (
      <button className="transactions-header-with-sort">
        MODIFIED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else {
    return cell;
  }
}
