import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import orderBy from "lodash.orderby";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { requestRSPList, cleanupRSPList, sortRSPList } from "./redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import DateInput from "./DateInput";
import Pagination from "./Pagination";
import Images from "../../utils/styles/images";
import "./style.scss";

function RSPAccountDetails(props) {
  const {
    requestRSPList,
    requestingRSPList,
    rspList,
    cleanupRSPList,
    sortRSPList,
    totalRSP
  } = props;
  const columns = React.useMemo(() => list_of_columns, []);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [pageNo, setPageNo] = useState("1");
  const [sortDir, setSortDir] = useState("DESC");
  const [nameSortDir, setNameSortDir] = useState("DESC");
  const [sortingNo, setSortingNo] = useState("0");
  const [showFilterInstructions, setShowFilterInstructions] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const getRSP = () => {
    requestRSPList({
      startDate,
      endDate,
      pageNo,
      sortDir,
      sortingNo,
      searchQuery
    });
  };

  useEffect(() => {
    // every time sort direction changes, we call api again (also only allow it to run if dates are set)
    if (!showFilterInstructions) {
      getRSP();
    }

    // on component unmount, do cleanup
    return () => {
      cleanupRSPList();
    };
  }, [sortDir, pageNo]);

  const clearSearch = () => {
    setPageNo(1);
    setSearchValue("");
    setSearchQuery("");
    setSortDir("DESC");
    setSortingNo("0");
    !showFilterInstructions &&
      requestRSPList({
        startDate,
        endDate,
        pageNo: "1",
        sortDir: "DESC",
        sortingNo: "0",
        searchQuery: ""
      });
  };

  const handlePageChange = page => {
    setPageNo(page);
  };

// start test
const handleActionHeaderClick = cell => {
  /*
    sortingNo:
    0 : TransDate
    1 : EffectiveDate
    2 : FUND CODE
    3 : Trans No.
    4 : Status
  */
  if (cell === "ENTRY DATE" || cell === "EFFECTIVE DATE" || cell === "FUND CODE" || cell === "STATUS") {
    setSortDir(prevSortDir => (prevSortDir === "ASC" ? "DESC" : "ASC"));
    setSortingNo(() => {
      switch (cell) {
        case "ENTRY DATE":
          return "0";
        case "EFFECTIVE DATE":
          return "1";
        case "FUND CODE":
          return "2";
        case "STATUS":
          return "4";
        default:
          return "";
      }
    });
  } else if (cell === "FULL NAME") {
    sortRSPList({ data: orderBy(rspList, ["UnitholderName"], [nameSortDir === "ASC" ? "asc" : "desc"]) });
    setNameSortDir(prevNameSortDir => (prevNameSortDir === "ASC" ? "DESC" : "ASC"));
  }
};
// end test

  return (
    <Fragment>
      {requestingRSPList ? (
        <div className="rsp-list-progress">
          <CircularProgress />
          <p className="rsp-list-progress-text">
            {requestingRSPList ? "Retrieving RSP list..." : null}
          </p>
        </div>
      ) : null}
      <div className="rsp-search-header">
        <div className="rsp-search-container">
          <div className="rsp-search">
            <img
              src={Images.search.search}
              alt="search-icon"
              className="rsp-list-begin-search"
              onClick={() =>
                searchQuery.length > 0 && !showFilterInstructions && getRSP()
              }
            />
            <input
              placeholder="Search by Unit holder ID"
              className="rsp-search-bar"
              value={searchValue}
              onChange={event => {
                setSearchValue(event.target.value.trim());
                setSearchQuery(event.target.value.trim());
              }}
              onKeyDown={event => {
                if (event.keyCode === 13 && !showFilterInstructions) {
                  setSearchQuery(event.target.value.trim());
                  getRSP();
                }
              }}
            />
            {searchQuery && searchQuery.length > 0 ? (
              <img
                src={Images.closeButton.close}
                alt="cancel-search"
                className="rsp-details-cancel-search"
                onClick={() => clearSearch()}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="rsp-date-range-container">
        <p className="date-range-filter-date">FILTER DATE</p>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
        />
        <p className="date-range-separator">-</p>
        <DatePicker
          selected={endDate}
          onChange={date => moment(date).isAfter(startDate) && setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yy"
          customInput={<DateInput />}
        />
        <button
          onClick={() => {
            getRSP();
            setShowFilterInstructions(false);
          }}
          className="date-range-filter"
          disabled={!startDate || !endDate}
        >
          FILTER
        </button>
      </div>
      <div className="rsp-list-container">
        {showFilterInstructions ? (
          <div className="filter-date-instructions">
            <img src={Images.transactions.filter} alt="filter-date" />
            <p className="Filter-date-to-view">
              Filter date to view RSP Account Details
            </p>
            <p className="Select-the-date-rang">
              Select the date range using the date picker above to view RSP
              Account Details.
            </p>
          </div>
        ) : (
          <Table
            data={rspList}
            columns={columns}
            handleActionHeaderClick={handleActionHeaderClick}
            pageIndex={pageNo}
            pageSize={10}
          />
        )}
      </div>
      {!showFilterInstructions && totalRSP > 10 ? (
        <Pagination
          handlePageChange={handlePageChange}
          total={totalRSP}
          pageSize={10}
          defaultPage={1}
          pageIndex={Number(pageNo)}
        />
      ) : null}
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.rspReducer
  }),
  {
    requestRSPList,
    sortRSPList,
    cleanupRSPList
  }
);

export default compose(
  withConnect,
  withRouter
)(RSPAccountDetails);
