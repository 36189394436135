import React from "react";
import Images from "../../utils/styles/images";
// ---------------------------Tab1--------------------------
export function modifyCell_Tab1(
  cell,
  ptAccountType,
  pageIndex,
  pageSize,
  handleActionClick
) {
  // Format the cell data here
  const { props } = cell;
  let columnName =
    props && props.cell && props.cell.column && props.cell.column.Header
      ? props.cell.column.Header
      : null;
  let cellValue =
    props && props.cell && props.cell.value ? props.cell.value : null;
  let rowNumber = props && props.row.index + 1;
  let rowData =
    props && props.row && props.row.original ? props.row.original : null;
  let CustomerProgress = props && props.customerProgress.progress
    // start test
    function formatCellValue(cellValue, defaultValue = "-") {
      return cellValue !== null ? cellValue : defaultValue;
    }
    
    function handleActionButtonClick(mode, rowData, columnName) {
        handleActionClick(mode, rowData, columnName); 
    }
    
    function getStatusLabel(cellValue) {
      switch (cellValue.unitholder_status) {
        case 1: return "Pending";
        case 2: return "Approved";
        case 3: return "Rejected";
        case 4: return "In-Review";
        case 5: return "Creating";
        case 6: return "Converted";
        default: return cellValue.unitholder_type === "PVD" ? "Converted" : "-";
      }
      
    }

    function getRiskLevel(cellValue) {
      if (cellValue) {
        return cellValue;
      } else {
        return "-"
      }
    }

  if (columnName === "ACTION") {
    if (!rowData) {
      return cell;
    }
    return (
      <div className="funds-action-buttons">
        <button
          className="funds-action-view"
          onClick={() => handleActionButtonClick("view", rowData, columnName)}
        >
          <img src={Images.fundListIcon.view} alt="eye" />
          View
        </button>
        <button
          className="funds-action-edit"
          onClick={() => handleActionButtonClick("edit", rowData, columnName)}
        >
          <img src={Images.fundListIcon.edit} alt="pencil" />
          Edit
        </button>
      </div>
    );
  } else if (columnName === "NO.") {
    return rowNumber;
  } else if (columnName === "UNITHOLDER ID") {
    console.log("rowData", rowData)
    if(rowData.unitholder_type === "MF"){
      return (
        <div>
        <button
          onClick={() => handleActionButtonClick(cellValue, rowData, columnName)}
          className="feed-list-article-button"
        >
          {formatCellValue(cellValue)}
        </button>        
        </div>
      );
    }else{
      return (
        <div>
            {formatCellValue(cellValue, "N/A")}
          </div>
      );
    }
  } else if (columnName === "STATUS") {
    return getStatusLabel(rowData); 
  } else if (columnName === "RISK LEVEL") {
    return getRiskLevel(rowData.risk_level);
  } else if (columnName === "CUSTOMER STATUS") {
    console.log('columnName === "CUSTOMER STATUS"');
  } else if (columnName === "UNITHOLDER TYPE") {
    return formatCellValue(rowData.unitholder_type, "MF");
  } else if (columnName === "TYPE") {
    if (cellValue) {
      return cellValue;
    } else {
      return "-";
    }
  } else if (columnName === "CREATED AT") {
    if (cellValue) {
      const dateObject = new Date(cellValue);
      dateObject.setHours(dateObject.getHours());
    
      const tDate = `${dateObject.toISOString().slice(0, 19).replace("T", " ")}`;
      return tDate;
    } else {
      return cell;
    }
  } else if (columnName === "UPDATED DATE") {
    if (cellValue) {
      const dateObject = new Date(cellValue);
      dateObject.setHours(dateObject.getHours());
    
      const tDate = `${dateObject.toISOString().slice(0, 19).replace("T", " ")}`;
      return tDate;
    } else {
      return "-";
    }
  } else if (columnName === "FROM") {
    return cellValue === "Unitholder" ? "Unitholder" : "Pentacle";
  } else {
    return cellValue;
  }
}
// end test
export function modifyCellHeader_Tab1(
  cell
) {
  if (cell === "UNIT TYPE") {
    return (
      <button className="transactions-header-with-sort">
        UNIT TYPE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "UNITHOLDER ID") {
    return (
      <button className="transactions-header-with-sort">
        UNITHOLDER ID
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "STATUS") {
    return (
      <button className="transactions-header-with-sort">
        STATUS
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "RISK LEVEL") {
    return (
      <button className="transactions-header-with-sort">
        RISK LEVEL
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "UNITHOLDER TYPE") {
    return (
      <button className="transactions-header-with-sort">
        UNITHOLDER TYPE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "TYPE") {
    return (
      <button className="transactions-header-with-sort">
        TYPE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "DATE ADDED") {
    return (
      <button className="transactions-header-with-sort">
        DATE ADDED
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else {
    return cell;
  }
}

// ---------------------------Tab2--------------------------
export function modifyCell_Tab2(cell, pageIndex, pageSize, handleActionClick) {
  // Format the cell data here
  const { props } = cell;
  let columnName =
    props && props.cell && props.cell.column && props.cell.column.Header
      ? props.cell.column.Header
      : null;
  let cellValue =
    props && props.cell && props.cell.value ? props.cell.value : null;
  let rowNumber = props && props.row.index + 1;
  let rowData =
    props && props.row && props.row.original ? props.row.original : null;

  if (columnName === "ACTION") {
    if (rowData) {
      return (
        <div className="funds-action-buttons">
          <button
            className="funds-action-view"
            onClick={() =>
              handleActionClick({ mode: "view", rowData: rowData })
            }
          >
            <img src={Images.fundListIcon.view} alt="eye" />
            View
          </button>
          <button
            className="funds-action-edit"
            onClick={() =>
              handleActionClick({ mode: "edit", rowData: rowData })
            }
          >
            <img src={Images.fundListIcon.edit} alt="pencil" />
            Edit
          </button>
        </div>
      );
    } else {
      return cell;
    }
  } else if (columnName === "NO.") {
    return rowNumber;
  } else if (columnName === "DEVICE NAME") {
    if (handleActionClick) {
      return (
        <div>
          <button
            onClick={() => handleActionClick(cellValue, rowData, columnName)}
            className="feed-list-article-button"
          >
            {cellValue}
          </button>
        </div>
      );
    } else {
      return cell;
    }
  } else if (columnName === "DATE ADDED" || columnName === "LAST LOGIN DATE") {
    if (cellValue) {
      let tDate = "";
      tDate = cellValue.replace("T", " ").replace(".000Z", "");
      return tDate;
    } else {
      return cell;
    }
  } 
   else if (columnName === "UNITHOLDER TYPE") {
    if (cellValue) {
      return rowData?.unitholder_type;
    } else {
      return cellValue;
    }
  } else {
    return cell;
  }
}
export function modifyCellHeader_Tab2(
  cell
) {
  if (cell === "DEVICE NAME") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        DEVICE NAME
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "DATE ADDED") {
    return (
      <button className="transactions-header-with-sort">
        DATE ADDED
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "LAST LOGIN DATE") {
    return (
      <button className="transactions-header-with-sort">
        LAST LOGIN DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "APP VERSION") {
    return (
      <button className="transactions-header-with-sort">
        APP VERSION
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "LAST LOGIN ISP") {
    return (
      <button className="transactions-header-with-sort">
        LAST LOGIN ISP
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else {
    return cell;
  }
}
// ---------------------------Tab3--------------------------
export function modifyCell_Tab3(cell, pageIndex, pageSize, handleActionClick) {
  // Format the cell data here
  const { props } = cell;
  let columnName =
    props && props.cell && props.cell.column && props.cell.column.Header
      ? props.cell.column.Header
      : null;
  let cellValue =
    props && props.cell && props.cell.value ? props.cell.value : null;
  let rowNumber = props && props.row.index + 1;
  let rowData =
    props && props.row && props.row.original ? props.row.original : null;

  if (columnName === "ACTION") {
    if (rowData) {
      return (
        <div className="funds-action-buttons">
          <button
            className="funds-action-view"
            onClick={() =>
              handleActionClick({ mode: "view", rowData: rowData })
            }
          >
            <img src={Images.fundListIcon.view} alt="eye" />
            View
          </button>
          <button
            className="funds-action-edit"
            onClick={() =>
              handleActionClick({ mode: "edit", rowData: rowData })
            }
          >
            <img src={Images.fundListIcon.edit} alt="pencil" />
            Edit
          </button>
        </div>
      );
    } else {
      return cell;
    }
  } else if (columnName === "NO.") {
    return rowNumber;
  } else if (columnName === "DEVICE NAME") {
    if (handleActionClick) {
      return (
        <div>
          <button
            onClick={() => handleActionClick(cellValue, rowData, columnName)}
            className="feed-list-article-button"
          >
            {cellValue}
          </button>
        </div>
      );
    } else {
      return cell;
    }
  } else if (
    columnName === "CONSENT DATE" ||
    columnName === "RECORD DATE(RGT)" ||
    columnName === "RECORD DATE(SALESFORCE)"
  ) {
    if (cellValue) {
      let aDate1;
      aDate1 = cellValue.split("T");
      return (
        <div>
          <div>{aDate1[0]}</div>
          <div>{aDate1[1].substring(0, 8)}</div>
        </div>
      );
    } else {
      return cell;
    }
  } else if (columnName === "CONSENT TYPE") {
    switch (cellValue) {
      case "1":
        return "Term & Con App";
      case "2":
        return "Consent Marketing";
      case "3":
        return "Term & Con MF";
      case "4":
        return "Consent RMF/SSF";
      case "5":
        return "NDID";
      default:
        return cellValue;
    }
  } else if (columnName === "CONSENT STATUS") {
    switch (cellValue) {
      case "Y":
      case "1":
        return "Accept";
      case "N":
      case "2":
        return "Not accept";
      case "C":
      case "3":
        return "Cancel";
      default:
        return cellValue;
    }
  } else {
    return cell;
  }
}
export function modifyCellHeader_Tab3(
  cell
) {
  if (cell !== "NO.") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        {cell}
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else {
    return cell;
  }
}
