import React from "react";
import Images from "../../utils/styles/images";

// start test
const cellFormatters = {
  "ACTION": (rowData, handleActionClick) => {
    return (
      <div className="funds-action-buttons">
        <button
          className="funds-action-view"
          onClick={() =>
            handleActionClick({ mode: "view", rowData: rowData })
          }
        >
          <img src={Images.fundListIcon.view} alt="eye" />
          View
        </button>
        <button
          className="funds-action-edit"
          onClick={() =>
            handleActionClick({ mode: "edit", rowData: rowData })
          }
        >
          <img src={Images.fundListIcon.edit} alt="pencil" />
          Edit
        </button>
      </div>
    );
  },
  "NO.": (rowNumber) => rowNumber, 
  "ID CARD": (cellValue, rowData, handleActionClick) => {
    return (
      <div>
        <button
          onClick={() => handleActionClick(cellValue, rowData, columnName)}
          className="feed-list-article-button"
        >
          {cellValue}
        </button>
      </div>
    );
  },
  "CREATED AT": (cellValue) => {
    let tDate = "";
      tDate = cellValue.replace("T", " ").replace(".000Z", "");
    return tDate; 
  },
  // Fallback for undefined cellValues
  "ID": (cellValue) => cellValue || cell
};

export function modifyCell(cell, pageIndex, pageSize, handleActionClick) {
  const { props } = cell; 

  const columnName = props.cell.column.Header; 
  const cellValue =  props.cell.value; 
  // fix lint
  // const rowNumber = props.row.index + 1; 
  const rowData = props.row.original;

  const formatter = cellFormatters[columnName];
  return formatter
    ? formatter(cellValue, rowData, columnIndex, handleActionClick)
    : cell;
}
// end test

// This is just for the header cells
export function modifyCellHeader(
  cell
) {
  if (cell === "DEVICE NAME") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        DEVICE NAME
      </button>
    );
  } else if (cell === "VERIFIED BY") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        VERIFIED BY
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "CREATE DATE") {
    return (
      <button className="transactions-header-with-sort">
        CREATE DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "REGISTERED DATE") {
    return (
      <button className="transactions-header-with-sort">
        REGISTERED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "DEREGISTERED DATE") {
    return (
      <button className="transactions-header-with-sort">
        DEREGISTERED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "TYPE") {
    return (
      <button className="transactions-header-with-sort">
        TYPE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "STATUS") {
    return (
      <button className="transactions-header-with-sort">
        STATUS
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else {
    return cell;
  }
}
