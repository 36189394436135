import React from "react";
import Images from "../../utils/styles/images";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
export function modifyCell(
  cell,
  pageSelect,
  pageSize,
  regisLogType,
  handleActionClick
) {
  // Format the cell data here
  const { props } = cell;
  let columnName =
    props && props.cell && props.cell.column && props.cell.column.Header
      ? props.cell.column.Header
      : null;
  let cellValue =
    props && props.cell && props.cell.value ? props.cell.value : null;
  let rowNumber = props && props.row.index + 1;
  let rowData =
    props && props.row && props.row.original ? props.row.original : null;

  if (columnName === "ACTION") {
    if (rowData) {
      return (
        <div className="funds-action-buttons">
          <button
            className="funds-action-view"
            onClick={() =>
              handleActionClick({ mode: "view", rowData: rowData })
            }
          >
            <img src={Images.fundListIcon.view} alt="eye" />
            View
          </button>
          <button
            className="funds-action-edit"
            onClick={() =>
              handleActionClick({ mode: "edit", rowData: rowData })
            }
          >
            <img src={Images.fundListIcon.edit} alt="pencil" />
            Edit
          </button>
        </div>
      );
    } else {
      return cell;
    }
  } else if (columnName === "NO.") {
    
    if (pageSelect !== 0) {
      return rowNumber + pageSelect * 10;
    } else {
      return rowNumber;
    }
  } else if (columnName === "USER NAME") {
    return (
      <div>
        <button
          onClick={() =>
            handleActionClick(cellValue, rowData, columnName, pageSelect)
          }
          className="feed-list-article-button"
        >
          {cellValue === null ? "N/A" : cellValue}
        </button>
      </div>
    );
  } else if (columnName === "IDENTITY CARD") {
    return cellValue === null ? "-" : cellValue;
  } else if (columnName === "EMAIL") {
    return cellValue === null ? "-" : cellValue;
  } else if (columnName === "CREATED AT") {
    if (cellValue) {
      let formattedDate = "";
      if (cellValue.includes("Z")) {
        // Date format is "2023-06-20T11:55:05.6533333Z"
        const date = new Date(cellValue);
        date.setHours(date.getHours());
        formattedDate = date.toISOString().replace("T", " ").split(".")[0];
      } else {
        // Date format is "2023-06-20T10:55:43"
        formattedDate = cellValue.replace("T", " ").replace(".000Z", "");
      }
      return formattedDate;
    } else {
      return cell;
    }
  } else if (columnName === "UPDATED AT") {
    if (cellValue) {
      let tDate = "";
      tDate = cellValue.replace("T", " ").replace(".000Z", "");
      return tDate;
    } else {
      return cell;
    }
  } else if (columnName === "ID") {
    return cell;
    // if (cellValue) {
    //   return cell;
    // } else {
    //   return cell;
    // }
  } else if (columnName === "EXPORT") {
    return (
      <div>
        <button
          onClick={() =>
            handleActionClick(cellValue, rowData, columnName, pageSelect)
          }
          className="conFd-synce-button"
          style={{ width: 71, height: 25, fontSize: 12 }}
        >
          Export file
        </button>
      </div>
    );
  } else if (columnName === "STATUS CODE") {
    let bError = null;
    if (regisLogType === "counter_service") {
      if (rowData?.is_success === 1) {
        // Succcess
        bError = false;
      } else if (rowData?.is_success === 0) {
        // False
        bError = true;
      }

      if (bError === true) {
        return (
          <div>
            Waiting
            <span id="Waiting" style={{ marginLeft: 5 }}>
              <img src={Images.tooltipIcon.tooltipWarnning} alt="Tooltip2" />
            </span>
            <ReactTooltip anchorSelect="#Waiting" place="right" variant="warning" float={true}
            content="status waiting"
            />
          </div>
        );
      } else {
        return (
          <div>
            Success
            <span id="Success" style={{ marginLeft: 5 }}>
              <img src={Images.tooltipIcon.tooltip} alt="Tooltip2" />
            </span>
            <ReactTooltip anchorSelect="#Success" place="right" variant="info" float={true}
            content="Success"
            />
          </div>
        );
      }
    } else if (regisLogType === "ndid") {
      bError = rowData.register_log.includes("errorCode");
      if (bError === true) {
        return (
          <div>
            Waiting
            <span id="Waiting" style={{ marginLeft: 5 }}>
              <img src={Images.tooltipIcon.tooltipWarnning} alt="Tooltip2" />
            </span>
            <ReactTooltip anchorSelect="#Waiting" place="right" variant="warning" float={true}
            content="status waiting"
            />
          </div>
        );
      } else {
        return (
          <div>
            Success
            <span id="Success" style={{ marginLeft: 5 }}>
              <img src={Images.tooltipIcon.tooltip} alt="Tooltip2" />
            </span>
            <ReactTooltip anchorSelect="#Waiting" place="right" variant="info" float={true}
            content="status waiting"
            />
          </div>
        );
      }
    }
  } else {
    return cell;
  }
}

export function modifyCellHeader(
  cell,
  handleActionHeaderClick,
  handleFltHeader
) {
  if (cell === "STATUS CODE") {
    return handleFltHeader ? handleFltHeader(cell) : cell;
  } else {
    return cell;
  }
}
