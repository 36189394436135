/*
 * SideBar
 *
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  clients: {
    id: "app.components.SideMenu.LeftMenu.clients",
    defaultMessage: "clients-EN"
  }, 
  NDID: {
      id: "app.components.SideMenu.LeftMenu.NDID",
      defaultMessage: "NDID"
    },
 CouterService: {
      id: "app.components.SideMenu.LeftMenu.CouterService",
      defaultMessage: "CouterService"
    }, 
    UserAccount: {
      id: "app.components.SideMenu.LeftMenu.UserAccount",
      defaultMessage: "UserAccount"
    },
    ImportRGTtoB2C: {
      id: "app.components.SideMenu.LeftMenu.ImportRGTtoB2C",
      defaultMessage: "Import RGT"
    },


  fundList: {
    id: "app.components.SideMenu.LeftMenu.fundList",
    defaultMessage: "fund list"
  },
  transaction: {
    id: "app.components.SideMenu.LeftMenu.transaction",
    defaultMessage: "transaction"
  },
  ConFdMain: {
    id: "app.components.SideMenu.LeftMenu.ConFdMain",
    defaultMessage: "content feed"
  },
    ConFd_Invest: {
      id: "app.components.SideMenu.LeftMenu.ConFd_Invest",
      defaultMessage: "มุมมองการลงทุน"
    },ConFd_News: {
      id: "app.components.SideMenu.LeftMenu.ConFd_News",
      defaultMessage: "ข่าวสารและรางวัล"
    },ConFd_ConInvest: {
      id: "app.components.SideMenu.LeftMenu.ConFd_ConInvest",
      defaultMessage: "สาระการลงทุน"
    },ConFd_ManualInvest: {
      id: "app.components.SideMenu.LeftMenu.ConFd_ManualInvest",
      defaultMessage: "ประกาศกองทุนรวม"
    },ConFd_NotiInvest: {
      id: "app.components.SideMenu.LeftMenu.ConFd_NotiInvest",
      defaultMessage: "Notify Invest"
    },ConFd_Srch: {
      id: "app.components.SideMenu.LeftMenu.ConFd_Srch",
      defaultMessage: "ค้นหา"
    },ConFd_Others: {
      id: "app.components.SideMenu.LeftMenu.ConFd_Others",
      defaultMessage: "อื่นๆ"
    },
    

  transactionDetails: {
    id: "app.components.SideMenu.LeftMenu.transactionDetails",
    defaultMessage: "Transaction details"
  },

  tag: {
    id: "app.components.SideMenu.LeftMenu.tag",
    defaultMessage: "Tag"
  },  

  SearchRec: {
    id: "app.components.SideMenu.LeftMenu.SearchRec",
    defaultMessage: "Search Recommend"
  },

  FundRec: {
    id: "app.components.SideMenu.LeftMenu.FuntRec",
    defaultMessage: "Fund Recommend"
  },
    FundRec_FundMan: {
      id: "app.components.SideMenu.LeftMenu.FundRecFundMan",
      defaultMessage: "By Fund Manager"
    },
    FundRec_Condi: {
      id: "app.components.SideMenu.LeftMenu.FundRecCondi",
      defaultMessage: "By Condition"
    },
    FundRec_Risk: {
      id: "app.components.SideMenu.LeftMenu.FundRecRisk",
      defaultMessage: "SIMPLE ADVISORY"
    },

  ContFS: {
    id: "app.components.SideMenu.LeftMenu.ContFS",
    defaultMessage: "Contenet Feed Sort"
  },
  RegisDevice: {
    id: "app.components.SideMenu.LeftMenu.RegisDevice",
    defaultMessage: "Registered Device"
  },
  Activity: {
    id: "app.components.SideMenu.LeftMenu.Activity",
    defaultMessage: "Activity Log"
  },
  Activity_Usr: {
    id: "app.components.SideMenu.LeftMenu.Activity",
    defaultMessage: "User"
  },
  Activity_Admin: {
    id: "app.components.SideMenu.LeftMenu.Activity",
    defaultMessage: "Admin"
  },

  RegisterLog: {
    id: "app.components.SideMenu.LeftMenu.RegisLog",
    defaultMessage: "Register Log"
  },
    RegisLog_CounterService: {
      id: "app.components.SideMenu.LeftMenu.RegisLog",
      defaultMessage: "Conuter Service"
    },
    RegisLog_NDID: {
      id: "app.components.SideMenu.LeftMenu.RegisLog",
      defaultMessage: "NDID"
    },
    NDIDLog: {
      id: "app.components.SideMenu.LeftMenu.RegisLog",
      defaultMessage: "NDID Log"
    },
  Consent: {
    id: "app.components.SideMenu.LeftMenu.Consent",
    defaultMessage: "Consent"
  }, 
  Analytic: {
    id: "app.components.SideMenu.LeftMenu.Consent",
    defaultMessage: "Analytic"
  },
  Easyinvest: {
      id: "app.components.SideMenu.LeftMenu.Easyinvest",
      defaultMessage: "E@asyinvest"
    },
  Applicant: {
      id: "app.components.SideMenu.LeftMenu.Applicant",
      defaultMessage: "Applicant"
  }
  
});
