import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  REQFundTag,
  RESFundTag,
  FailFundTag,
  REQSrchRecDetailEdt,
  RESSrchRecDetailEdt,
  FailSrchRecDetailEdt
} from "./actions";
import * as actions from "../../Authentication/redux/actions";


function* fetchFundTagListWorker() {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  try {
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/tag/fund_tag`,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    if (response && response.data) {
      yield put(RESFundTag(response.data));
    } else {
            yield put(RESFundTag());
    }
  } catch (error) {
    
    yield put(FailFundTag());
  }
}

function* EDTxSrchRecDetail({ payload }) {
  try {
    const { FormData, history } = payload;
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    let body = FormData;
    const response = yield call(
      api.post,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/search_recommend/edit`,
      body,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    console.log("search_recommend/edit response:",response.data);
    if (response && response.data && response.status === 200) {
      
      yield put(RESSrchRecDetailEdt(response));
      history.goBack();
    } else {
      yield put(FailSrchRecDetailEdt());
    }
  } catch (error) {
    
    yield put(FailSrchRecDetailEdt());
  }
}
export function* srchRecDetailSaga() {
  yield all([
    // takeLatest(REQFundCodeList, fetchFundCodeListWorker),
    takeLatest(REQFundTag, fetchFundTagListWorker),
    takeLatest(REQSrchRecDetailEdt, EDTxSrchRecDetail)
  ]);
}
