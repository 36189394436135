import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQUsrActList } from "./redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import { MEMUsrActDetail } from "../UsrActDetail/redux/actions";
import Images from "../../utils/styles/images";
import "./style.scss";

import Pagination from "./Pagination";
function UsrActList(props) {
  const {
    REQUsrActList,
    REQStrUsrActList,
    UsrActList,
    totalUsrAct,
    MEMUsrActDetail,
    history
  } = props;
  const columns = React.useMemo(() => list_of_columns, []);
  const [tSrch, SETxSrch] = useState("");
  const [tOpSrch, SETxOpSrch] = React.useState("Email");
  const [nPageIndex, SETxPageIndex] = useState(0);
  const [tSortH, SETtSortH] = useState("created_at DESC");
  const [tFltActStage, SETtFltActStage] = useState([
    "Verified by",
    "Soft Onboarding",
    "Approve KYC"
  ]);
  const CALLxUsrAct = () => {
    if (history.location.PageCurrent === undefined) {
      SETxPageIndex(0);
      REQUsrActList({
        page: "1",
        tSrch: tSrch,
        tOpSrch,
        tSortH,
        tFltActStage
      });
    } else {

      let nPage = history.location.PageCurrent + 1;
      // SET value UI
      SETxOpSrch(history.location.UISearch.tOpSrch);
      SETxSrch(history.location.UISearch.tSrch);

      SETxPageIndex(history.location.PageCurrent);
      REQUsrActList({
        page: nPage.toString(),
        tSrch: history.location.UISearch.tSrch,
        tOpSrch: history.location.UISearch.tOpSrch,
        tSortH,
        tFltActStage
      });
    }
  };

  useEffect(() => {
    CALLxUsrAct();
  }, [tSortH, tFltActStage]);

  const SRCHxUsrAct = () => {
    SETxPageIndex(0);
    REQUsrActList({
      page: "1",
      tSrch: tSrch,
      tOpSrch,
      tSortH,
      tFltActStage
    });
  };
  const SRCHxUsrActClr = () => {
    SETxSrch("");
    SETxPageIndex(0);
    REQUsrActList({
      page: "1",
      tSrch: "",
      tOpSrch,
      tSortH,
      tFltActStage
    });
  };
  const RadioInput = ({ label, value, checked, setter }) => {
    return (
      <span>
        <input
          type="radio"
          checked={checked === value}
          onChange={() =>
            {
              setter(value);
            }
          }
        />
        <span style={{ marginLeft: 0 }}>{label}</span>
      </span>
    );
  };

  // start test
  const sortMappings = {
    "REGISTER DATE": { 
      ascending: "created_at ASC", 
      descending: "created_at DESC"
    },
    "USERNAME": { 
        ascending: "username ASC",
        descending: "username DESC" 
    },
    "E-MAIL": { 
        ascending: "email ASC",
        descending: "email DESC" 
    },
    "ACCOUNT TYPE": { 
        ascending: "hasConverted ASC", 
        descending: "hasConverted DESC"
    },
    "UNITHOLDER": { 
        ascending: "UnitCount ASC",
        descending: "UnitCount DESC" 
    }
  };

  const SETxActHeaderClick = cell => {
    if (sortMappings[cell]) {
      const newSortH = tSortH === sortMappings[cell].ascending 
                        ? sortMappings[cell].descending 
                        : sortMappings[cell].ascending;
      SETtSortH(newSortH);
    }
  };
  // end test

  
  const SETxActTableClick = (cellValue, rowData, columnName) => {
    // console.log(rowData);

    if (columnName === "USERNAME") {
      MEMUsrActDetail({
        RowDataAt: rowData,
        PageCurrent: nPageIndex,
        UISearch: {
          tSrch: tSrch,
          tOpSrch: tOpSrch,
          tSortH: tSortH
        }
      });
      localStorage.setItem('currentUsrActDetail', JSON.stringify(rowData.id));
      history.push(`/UsrActDetail/${rowData.id}`);
    }
  };
  const SETxPageGo = pnPageAt => {
    let nPageAt = pnPageAt + 1;
    SETxPageIndex(pnPageAt);
    REQUsrActList({
      page: nPageAt.toString(),
      tSrch,
      tOpSrch,
      tSortH,
      tFltActStage
    });
    
  };
  const CALxPaging = () => {
    let nPageCnt = Math.ceil(totalUsrAct / 10);
    return nPageCnt > 1 ? (
      <Pagination
        nPageIndex={nPageIndex}
        total={nPageCnt}
        gotoPage={SETxPageGo}
      />
    ) : null;
  };
  const GETxWidthSrch = () => {
    if (tOpSrch === "UUID") {
      return 450;
    } else {
      return 331;
    }
  };
  const SETxFltHeader = cell => {
    if (cell === "ACCOUNT STAGE") {
      return (
        <div className="clients-header-sortby-converted-user">
          <div className="clients-header-sortby-text-container-converted-user">
            <p>{cell}</p>
            <img
              className="clients-header-sortby-img-converted-user"
              src={Images.filer.filer}
              alt="down-arrow"
            />
          </div>
          <div
            className="clients-header-sortby-radio-container-converted-user"
            style={{ width: 208, height: 150 }}
          >
            <p>FILTER ACCOUNT STAGE BY</p>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Verified by"
                checked={tFltActStage.find(f => f === "Verified by")}
                onChange={() => {
                  let bChk = tFltActStage.includes("Verified by");
                  if (bChk === true) {
                    let tFlt = tFltActStage.filter(f => f !== "Verified by");
                    SETtFltActStage(tFlt);
                  } else if (bChk === false) {
                    let tAdd = [...tFltActStage, "Verified by"];
                    SETtFltActStage(tAdd);
                  }
                }}
              />
              <label htmlFor="Verified by-user" className="checkbox-label">
                Verified by
              </label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Soft Onboarding by"
                checked={tFltActStage.find(f => f === "Soft Onboarding")}
                onChange={() => {
                  let bChk = tFltActStage.includes("Soft Onboarding");
                  if (bChk === true) {
                    let tFlt = tFltActStage.filter(
                      f => f !== "Soft Onboarding"
                    );
                    SETtFltActStage(tFlt);
                  } else if (bChk === false) {
                    let tAdd = [...tFltActStage, "Soft Onboarding"];
                    SETtFltActStage(tAdd);
                  }
                }}
              />
              <label
                htmlFor="Soft Onboarding by-user"
                className="checkbox-label"
              >
                Soft Onboarding
              </label>
            </div>
            <div className="clients-header-sortby-row">
              <input
                type="checkbox"
                value="Approve KYC by"
                checked={tFltActStage.find(f => f === "Approve KYC")}
                onChange={() => {
                  let bChk = tFltActStage.includes("Approve KYC");
                  if (bChk === true) {
                    let tFlt = tFltActStage.filter(f => f !== "Approve KYC");
                    SETtFltActStage(tFlt);
                  } else if (bChk === false) {
                    let tAdd = [...tFltActStage, "Approve KYC"];
                    SETtFltActStage(tAdd);
                  }
                }}
              />
              <label htmlFor="Approve KYC by-user" className="checkbox-label">
                Approve KYC
              </label>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <Fragment>
      <div className="button-create-container" style={{ marginBottom: 10 }}>
        <div style={{ height: 40 }} />
        {REQStrUsrActList ? (
          <div className="regisDevice-list-progress">
            <CircularProgress />
            <p className="regisDevice-list-progress-text">
              {REQStrUsrActList ? "Retrieving UserAccount list..." : null}
            </p>
          </div>
        ) : null}
      </div>

      <div className="labelInput" style={{ marginLeft: 20, marginTop: 50 }}>
        <span className="labelInputText" style={{ width: 100 }}>
          Search By
        </span>
        <span style={{ paddingRight: 10 }}>:</span>
        <div
          className="usrAct-search"
          style={{ marginTop: 0, width: GETxWidthSrch() }}
        >
          <img
            src={Images.search.search}
            alt="search-icon"
            className="transactions-list-begin-search"
            onClick={() => SRCHxUsrAct()}
          />
          <input
            placeholder={"Search by " + tOpSrch}
            className="usrAct-search-bar"
            value={tSrch}
            onChange={event => {
              SETxSrch(event.target.value.trim());
            }}
            onKeyDown={event => {
              
              if (event.keyCode === 13) {
                SRCHxUsrAct();
              }
            }}
          />
          {tSrch && tSrch.length > 0 ? (
            <img
              src={Images.closeButton.close}
              alt="cancel-search"
              className="transactions-details-cancel-search"
              onClick={() => SRCHxUsrActClr()}
            />
          ) : null}
        </div>
      </div>
      <div
        className="clients-radio-container"
        style={{ marginLeft: 145, marginBottom: 20 }}
      >
        <RadioInput
          label="E-mail"
          value="Email"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Username"
          value="Username"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="English Name"
          value="English Name"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Thai Name"
          value="Thai Name"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="ID No. / Passport No."
          value="ID No. / Passport No."
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
        <span style={{ marginRight: 20 }} />
        <RadioInput
          label="Mobile"
          value="Mobile"
          checked={tOpSrch}
          setter={SETxOpSrch}
        />
      </div>
      <button
        style={{ marginLeft: 20, marginBottom: 20 }}
        onClick={() => {
          SRCHxUsrAct();
        }}
        className="conFd-synce-button"
      >
        Search
      </button>
      {}
      {REQStrUsrActList ? null : (
        <Table
          columns={columns}
          data={UsrActList || []}
          handleActionHeaderClick={SETxActHeaderClick}
          handleFltHeader={SETxFltHeader}
          handleActionClick={SETxActTableClick}
          pageSelect={nPageIndex}
        />
      )}
      {REQStrUsrActList ? null : <CALxPaging />}
    </Fragment>
  );
}

const withConnect = connect(
  state => ({
    ...state.usrActListReducer
  }),
  {
    REQUsrActList,
    MEMUsrActDetail
  }
);

export default compose(
  withConnect,
  withRouter
)(UsrActList);
