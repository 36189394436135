import React, { Fragment, useState } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter
} from "react-table";
import { modifyCell, modifyCellHeader } from "./cell_formatter";
import Images from "../../utils/styles/images";
import Pagination from "./Pagination";

function Table({ columns,
   data, 
   handleActionHeaderClick, 
   handleSortHeader, 
   handleActionClick,
   history
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageCount,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    state,
    setGlobalFilter
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: history.location.PageCurrent, pageSize: 10 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const  [searchValue, setSearchValue] = useState(undefined);
  return (
    
    <Fragment>
      <div className="fundrec-search-header">
        <div className="fundrec-search-container">
          <div className="fundrec-search">
            <img
              src={Images.search.search}
              alt="search-icon"
              className="fundrec-list-begin-search"
              onClick={() =>
                searchValue.length > 0 && setGlobalFilter(searchValue)
              }
            />
            <input
              placeholder="Search by Fund Name"
              className="fundrec-search-bar"
              value={state.globalFilter || ""}
              onChange={event => {
                setSearchValue(event.target.value);
                setGlobalFilter(event.target.value);
              }}
              onKeyDown={event => {
                if (event.keyCode === 13) {
                  setSearchValue(event.target.value.trim());
                  setGlobalFilter(event.target.value.trim());
                }
              }}
            />
            {searchValue && searchValue.length > 0 ? (
              <img
                src={Images.closeButton.close}
                alt="cancel-search"
                className="fundrec-details-cancel-search"
                onClick={() => {
                  setSearchValue(undefined);
                  setGlobalFilter(undefined);
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="fundrec-list-container">
        <table className="fundrec-table" {...getTableProps()}>
          <thead className="fundrec-head-container">
            {headerGroups.map(headerGroup => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="fundrec-head-row"
              >
                {headerGroup.headers.map((column, i) => (
                  <th
                    // {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={`key-${i}`}
                    className="fundrec-header"
                    style={{ width: column.render("width") } }
                  >
                    {modifyCellHeader(
                      column.render("Header"),
                      handleActionHeaderClick,
                      handleSortHeader
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps} className="fundrec-table-body">
            {page.length <= 0 ? (
              <tr className="fundrec-table-empty-row">
                <td colSpan={14}>
                  <div className="fundrec-table-no-data">
                    <img src={Images.noData.board} alt="no-data-found-board" />
                    <p className="fundrec-table-no-data-header">
                      No result found
                    </p>
                    <p className="fundrec-table-no-data-body">
                      We couldn’t find what you are looking for.
                      <br /> Please try again.
                    </p>
                  </div>
                </td>
              </tr>
            ) : (
              page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="fundrec-table-row">
                    {row.cells.map(cell => {
                        
                      return (
                        <td {...cell.getCellProps()} className="fundrec-cell">
                          {
                            modifyCell(cell.render("Cell"), pageIndex, pageSize, handleActionClick)
                          }
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {pageCount > 1 ? (
        <Pagination
          pageIndex={pageIndex}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          total={pageCount}
          gotoPage={gotoPage}
        />
      ) : null}
    </Fragment>
  );
}

export default Table;
