import { put, call, all, takeLatest, select } from "redux-saga/effects";
import * as api from "../../../utils/api";
import {
  requestContentFeedListAction,
  receiveContentFeedListAction,
  failContentFeedListAction,
  requestInterestsListAction,
  receiveInterestsListAction,
  failInterestsListAction,
  // --------GETConFd--------
  REQConFdList,
  RESConFdList,
  FailConFdList,
  // --------GET Synce--------
  REQSyncEN,
  RESSyncEN,
  FailSyncEN,
  REQSyncTH,
  RESSyncTH,
  FailSyncTH,

  // --------GET Synce PDF--------
  REQSyncPDFEN,
  RESSyncPDFEN,
  FailSyncPDFEN,
  REQSyncPDFTH,
  RESSyncPDFTH,
  FailSyncPDFTH,

  REQFundCodeList,
  RESFundCodeList,
  FailFundCodeList,
} from "./actions";
import * as actions from "../../Authentication/redux/actions";

const Mapping_FeedStatusCode = [
  {
    code: "1",
    titleEn: "Draft",
    titleTh: "??"
  },
  {
    code: "2",
    titleEn: "Published",
    titleTh: "??"
  }
];

function* fetchInterestsListWorker({ payload }) {
  // We need this API to map the interestCode in Feed list to actual text for Category
  try {
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/interests?fields=imgUrl,code,name,nameEn,nameTh`
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    if (response && response.data && response.data.data) {
      // store the interest list
      yield put(receiveInterestsListAction(response.data.data));
      // call the content feed list API
      yield put(requestContentFeedListAction(payload));
    } else {
      
      yield put(failInterestsListAction());
    }
  } catch (error) {
    
    yield put(failInterestsListAction());
  }
}
function* fetchContentFeedListWorker({ payload }) {
  try {
    const { sortDir } = payload;
    const { interestsList } = yield select(
      state => state.contentFeedListReducer
    );
    let currentPage = 1;
    let feedsPerPage = 50;
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/feeds?fields=articles{uuid,statusCode,interestCode,articleTitle,modifiedBy,modifiedAt}&perPage=${feedsPerPage}&page=${currentPage}&sortKey= interest.name_en&sortDir=${sortDir}`
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    if (
      response &&
      response.data.data.articles.length > 0 &&
      interestsList.length > 0
    ) {
      // We need to add the status and category text into each article based on the retrieved code
      // Status (manual mapping, no API for this)
      // Category (based off Interest list API)
      response.data.data.articles.forEach(article => {
        article["status"] = Mapping_FeedStatusCode.find(
          mapObj => mapObj.code === article.statusCode
        ).titleEn;

        article["category"] = interestsList.find(
          interest => interest.code === article.interestCode
        ).nameEn;
      });

      yield put(receiveContentFeedListAction(response.data.data));
    } else if (
      response &&
      response.data.data.articles
    ) {
      yield put(receiveContentFeedListAction(response.data.data));
    } else {
      
      yield put(failContentFeedListAction());
    }
  } catch (error) {
    
    yield put(failContentFeedListAction());
  }
}

// ---------------GET ContFeed-------------
function* fetchConFdListWorker({ payload }) {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  try {
    const { FormData } = payload;
    const response = yield call(api.get,`${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/feeds/article/${FormData}`,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    if (response && response.data.data) {
      yield put(RESConFdList(response.data.data));
    } else {
      yield put(FailConFdList());
    }
  } catch (error) {
    
    yield put(FailConFdList());
  }
}
// ---------------GET Sync-------------
function* SYNCxContentEN({ payload }) {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  const { Sync } = payload;
  try {
    console.log("SYNCxContentEN Sync:",Sync);
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/sync_article/${Sync}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    const response = yield request.json();
    console.log("SYNCxContentEN response:",response);

    if (response && response.message === 'success') {
      yield put(RESSyncEN(response.data));
    } else {
            yield put(FailSyncEN());
    }
  } catch (error) {
    
    yield put(FailSyncEN());
  }
}
function* SYNCxContentTH({ payload }) {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  const { Sync } = payload;
  try {
    console.log("SYNCxContentTH Sync:",Sync);
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/sync_article/${Sync}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    const response = yield request.json();
    console.log("SYNCxContentTH response:",response);

    if (response && response.message === 'success') {
      yield put(RESSyncTH(response.data));
    } else {
            yield put(FailSyncTH());
    }
  } catch (error) {
    
    yield put(FailSyncTH());
  }
}

// ---------------GET Sync PDF-------------
function* SYNCxContentPDFEN({ payload }) {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  const { URL, Sync } = payload;
  
  try {
    const response = yield call(api.get, `/${URL}/${Sync}`, {
      headers: {
        
          userid: tGUID,
          id_token: "Bearer " + accessToken
      }
    });
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }

    

    if (response && response.data) {
      yield put(RESSyncPDFEN(response.data));
    } else {
            yield put(FailSyncPDFEN());
    }
  } catch (error) {
    
    yield put(FailSyncPDFEN());
  }
}
function* SYNCxContentPDFTH({ payload }) {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);
  const { URL, Sync } = payload;
  try {
    const response = yield call(
      api.get,
      `/${URL}/${Sync}`,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }

    

    if (response && response.data) {
      yield put(RESSyncPDFTH(response.data));
    } else {
            yield put(FailSyncPDFTH());
    }
  } catch (error) {
    
    yield put(FailSyncPDFTH());
  }
}
function* fetchFundCodeListWorker() {
  const accessToken = yield select(state => state.auth.accessToken);
  const tGUID = yield select(state => state.auth.GUID);

  try {
    const response = yield call(
      api.get,
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/dashboard/funds`,
      {
        headers: {
          
          userid: tGUID,
          id_token: "Bearer " + accessToken
        }
      }
    );
    if (response.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
    
    if (response && response.data) {
      yield put(RESFundCodeList(response.data));
    } else {
            yield put(FailFundCodeList());
    }
  } catch (error) {
    
    yield put(FailFundCodeList());
  }
}
export function* contentFeedListSagas() {
  yield all([
    takeLatest(requestInterestsListAction, fetchInterestsListWorker),
    takeLatest(requestContentFeedListAction, fetchContentFeedListWorker),

    takeLatest(REQConFdList, fetchConFdListWorker),
    takeLatest(REQSyncEN, SYNCxContentEN),
    takeLatest(REQSyncTH, SYNCxContentTH),

    takeLatest(REQSyncPDFEN, SYNCxContentPDFEN),
    takeLatest(REQSyncPDFTH, SYNCxContentPDFTH),

    takeLatest(REQFundCodeList, fetchFundCodeListWorker),

  ]);
}
