import { put, call, all, takeLatest, select } from "redux-saga/effects";
import {
// --------------Register Log-----------------
  REQRegisLogList,
  RESRegisLogList,
  FailRegisLogList,
// --------------Ndid Log-----------------
  REQCusDetailList,
  RESCusDetailList,
  FailCusDetailList

} from "./actions";
import moment from "moment";
import * as actions from "../../Authentication/redux/actions";


// start test
function buildSearchParams(tSrch, tOpSrch) {
  const searchParams = {
    emailSrch: '',
    usrNameSrch: '',
    UUIDSrch: '',
    nameENSrch: '',
    nameTHSrch: '',
    idnumberSrch: '',
    mobileSrch: ''
  };

  switch (tOpSrch) {
    case "Email":
      searchParams.emailSrch = tSrch;
      break;
    case "Username":
      searchParams.usrNameSrch = tSrch;
      break;
    case "UUID":
      searchParams.UUIDSrch = tSrch;
      break;
    case "English Name":
      searchParams.nameENSrch = tSrch;
      break;
    case "Thai Name":
      searchParams.nameTHSrch = tSrch;
      break;
    case "ID No. / Passport No.":
      searchParams.idnumberSrch = tSrch;
      break;
    case "Mobile":
      searchParams.mobileSrch = tSrch;
      break;
    default:
      break;
  }
  return searchParams;
}

function formatStatusFilter(tFltStaCode) {
  if (tFltStaCode.includes("Success") && tFltStaCode.includes("Error")) {
    return "'Success','Error'";
  } else if (tFltStaCode.includes("Success")) {
    return "'Success'";
  } else if (tFltStaCode.includes("Error")) {
    return "'Error'";
  } else { 
    return "";
  }
}

function* fetchRegisLogListWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { page, tStartD, tEndD, tSrch, tOpSrch, tSortH, tRegisLogType, tFltStaCode } = payload;
    const searchParams = buildSearchParams(tSrch, tOpSrch); 
    const formattedStatus = formatStatusFilter(tFltStaCode);

    const request = yield fetch(`${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin/registerLog?page=${page}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        userid: tGUID,
        id_token: "Bearer " + accessToken
      },
      body: JSON.stringify({
        ...searchParams,
        startDate: moment(tStartD).format('YYYY-MM-DD'), 
        endDate: moment(tEndD).format('YYYY-MM-DD') + " 23:59",  
        registerType: tRegisLogType,
        sortH: tSortH,
        FltStaCode: formattedStatus
      })
    });
    if (request.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }

    const response = yield request.json();
    
    if (response) {
      yield put(RESRegisLogList(response));
    } else {
      yield put(RESRegisLogList());
    }
  } catch (error) {
    yield put(FailRegisLogList());
  }
}
// end test
function* fetchCusDetailListWorker({ payload }) {
  try {
    const accessToken = yield select(state => state.auth.accessToken);
    const tGUID = yield select(state => state.auth.GUID);
    const { page,tStartD,tEndD,tSrch,tOpSrch,tSortH} = payload;
    const tStartD1 = moment(tStartD).format('YYYY-MM-DD');
    let tEndD1 = moment(tEndD).format('YYYY-MM-DD');
    tEndD1 = tEndD1+" 23:59";

    let tEmailSrch="";
    let tUsrNameSrch="";
    let tUUIDSrch="";
    let tFirstNameSrch="";
    let tIDNoSrch="";
    let tMobileSrch="";

    switch (tOpSrch) {
      case "Email":
        tEmailSrch =tSrch;
      break;
      case "Username":
        tUsrNameSrch=tSrch;
      break;
      case "UUID":
        tUUIDSrch=tSrch;
      break;
      case "First Name":
        tFirstNameSrch=tSrch;
      break;
      case "ID No. / Passport No.":
        tIDNoSrch=tSrch;
      break;
      case "Mobile":
        tMobileSrch=tSrch;
      break;
    default:
      break;
  }

    const request = yield fetch(`${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin/ndidLog?page=${page}`, 
    { 
      method: "POST",  
      headers:
       {
        "Content-Type": "application/json",
        userid: tGUID,
          id_token: "Bearer " + accessToken
      },
      body: JSON.stringify({
        // ---Search----
        emailSrch: tEmailSrch,
        usernameSrch: tUsrNameSrch,
        uuidSrch: tUUIDSrch,
        firstNameSrch: tFirstNameSrch,
        idnumberSrch: tIDNoSrch,
        mobileSrch: tMobileSrch,
    
        startDate:tStartD1,
        endDate:tEndD1,

        sortH:tSortH
      })
       
    });
    if (request.status === 401) {
      yield put(actions.autoSignOut()); 
      return; 
    }
   
    const response = yield request.json();
     
    if (response) 
    {
     yield put(RESCusDetailList(response));
    } 
    else
     {
      
      yield put(RESCusDetailList());
    }

  } catch (error) {
    
    yield put(FailCusDetailList());
  }
}
export function* regisLogListSaga() {
  yield all([
    takeLatest(REQRegisLogList, fetchRegisLogListWorker) ,
    takeLatest(REQCusDetailList, fetchCusDetailListWorker)   
  ]);
}
