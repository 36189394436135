import React from "react";
import moment from "moment";
import Images from "../../utils/styles/images";

export function modifyCell(cell, pageIndex, pageSize, handleActionClick) {

  // Format the cell data here
  const { props } = cell;
  let columnName =
    props && props.cell && props.cell.column && props.cell.column.Header
      ? props.cell.column.Header
      : null;
  let cellValue =
    props && props.cell && props.cell.value ? props.cell.value : null;
     let rowNumber = props && props.row.index + 1;
  let rowData =
    props && props.row && props.row.original ? props.row.original : null;

    let aLangTagName;
  if (columnName === "ACTION") {
    if (rowData) {
      return (
        <div className="funds-action-buttons">
          <button
            className="funds-action-view"
            onClick={() =>
              handleActionClick({ mode: "view", rowData: rowData })
            }
          >
            <img src={Images.fundListIcon.view} alt="eye" />
            View
          </button>
          <button
            className="funds-action-edit"
            onClick={() =>
              handleActionClick({ mode: "edit", rowData: rowData })
            }
          >
            <img src={Images.fundListIcon.edit} alt="pencil" />
            Edit
          </button>
        </div>
      );
    } else {
      return cell;
    }
  } 
  else if (columnName === "TAG NAME") {
    if (handleActionClick) {
        // cellValue
        aLangTagName = cellValue?.split("|");
      return (
        <div>
        <button
          onClick={() => handleActionClick(cellValue, rowData, columnName)}
          className="feed-list-article-button"
        >
          {aLangTagName?.[0] ?? ""}
        </button>
        <button
             onClick={() => handleActionClick(cellValue, rowData, columnName)}
             className="feed-list-article-button"
           >
             {aLangTagName?.[1] ?? ""}
        </button>
      </div>
      );
    } else {
      return cell;
    }
  }
  // fix lint
  else if (columnName === "CREATED DATE" || columnName === "MODIFIED DATE") {
    if (cellValue) {
      return moment(cellValue).format("YYYY-MM-DD");
    } else {
      return cell;
    }
  }  


  else if (columnName === "STATUS") {
    switch (cellValue) {
      case "Publish":
        return (
          <div className="clients-table-cell-account-status">
            <img src={Images.statusIcon.approved} alt="approved-icon" />
            <p className="clients-table-cell-account-status-approved">Publish</p>
          </div>
        );
      case "Unpublish":
        return (
          <div className="clients-table-cell-account-status">
            <img src={Images.statusIcon.rejected} alt="pending-icon" />
            <p className="clients-table-cell-account-status-rejected">Unpublish</p>
          </div>
        );
      default:
        break;
    }
  }
  else if (columnName === "NO.") 
  {
    
    return rowNumber ;
  } 
  else {
    return cell;
  }
}

// This is just for the header cells
export function modifyCellHeader(cell, handleActionHeaderClick, handleSortHeader) {
  if (cell === "TAG NAME") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        TAG NAME
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }
  else if (cell === "MODIFIED BY") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        MODIFIED BY
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }  else if (cell === "CREATED DATE") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        CREATED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }else if (cell === "MODIFIED DATE") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        MODIFIED DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }else if (cell === "TYPE") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        TYPE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }else if (cell === "STATUS") {
    return (
      <button
        className="transactions-header-with-sort"
      >
        STATUS
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  }
  else {
    return cell;
  }
}
