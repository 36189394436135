export const aColEayInv = [
  {
    Header: "NO.",
    accessor: "no",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:10,
    width: 60
  },
  {
    Header: "REGISTER DATE",
    accessor: "created_at",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:0
  }, 
  {
    Header: "UNITHOLDER ID",
    accessor: "unitholder_id",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:10,
  },
  {
    Header: "USERNAME",
    accessor: "username",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 160,
    paddingLeft:10
  },
  {
    Header: "E-MAIL",
    accessor: "email",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 180,
    paddingLeft:10
  },
  {
    Header: "NAME",
    accessor: "firstnamecli",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 120,
    paddingLeft:10
  },
  {
    Header: "NAME (TH)",
    accessor: "first_name_th",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:10,
  },
  {
    Header: "ID / PASSPORT NO.",
    accessor: "identification_no",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:10,
  },
  {
    Header: "MOBILE NO.",
    accessor: "mobile",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:10,
  },
  

];


export const aColUntHld = [
  {
    Header: "NO.",
    accessor: "no",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    paddingLeft:10,
    width: 60
  },
  {
    Header: "CREATE DATE",
    accessor: "created_at",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:0
  }, 
  {
    Header: "UUID",
    accessor: "customer_uuid",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 160,
    paddingLeft:10
  },
  {
    Header: "USERNAME",
    accessor: "username",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 180,
    paddingLeft:10
  },
  {
    Header: "E-MAIL",
    accessor: "email",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 180,
    paddingLeft:15
  }
  ,{
    Header: "NAME",
    accessor: "first_name_en",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 140,
    paddingLeft:20
  } 
  ,{
    Header: "THAI NAME",
    accessor: "first_name_th",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 120,
    paddingLeft:10
  } 
  ,{
    Header: "ID / PASSPORT NO.",
    accessor: "identification_no",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 140,
    paddingLeft:10
  } 
  ,{
    Header: "MOBILE NO.",
    accessor: "mobile",
    disableSortBy: true,
    display:"",
    textAlign:"left",
    width: 150,
    paddingLeft:10
  } 
];
