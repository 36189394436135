import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQConsentVSList } from "./redux/actions";
import Table from "./table";
import { list_of_columns } from "./list_of_columns";
import { MEMConsentVSDetail } from "../ConsentVSDetail/redux/actions";
import Images from "../../utils/styles/images";
import "./style.scss";



import Pagination from "./Pagination";
function ConsentVSList(props) {
  const {
    REQConsentVSList,
    REQStrConsentVSList,
    ConsentVSList,
    totalConsentVS,
    MEMConsentVSDetail,
    RowDataAt,
    history
  } = props;

  const columns = React.useMemo(() => list_of_columns, []);
  const [nPageIndex, SETxPageIndex] = useState(0);
  const [tSortH, SETtSortH] = useState("created_at DESC");
  const [isPageReload, setIsPageReload] = useState(false);
  const [isUnloading, setIsUnloading] = useState(false);

  const CALLxConsentVS = () => {
    if(history.location.PageCurrent===undefined)
    {
        SETxPageIndex(0);
        REQConsentVSList({
          page: "1",
          nConsentTypeId:RowDataAt.consent_type_id,
          tSortH
        });
    }
    else
    {
      let nPage = history.location.PageCurrent+1;
      if(history.location.pathname === "/Version")
      {
      SETxPageIndex(history.location.PageCurrent);
      REQConsentVSList({
        page: nPage.toString(),
        nConsentTypeId:RowDataAt.consent_type_id,
        tSortH:history.location.UISearch.tSortH
      });
      }
    }
    };

  const SETxActHeaderClick = cell => {
      if (cell === "CONSENT DATE") 
      {
        if (tSortH === "created_at ASC") 
        {
        SETtSortH("created_at DESC");
        } 
        else if (tSortH === "created_at DESC") 
        {
        SETtSortH("created_at ASC");
        }
        else
        {
        SETtSortH("created_at DESC");
        }
      }
      else if (cell === "CONSENT VERSION")
      {
      if (tSortH === "consent_version ASC") 
      {
        SETtSortH("consent_version DESC");
      } 
      else if (tSortH === "consent_version DESC") 
      {
        SETtSortH("consent_version ASC");
      }
      else
      {
        SETtSortH("consent_version DESC");
      }
      }else if (cell === "CONSENT NO")
      {
      if (tSortH === "consent_no ASC") 
      {
        SETtSortH("consent_no DESC");
      } 
      else if (tSortH === "consent_no DESC") 
      {
        SETtSortH("consent_no ASC");
      }
      else
      {
        SETtSortH("consent_no DESC");
      }
      }
    };
  
  const SETxActTableClick = (cellValue, rowData, columnName) => {
    if (columnName === "ACTION") {    
      MEMConsentVSDetail({ RowDataAt: rowData ,PageCurrent: nPageIndex
        ,UISearch:{
          tConsentTypeId:RowDataAt.consent_type_id,
          tConsentType:RowDataAt.consent_type,
          tSortH:tSortH
        }
      });
      localStorage.setItem('currentVersion', JSON.stringify({ RowDataAt: rowData ,PageCurrent: nPageIndex
        ,UISearch:{
          tConsentTypeId:RowDataAt.consent_type_id,
          tConsentType:RowDataAt.consent_type,
          tSortH:tSortH
        }
      }));
      history.push(`/VersionDetail`);
    }
  };

  const SETxPageGo = pnPageAt => {
    let nPageAt = pnPageAt + 1;
    SETxPageIndex(pnPageAt);
    REQConsentVSList({
      page: nPageAt.toString(),
      nConsentTypeId:RowDataAt.consent_type_id,
      tSortH
    });
  };

  const CALxPaging = () => {
    let nPageCnt = Math.ceil(totalConsentVS / 10);
    return nPageCnt > 1 ? (
      <Pagination
        nPageIndex={nPageIndex}
        total={nPageCnt}
        gotoPage={SETxPageGo}
      />
    ) : null;
  };

  const SETxFltHeader = cell => 
  {
    if (cell === "STATUS CODE") 
    {
      return (
        <div className="clients-header-sortby-converted-user">
          <div className="clients-header-sortby-text-container-converted-user">
            <p>{cell}</p>
            <img
              className="clients-header-sortby-img-converted-user"
              src={Images.filer.filer}
              alt="down-arrow"
            />
          </div>

        </div>
      );
    }
  };

  useEffect(() => {
    CALLxConsentVS();
    }, []);
  
    useEffect(() => {
      const savePageState = () => {
        const dataFromList = JSON.parse(localStorage.getItem('currentConsent'))
        console.log("dataFromList", dataFromList)
        localStorage.setItem('currentConsent', JSON.stringify(dataFromList));
      };
  
      // Save initial state
      savePageState();
      
      const handleBeforeUnload = () => {
        savePageState();
      };
    
      const handlePageShow = (event) => {
        if (!event.persisted) {
          const storedData = JSON.parse(localStorage.getItem('currentConsent'));
          if (storedData) {
            console.log("stored", storedData)
          }
        }
      };
  
      // Check for page reload using Performance API
      const navigationType = window.performance.getEntriesByType("navigation")[0]?.type;
      if (navigationType === 'reload') {
        setIsPageReload(true);
        const storedData = JSON.parse(localStorage.getItem('currentConsent'));
        console.log("StoredData out", storedData)
        if (storedData) {
          console.log("storedData", storedData)
          history.push(`/Version`);
         
        }
      }
  
      // Handle navigation events
      const unlisten = history.listen((location, action) => {
        if (action === 'POP' && !isPageReload && !isUnloading) {
          history.replace({
            pathname: "/Consent",
          });
        }
      });
  
      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('pageshow', handlePageShow);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        window.removeEventListener('pageshow', handlePageShow);
        unlisten();
  
        // Only clean up stored data if we're not reloading and not unloading
        if (!isPageReload && !isUnloading) {
          localStorage.removeItem('currentConsent');
        }
      };
    }, []);


  return (
    <Fragment>
    <div className="button-create-container" style={{ marginBottom: 1 }}>
      <div style={{ height: 40 }} />
      {REQStrConsentVSList ? (
        <div className="regisDevice-list-progress">
          <CircularProgress />
          <p className="regisDevice-list-progress-text">
            {REQStrConsentVSList ? "Retrieving Consent Version list..." : null}
          </p>
        </div>
      ) : null}
    </div>
    {}
    {REQStrConsentVSList ? null : (
      <Table
        columns={columns}
        data={ConsentVSList}
        handleActionHeaderClick={SETxActHeaderClick}
        handleFltHeader={SETxFltHeader}
        handleActionClick={SETxActTableClick} 
        pageSelect={nPageIndex}
        consentType={RowDataAt.consent_type}
      />
    )}
    {REQStrConsentVSList ? null : <CALxPaging />}
  </Fragment>
  );
}

 
const withConnect = connect(
  state => ({
    ...state.consentVSListReducer
  }),
  {
    REQConsentVSList,
    MEMConsentVSDetail
  }
);

export default compose(
  withConnect,
  withRouter
)(ConsentVSList);
