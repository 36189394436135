import {
  REQUEST_COUNTRIES,
  RECEIVE_COUNTRIES,
  FAILED_COUNTRIES,
  REQUEST_GENDERS,
  RECEIVE_GENDERS,
  FAILED_GENDERS,
  REQUEST_DOCTYPES,
  RECEIVE_DOCTYPES,
  FAILED_DOCTYPES,
  REQUEST_MARITAL,
  RECEIVE_MARITAL,
  FAILED_MARITAL,
  REQUEST_PROVINCES,
  RECEIVE_PROVINCES,
  FAILED_PROVINCES,
  REQUEST_POSTCODE,
  RECEIVE_POSTCODE,
  FAILED_POSTCODE,
  REQUEST_OCCUPATIONS,
  RECEIVE_OCCUPATIONS,
  FAILED_OCCUPATIONS,
  REQUEST_MONTHLY_INCOMES,
  RECEIVE_MONTHLY_INCOMES,
  FAILED_MONTHLY_INCOMES,
  REQUEST_INCOME_SOURCES,
  RECEIVE_INCOME_SOURCES,
  FAILED_INCOME_SOURCES,
  RECEIVE_BANKS,
  RECEIVE_NATIONALITIES,
  RECEIVE_DATA_FOR_CONTACT_FORM,
  REQUEST_DATA_FOR_CONTACT_FORM,
  FUND_CATEGORIES_RECIEVED,
  RECEIVE_DISTRICTS,
  RECEIVE_BUSINESSTYPES,
  REQUEST_BUSINESSTYPES,
} from "../constants";

const initialState = {
  retrievingCountries: false,
  countries: [],
  retrievingGenders: false,
  genders: [],
  retrievingDoctypes: false,
  doctypes: [],
  retrievingMaritalStatuses: false,
  maritalStatuses: [],
  retrievingProvinces: false,
  districts: [],
  retrievingDistricts: false,
  provinces: [],
  retrievingPostcodes: false,
  postcodes: [],
  retrievingOccupations: false,
  occupations: [],
  retrievingMonthlyIncomes: false,
  monthlyIncomes: [],
  retrievingIncomeSources: false,
  incomeSources: [],
  nationalities: [],
  banks: [],
  contactDistrict: [],
  contactPostCodes: [],
  districtsResidential: [],
  districtWork: [],
  fundCategories: [],
  districtContactAddress: [],
  districtMailingAddress: [],
  businessTypes: [],
  retrievingBusinessTypes: false,
};

export function dropDownDataReducer(state = initialState, action = {}) {
  let listOfCountries = action.countries || [];
  let listOfNationalities = action.payload || [];
  let listOfGenders = action.genders || [];
  let listOfDoctypes = action.doctypes || [];
  let listOfStatus = action.maritalStatuses || [];
  let listOfProvinces = action.provinces || [];
  let listOfPostcodes = action.postcodes || [];
  let listOfBusinessTypes = action.businessTypes || [];
  let listOfOccupations = action.occupations || [];
  let listOfMonthlyIncomes = action.monthlyIncomes || [];
  let listOfIncomeSources = action.incomeSources || [];
  let listOfBanks = action.payload || [];

  switch (action.type) {
    case FUND_CATEGORIES_RECIEVED:
      return {
        ...state,
        fundCategories: action.payload,
      };
    case REQUEST_COUNTRIES:
    case FAILED_COUNTRIES:
      return {
        ...state,
        retrievingCountries: action.retrievingCountries,
      };
    case RECEIVE_COUNTRIES:
      listOfCountries.forEach((element) => {
        element["value"] = element.name;
        element["label"] = element.name;
      });
      return {
        ...state,
        retrievingCountries: action.retrievingCountries,
        countries: listOfCountries,
      };
    case RECEIVE_NATIONALITIES:
      listOfNationalities.forEach((element) => {
        element["value"] = element.name;
        element["label"] = element.name;
      });
      return {
        ...state,
        nationalities: listOfNationalities,
      };
    case REQUEST_GENDERS:
    case FAILED_GENDERS:
      return {
        ...state,
        retrievingGenders: action.retrievingGenders,
      };

    case RECEIVE_GENDERS:
      listOfGenders.forEach((element) => {
        element["value"] = element.name;
        element["label"] = element.name;
      });
      return {
        ...state,
        retrievingGenders: action.retrievingGenders,
        genders: listOfGenders,
      };
    case REQUEST_DOCTYPES:
    case FAILED_DOCTYPES:
      return {
        ...state,
        retrievingDoctypes: action.retrievingDoctypes,
      };

    case RECEIVE_DOCTYPES:
      listOfDoctypes.forEach((element) => {
        element["value"] = element.name;
        element["label"] = element.name;
      });
      return {
        ...state,
        retrievingDoctypes: action.retrievingDoctypes,
        doctypes: listOfDoctypes,
      };

    case REQUEST_MARITAL:
    case FAILED_MARITAL:
      return {
        ...state,
        retrievingMaritalStatuses: action.retrievingMaritalStatuses,
      };

    case RECEIVE_MARITAL:
      listOfStatus.forEach((element) => {
        element["value"] = element.name;
        element["label"] = element.name;
      });
      return {
        ...state,
        retrievingMaritalStatuses: action.retrievingMaritalStatuses,
        maritalStatuses: listOfStatus,
      };

    case RECEIVE_DISTRICTS: {
      const { data = [], type } = action.payload;
      return {
        ...state,
        [type]: data.map((item) => ({
          ...item,
          value: item.name,
          label: item.name,
        })),
      };
    }
    case REQUEST_PROVINCES:
    case FAILED_PROVINCES:
      return {
        ...state,
        retrievingProvinces: action.retrievingProvinces,
      };
    case RECEIVE_PROVINCES:
      listOfProvinces.forEach((element) => {
        element["value"] = element.name;
        element["label"] = element.name;
      });
      return {
        ...state,
        retrievingProvinces: action.retrievingProvinces,
        provinces: listOfProvinces,
      };
    case REQUEST_POSTCODE:
    case FAILED_POSTCODE:
      return {
        ...state,
        retrievingPostcodes: action.retrievingPostcodes,
      };
    case RECEIVE_POSTCODE:
      listOfPostcodes.forEach((element) => {
        element["value"] = element.code;
        element["label"] = element.name;
      });
      return {
        ...state,
        retrievingPostcodes: action.retrievingPostcodes,
        postcodes: listOfPostcodes,
      };

    case REQUEST_OCCUPATIONS:
    case FAILED_OCCUPATIONS:
      return {
        ...state,
        retrievingOccupations: action.retrievingOccupations,
      };
    case RECEIVE_OCCUPATIONS:
      listOfOccupations.forEach((element) => {
        element["value"] = element.name;
        element["label"] = element.name;
      });
      return {
        ...state,
        retrievingOccupations: action.retrievingOccupations,
        occupations: listOfOccupations,
      };
    case REQUEST_BUSINESSTYPES:
      return {
        ...state,
        retrievingBusinessTypes: action.retrievingBusinessTypes,
      };
    case RECEIVE_BUSINESSTYPES:
      listOfBusinessTypes.forEach((element) => {
        element["value"] = element.name;
        element["label"] = element.name;
      });
      return {
        ...state,
        retrievingOccupations: action.retrievingOccupations,
        businessTypes: listOfBusinessTypes,
      };

    case REQUEST_MONTHLY_INCOMES:
    case FAILED_MONTHLY_INCOMES:
      return {
        ...state,
        retrievingMonthlyIncomes: action.retrievingMonthlyIncomes,
      };
    case RECEIVE_MONTHLY_INCOMES:
      listOfMonthlyIncomes.forEach((element) => {
        element["value"] = element.name;
        element["label"] = element.name;
      });
      return {
        ...state,
        retrievingMonthlyIncomes: action.retrievingMonthlyIncomes,
        monthlyIncomes: listOfMonthlyIncomes,
      };

    case REQUEST_INCOME_SOURCES:
    case FAILED_INCOME_SOURCES:
      return {
        ...state,
        retrievingIncomeSources: action.retrievingIncomeSources,
      };
    case RECEIVE_INCOME_SOURCES:
      listOfIncomeSources.forEach((element) => {
        element["value"] = element.name;
        element["label"] = element.name;
      });
      return {
        ...state,
        retrievingIncomeSources: action.retrievingIncomeSources,
        incomeSources: listOfIncomeSources,
      };

    case RECEIVE_BANKS:
      listOfBanks.forEach((element) => {
        element["value"] = element.name;
        element["label"] = element.name;
      });
      return {
        ...state,
        retrievingBanks: action.retrievingBanks,
        banks: listOfBanks,
      };
    case REQUEST_DATA_FOR_CONTACT_FORM: {
      return {
        ...state,
        loading: action.payload.loading,
      };
    }

    case RECEIVE_DATA_FOR_CONTACT_FORM: {
      return {
        ...state,
        [action.payload.name]: action.payload.data,
        loading: false,
      };
    }
    default:
      return state;
  }
}
