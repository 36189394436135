export const list_of_columns = [
  {
    Header: "NO.",
    accessor: "no"
  },
  {
    Header: "UNITHOLDER ID",
    accessor: "unitholderId"
  },
  {
    Header: "FULL NAME",
    accessor: "unitholderName"
  },
  {
    Header: "FUND CODE",
    accessor: "fundCode"
  },
  {
    Header: "TRANS. TYPE",
    accessor: "transCode"
  },
  {
    Header: "ORDER DATE",
    accessor: "transDatetime"
  },
  {
    Header: "EFFECTIVE DATE",
    accessor: "effectiveDate"
  },
  {
    Header: "AMOUNT (THB)",
    accessor: "amount"
  },
  {
    Header: "UNITS",
    accessor: "unit"
  },
  {
    Header: "SWITCH TO FUND CODE",
    accessor: "toFundCode"
  },
  {
    Header: "STATUS",
    accessor: "statusDesc"
  }
];
