import React, { Fragment,useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { CircularProgress } from "@material-ui/core";
import { REQNDIDLogDetail } from "./redux/actions";
import { withRouter  } from "react-router-dom";
import ReactJson from 'react-json-view';
import "./style.scss";
function NDIDLogDetail(poPrp) {
  const {
    REQNDIDLogDetail,
    RowDataAt,
    history,
    PageCurrent,
    REQStrNDIDLoglist,
    NDIDLoglist,
    UISearch
  } = poPrp;

  const GETxNDIDLogDetail = () => {
    REQNDIDLogDetail({
      identification_no:RowDataAt.identification_no
    });
  };  
 
  useEffect(() => {
    GETxNDIDLogDetail();

    return () => {      
       if (history.action === "POP") {
        history.replace({
          pathname:'/NDIDLog',
          PageCurrent: PageCurrent,
          UISearch:UISearch
        });
       }
    };

 }, []); 

 const [isPageReload, setIsPageReload] = useState(false);
  const [isUnloading, setIsUnloading] = useState(false);

  useEffect(() => {
    const savePageState = () => {
      const dataFromList = JSON.parse(localStorage.getItem('currentNDIDlogDetail'))
      console.log("dataFromList", dataFromList)
      localStorage.setItem('currentNDIDlogDetail', JSON.stringify(dataFromList));
    };
  
    // Save initial state
    savePageState();
    
    const handleBeforeUnload = () => {
      savePageState();
    };
  
    const handlePageShow = (event) => {
      if (!event.persisted) {
        const storedData = JSON.parse(localStorage.getItem('currentNDIDlogDetail'));
        if (storedData) {
          console.log("stored", storedData)
        }
      }
    };
  
    // Check for page reload using Performance API
    const navigationType = window.performance.getEntriesByType("navigation")[0]?.type;
    if (navigationType === 'reload') {
      setIsPageReload(true);
      const storedData = JSON.parse(localStorage.getItem('currentNDIDlogDetail'));
      
      if (storedData) {
        history.push(`/NDIDLogDetail`);
       
      }
    }
  
    // Handle navigation events
    const unlisten = history.listen((location, action) => {
      if (action === 'POP' && !isPageReload && !isUnloading) {
        history.replace('/NDIDLog')
      }
    });
  
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('pageshow', handlePageShow);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('pageshow', handlePageShow);
      unlisten();
  
      if (!isPageReload && !isUnloading) {
        localStorage.removeItem('currentNDIDlogDetail');
      }
    };
  }, []);

 const SHWxTypeTable =()=>{
  return(
    <ReactJson 
    src={NDIDLoglist}
    theme="monokai"
    iconStyle="circle"
    style={{paddingTop:10,paddingBottom:10,paddingLeft:5
          ,fontFamily: "Lato"
          ,wordBreak:"break-all"
        }}
      />
   );
 };
 
  return (
    <Fragment>
    <div className="tag-feed-form-container">
    <div className="tag-feed-form-buttons-container">
      <button
            className="tag-detail-cancel"
            onClick={() => 
              history.push({
                  pathname:'/NDIDLog',
                  PageCurrent: PageCurrent,
                  UISearch:UISearch
              })
            }
          >
            CANCEL
      </button> 
    </div>   
  
      <div className="acti-feed-form-inputs-container">
      {REQStrNDIDLoglist ? (
          <div className="regisDevice-list-progress">
            <CircularProgress />
            <p className="regisDevice-list-progress-text">
              {REQStrNDIDLoglist ? "Retrieving NDIDLog list..." : null}
            </p>
          </div>
        ) : null}

       {REQStrNDIDLoglist ? null : (SHWxTypeTable())}
      </div>
    </div>
  </Fragment>

  );
}

const withConnect = connect(

  state => ({
    ...state.regisLogDetailReducer
  }),
  {
    REQNDIDLogDetail
  }
);

export default compose(
  withConnect,
  withRouter
)(NDIDLogDetail);
