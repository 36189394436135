import { createReducer } from "redux-act";
import {
  requestUnthldDetailProfile,
  receiveUnthldDetailProfile,
  failUntHldDetailProfile,
  requestUntHldDetailDocuments,
  receiveUntHldDetailDocuments,
  failUntHldDetailDocuments,
  setCurrentCustomerUntHldDetail,
  requestUntHldDetailAccountLockedStatus,
  receiveUntHldDetailAccountLockedStatus,
  failUntHldDetailAccountLockedStatus,
  requestUntHldDetailDocumentApprove,
  receiveUntHldDetailDocumentApprove,
  failUntHldDetailDocumentApprove,
  requestUntHldDetailDocumentReject,
  receiveUntHldDetailDocumentReject,
  failUntHldDetailDocumentReject,
  requestUntHldDetailDocumentUndo,
  receiveUntHldDetailDocumentUndo,
  failUntHldDetailDocumentUndo,
  requestUntHldDetailApprove,
  receiveUntHldDetailApprove,
  failUntHldDetailApprove,
  requestUntHldDetailReject,
  receiveUntHldDetailReject,
  failUntHldDetailReject,
  requestUntHldDetailCancelStatus,
  receiveUntHldDetailCancelStatus,
  failUntHldDetailCancelStatus,
  clearUntHldDetailErrorMessage,
  requestUntHldDetailFunds,
  receiveUntHldDetailFunds,
  failUntHldDetailFunds,
  requestUntHldDetailAccountUnlock,
  receiveUntHldDetailAccountUnlock,
  failUntHldDetailAccountUnlock,
  receiveCustomerUntHldDetailChannel,
  getApplicants,
  setApplicants,
  failApplicants,
  // --------------User ACCOUNT-----------------
  REQUsrActSrch,
  RESUsrActSrch,
  FailUsrActSrch,
  // --------------UnitHolder-----------------
  REQUntHldSrch,
  RESUntHldSrch,
  FailUntHldSrch,
  CleanUpUntHldSrch,
  UpdateUnitHolderId,
  setFullImgUrl,
  // regis img
  FETCH_REGISTERED_IMAGE_REQUEST,
  FETCH_REGISTERED_IMAGE_SUCCESS,
  FETCH_REGISTERED_IMAGE_FAILURE,
  // counterservice
  FETCH_COUNTERSERVICE_IMAGE_REQUEST,
  FETCH_COUNTERSERVICE_IMAGE_SUCCESS,
  FETCH_COUNTERSERVICE_IMAGE_FAILURE,
  // s3
  FETCH_UPLOAD_FILE_TO_S3_SUCCESS,
  FETCH_UPLOAD_FILE_TO_S3_FAILURE,
  FETCH_UPLOAD_FILE_TO_S3_REQUEST,
  // save to db
  FETCH_SAVE_TO_DB_SUCCESS,
  FETCH_SAVE_TO_DB_FAILURE,
  FETCH_SAVE_TO_DB_REQUEST
} from "./actions";

let oAvatar = null;

const initialState = {
  requestingClientProfile: false,

  NameTH: null,
  UsrAccountType: null,
  UserName: null,
  RowDataAt:[],
  clientDetail: [],

  requestingClientDocuments: false,

  documentDetails: [],
  document9Selfie: [],

  requestingClientFunds: false,
  clientFunds: [],
  customerUuid: null,
  unitholderId: null,
  requestingLockedStatus: false,
  isLockedStatus: false,
  requestingUnlock: false,
  requestingDocumentApproval: false,
  requestingDocumentRejection: false,
  requestingDocumentUndo: false,

  requestingClientApproval: false,

  requestingClientRejection: false,
  requestingCancelStatus: false,
  errorMessage: null,
  customerChannel: null,
  // --------------User ACCOUNT-----------------
  REQStrUsrActSrch: false,
  UsrActSrch: [],
  totalUsrAct: 0,
  // --------------UnitHolder-----------------
  REQStrUntHldSrch: false,
  UntHldSrch: [],
  unitholderIdFrmApprove: "",
  totalUntHldSrch: 0,
  fullImgUrl: "",
  // regis img
  loading: false,
  registeredImage: "",
  regisImageDate: null,
  error: null,
  // counterservice
  counterCard: "",
  counterIdentify: "",
  counterDate: null,
  // s3
  uploadingToS3: false,
  s3UploadError: null,
  s3formdata: null,
  s3posturl: null,
};

const untHldDetailReducer = createReducer(
  {
    [requestUnthldDetailProfile]: (state) => {
      return {
        ...state,
        requestingClientProfile: true
      };
    },
    [receiveUnthldDetailProfile]: (state,payload) => {
      return {
        ...state,
        requestingClientProfile: false,
        clientDetail: payload 
      };
    },
    [failUntHldDetailProfile]: (state) => {
      return {
        ...state,
        requestingClientProfile: false,
        clientDetail: []
      };
    },
    // -----------Doc Image----------------------------------
    [requestUntHldDetailDocuments]: (state, payload) => {
     
      oAvatar = payload;
      return {
        ...state,
        requestingClientDocuments: true
      };
    },
    [receiveUntHldDetailDocuments]: (state, payload) => {
      let oImgDoc = null;
      let oDataCut = null;
      if (payload.length !== 0) {
        
        oImgDoc = payload.find(d => d.typeCode === "9");
        oDataCut = payload.filter(d => d.typeCode !== "9");
        // DocImage have Type=9 is SELFIE.than swop with AvarImage
        if (oImgDoc) {
          oDataCut.push({
            lastModifiedAt: oImgDoc.lastModifiedAt,
            statusCode: oImgDoc.statusCode,
            typeCode: oImgDoc.typeCode,
            url: oImgDoc.url || oAvatar,
            uuid: oImgDoc.uuid
          });
        }
        
      }

      return {
        ...state,
        requestingClientDocuments: false,
        documentDetails: oDataCut,
        document9Selfie: oImgDoc ? oImgDoc.url : oAvatar
      };
    },

    [failUntHldDetailDocuments]: (state) => {
      return {
        ...state,
        requestingClientDocuments: false,
        documentDetails: []
      };
    },

    [setCurrentCustomerUntHldDetail]: (state, payload) => {
      return {
        ...state,
        customerUuid: payload.uuid,
        unitholderId: payload.unitholderid,
        NameTH: payload.NameTH,
        UsrAccountType: payload.UsrAccountType,
        UserName: payload.UserName,
        RowDataAt:payload.RowDataAt,
        PageCurrent: payload.PageCurrent,
        UISearch: payload.UISearch,

        clientDetail: [],

        documentDetails: [],
        document9Selfie: [],

        clientFunds: [],
        isLockedStatus: false,
        requestingDocumentApproval: false,
        requestingDocumentRejection: false,
        requestingDocumentUndo: false,
        requestingClientApproval: false,
        requestingClientRejection: false,
        requestingCancelStatus: false,
        errorMessage: null
      };
    },
    [requestUntHldDetailAccountLockedStatus]: (state) => {
      return {
        ...state,
        requestingLockedStatus: true
      };
    },
    [receiveUntHldDetailAccountLockedStatus]: (state, payload) => {
      return {
        ...state,
        requestingLockedStatus: false,
        isLockedStatus: payload
      };
    },
    [failUntHldDetailAccountLockedStatus]: (state) => {
      return {
        ...state,
        requestingLockedStatus: false,
        isLockedStatus: false
      };
    },
    [requestUntHldDetailAccountUnlock]: (state) => {
      return {
        ...state,
        requestingUnlock: true
      };
    },
    [receiveUntHldDetailAccountUnlock]: (state) => {
      return {
        ...state,
        requestingUnlock: false,
        isLockedStatus: "UNLOCKED"
      };
    },
    [failUntHldDetailAccountUnlock]: (state) => {
      return {
        ...state,
        requestingUnlock: false
      };
    },
    [requestUntHldDetailDocumentApprove]: (state) => {
      return {
        ...state,
        requestingDocumentApproval: true
      };
    },
    [receiveUntHldDetailDocumentApprove]: (state) => {
      return {
        ...state,
        requestingDocumentApproval: false
      };
    },
    [failUntHldDetailDocumentApprove]: (state) => {
      return {
        ...state,
        requestingDocumentApproval: false
      };
    },
    [requestUntHldDetailDocumentReject]: (state) => {
      return {
        ...state,
        requestingDocumentRejection: true
      };
    },
    [receiveUntHldDetailDocumentReject]: (state) => {
      return {
        ...state,
        requestingDocumentRejection: false
      };
    },
    [failUntHldDetailDocumentReject]: (state) => {
      return {
        ...state,
        requestingDocumentRejection: false
      };
    },
    [requestUntHldDetailDocumentUndo]: (state) => {
      return {
        ...state,
        requestingDocumentUndo: true
      };
    },
    [receiveUntHldDetailDocumentUndo]: (state) => {
      return {
        ...state,
        requestingDocumentUndo: false
      };
    },
    [failUntHldDetailDocumentUndo]: (state) => {
      return {
        ...state,
        requestingDocumentUndo: false
      };
    },
    [requestUntHldDetailApprove]: (state) => {
      return {
        ...state,
        requestingClientApproval: true
      };
    },
    [receiveUntHldDetailApprove]: (state) => {
      return {
        ...state,
        requestingClientApproval: false
      };
    },
    [failUntHldDetailApprove]: (state, payload) => {
      return {
        ...state,
        requestingClientApproval: false,
        errorMessage: payload
      };
    },
    [requestUntHldDetailReject]: (state) => {
      return {
        ...state,
        requestingClientRejection: true
      };
    },
    [receiveUntHldDetailReject]: (state) => {
      return {
        ...state,
        requestingClientRejection: false
      };
    },
    [failUntHldDetailReject]: (state) => {
      return {
        ...state,
        requestingClientRejection: false
      };
    },
    [requestUntHldDetailCancelStatus]: (state) => {
      return {
        ...state,
        requestingCancelStatus: true
      };
    },
    [receiveUntHldDetailCancelStatus]: (state) => {
      return {
        ...state,
        requestingCancelStatus: false
      };
    },
    [failUntHldDetailCancelStatus]: (state) => {
      return {
        ...state,
        requestingCancelStatus: false
      };
    },
    [requestUntHldDetailFunds]: (state) => {
      return {
        ...state,
        requestingClientFunds: true
      };
    },
    [receiveUntHldDetailFunds]: (state, payload) => {
      return {
        ...state,
        requestingClientFunds: false,
        clientFunds: payload
      };
    },
    [failUntHldDetailFunds]: (state) => {
      return {
        ...state,
        requestingClientFunds: false
      };
    },
    [clearUntHldDetailErrorMessage]: (state) => {
      return {
        ...state,
        errorMessage: null
      };
    },
    [receiveCustomerUntHldDetailChannel]: (state, payload) => {
      return {
        ...state,
        customerChannel: payload
      };
    },
    [getApplicants]: (state) => {
      return {
        ...state,
        requestApplicants: true
      };
    },
    [setApplicants]: (state, payload) => {
      return {
        ...state,
        requestApplicants: false,
        applicantList: payload.applicants
      };
    },
    [failApplicants]: (state) => {
      return {
        ...state,
        requestApplicants: false,
        applicantList: []
      };
    },
    // --------------User ACCOUNT-----------------
    [REQUsrActSrch]: (state) => {
      return {
        ...state,
        REQStrUsrActSrch: true
      };
    },

    [RESUsrActSrch]: (state, payload) => {
      
      return {
        ...state,
        REQStrUsrActSrch: false,
        UsrActSrch: payload.Cli,
        totalUsrAct: payload.TotalCli
      };
    },

    [FailUsrActSrch]: (state) => {
      return {
        ...state,
        REQStrUsrActSrch: false,
        UsrActSrch: [],
        totalUsrAct: 0
      };
    },

    // --------------UnitHolder-----------------
    [REQUntHldSrch]: (state) => {
      return {
        ...state,
        REQStrUntHldSrch: true
      };
    },

    [RESUntHldSrch]: (state, payload) => {
      
      let tUntHldId = "";
      let aMemPageUntHldSrch;
      if (payload.response.length !== 0) {
        tUntHldId = payload.response.data[0].unitholderId;
        console.log("payload:",response.data[0]);
        aMemPageUntHldSrch = payload.aMemPageUntHldSrch;
        aMemPageUntHldSrch["unitholderid"] = tUntHldId;
        
      }
      console.log("tUntHldId:",tUntHldId);

      return {
        ...state,
        REQStrUntHldSrch: false,
        unitholderIdFrmApprove: tUntHldId,
        aMemPageUntHldSrch: aMemPageUntHldSrch,
        totalUntHldSrch: payload.total
      };
    },

    [FailUntHldSrch]: (state) => {
      return {
        ...state,
        REQStrUntHldSrch: false,
        unitholderIdFrmApprove: "",
        totalUntHldSrch: 0
      };
    },
    [CleanUpUntHldSrch]: (state) => {
      return {
        ...state,
        unitholderIdFrmApprove: ""
      };
    },
    [UpdateUnitHolderId]: (state, payload) => {
      return {
        ...state,
        unitholderId: payload
      };
    },
    [setFullImgUrl]: (state, payload) => {
      return {
        ...state,
        fullImgUrl: payload
      }
    },
    [FETCH_REGISTERED_IMAGE_REQUEST]: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    
    [FETCH_REGISTERED_IMAGE_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      registeredImage: payload.body.register_image,
      regisImageDate: payload.body.created_at,
    }),
  
    [FETCH_COUNTERSERVICE_IMAGE_FAILURE]: (state,payload ) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [FETCH_COUNTERSERVICE_IMAGE_REQUEST]: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    
    [FETCH_COUNTERSERVICE_IMAGE_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      counterIdentify: payload.body.picture_identify,
      counterCard: payload.body.picture_card,
      counterDate: payload.body.created_at,
    }),
  
    [FETCH_REGISTERED_IMAGE_FAILURE]: (state,payload ) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    [FETCH_UPLOAD_FILE_TO_S3_REQUEST]: (state) => ({
      ...state,
      uploadingToS3: true,  
      s3UploadError: null,   
    }),
    
    [FETCH_UPLOAD_FILE_TO_S3_SUCCESS]: (state, payload) => ({
      ...state,
      uploadingToS3: false,  
      s3formdata: payload.data.formDatas,  
      s3posturl: payload.data.postUrl,
    }),
    
    [FETCH_UPLOAD_FILE_TO_S3_FAILURE]: (state, payload) => ({
      ...state,
      uploadingToS3: false,  
      s3UploadError: payload, 
    }),
    [FETCH_SAVE_TO_DB_REQUEST]: (state) => ({
      ...state,
      savingtodb: true,  
      savetoDBerror: null,   
    }),
    
    [FETCH_SAVE_TO_DB_SUCCESS]: (state, payload) => ({
      ...state,
      savingtodb: false,  
      savetoDB: payload,  
    }),
    
    [FETCH_SAVE_TO_DB_FAILURE]: (state, payload) => ({
      ...state,
      savingtodb: false,  
      savetoDBerror: payload, 
    }),
  },
  initialState
);

export default untHldDetailReducer;
