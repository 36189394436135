import React from "react";
import moment from "moment";
import Images from "../../utils/styles/images";

export function modifyCell(cell, pageIndex, pageSize, handleActionClick) {
  // Format the cell data here
  const { props } = cell;
  let columnName =
    props && props.cell && props.cell.column && props.cell.column.Header
      ? props.cell.column.Header
      : null;
  let cellValue =
    props && props.cell && props.cell.value ? props.cell.value : null;
  let rowData =
    props && props.row && props.row.original ? props.row.original : null;

  if (columnName === "ACTION") {
    if (rowData) {
      return (
        <div className="funds-action-buttons">
          <button
            className="funds-action-view"
            onClick={() =>
              handleActionClick({ mode: "view", rowData: rowData,pageIndex })
            }
          >
            <img src={Images.fundListIcon.view} alt="eye" />
            View
          </button>
          <button
            className="funds-action-edit"
            onClick={() =>
              handleActionClick({ mode: "edit", rowData: rowData,pageIndex })
            }
          >
            <img src={Images.fundListIcon.edit} alt="pencil" />
            Edit
          </button>
        </div>
      );
    } else {
      return cell;
    }
  } 
  else if (columnName === "NAV DATE") 
  {
    if (cellValue) 
      {
        let aTime = cellValue.split("T");
        let tTime = aTime[1];
        tTime = tTime.substring(0, 8);
       
        return moment(cellValue).format("DD/MM/YYYY ")+tTime;
      } 
      else 
      {
        return cell;
      }
  } 
 else if (columnName === "SYNC DATE") 
  {
    if (cellValue) 
    // {
      // ------เอาเวลา-----
      // if (!cellValue) return cell; 
    //     const dateObject = new Date(cellValue);
    //     dateObject.setHours(dateObject.getHours() + 7); 
    //     dateObject.toISOString().slice(0, 19).replace("T", " ");
    //     return moment(dateObject).format("DD/MM/YYYY ")+dateObject.toISOString().slice(11, 19).replace("T", " ");

    // } 
    {
      // ------เอาเวลา-----
      const dateObject = new Date(cellValue);
      dateObject.setHours(dateObject.getHours() + 7);
      const timeStr = dateObject.toISOString().slice(11, 19).replace("T", " ");
  
      return moment(dateObject).format("DD/MM/YYYY ") + timeStr;
  }
    else 
    {
      return cell;
    }
  } 
  
  else if (columnName === "FUND STATUS") {
    if (typeof cellValue === "boolean" && cellValue) {
      return (
        <div className="funds-status-active">
          <p>Active</p>
        </div>
      );
    } else if (cellValue === false || cellValue === null) {
      return (
        <div className="funds-status-inactive">
          <p>Inactive</p>
        </div>
      );
    } else {
      return cell;
    }
  } else {
    return cell;
  }
}

// This is just for the header cells
export function modifyCellHeader(cell, handleActionHeaderClick, handleSortHeader) {
  if (cell === "FUND NO.") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        FUND NO.
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "FUND CODE") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        FUND CODE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "ASSET CLASS") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        ASSET CLASS
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "RISK LEVEL") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        RISK LEVEL
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "FUND STATUS") {
    return (
      <button
        className="transactions-header-with-sort"
        onClick={() => handleActionHeaderClick(cell)}
      >
        FUND STATUS
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else {
    return cell;
  }
}
