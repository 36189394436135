
import Images from "../../utils/styles/images";

const selected_menuListArray = [
  {
    title: "clients",
    text: "Clients",
    icon: Images.menuIcon.clients,
    link: "/",
    id: 11,
    children: [
      {
        title: "UserAccount",
        text: "User Account",
        link: "/UsrAct",
        id: 115
      },
      {
        title: "UnitHolder",
        text: "UnitHolder",
        link: "/UnitHolder",
        id: 116
      },
      {
        title: "ImportRGTtoB2C",
        text: "Import RGT",
        link: "/ImportRGTtoB2C",
        id: 117
      },
      {
        title: "AutoApprove",
        text: "Auto Approve",
        link: "/AutoApprove",
        id: 118
      }
    ]
  },
  {
    title: "Consent",
    text: "Consent",
    icon: Images.menuIcon.consent,
    link: "/Consent",
    id: 17
  },
  {
    title: "tag",
    text: "Tag",
    icon: Images.menuIcon.tag,
    link: "/Tag",
    id: 9
  },
  {
    title: "SearchRec",
    text: "Search Recommend",
    icon: Images.menuIcon.search_rec,
    link: "/SearchRec",
    id: 10
  },
  {
    title: "FundRec",
    text: "Fund Recommend",
    icon: Images.menuIcon.fund_rec,
    link: "/FundRec_FundMan",
    id: 12,
    children: [
      {
        title: "FundRec_FundMan",
        text: "By Fund Manager",
        link: "/FundRec_FundMan",
        id: 121
      },
      {
        title: "FundRec_Condi",
        text: "By Condition",
        link: "/FundRec_Condi",
        id: 122
      },
      {
        title: "FundRec_Risk",
        text: "SIMPLE ADVISORY",
        link: "/FundRec_FundRisk",
        id: 123
      }
    ]
  },
  {
    title: "fundList",
    text: "Fund List",
    icon: Images.menuIcon.fundList,
    link: "/fund_list",
    id: 2
  },
  {
    title: "transaction",
    text: "Transaction",
    icon: Images.menuIcon.transaction,
    link: "/transaction",
    id: 3,
    children: [
      {
        title: "transactionDetails",
        text: "Transaction details",
        link: "/transaction_details",
        id: 31
      },
      {
        title: "RSPAccountDetails",
        text: "RSP Account Details",
        link: "/rsp_account_details",
        id: 32
      },
      {
        title: "requestATSDetails",
        text: "Request ATS details",
        link: "/request_ats_details",
        id: 33
      }
    ]
  },
  {
    title: "ConFdMain",
    text: "Content Feed",
    icon: Images.menuIcon.content_feed,
    link: "/ConFd_Invest",
    id: 6,
    children: [
      {
        title: "ConFd_Invest",
        text: "มุมมองการลงทุน",
        link: "/ConFd_Invest",
        id: 62
      },
      {
        title: "ConFd_News",
        text: "ข่าวสารและรางวัล",
        link: "/ConFd_News",
        id: 63
      },
      {
        title: "ConFd_ConInvest",
        text: "สาระการลงทุน",
        link: "/ConFd_ContentInvest",
        id: 64
      },
      {
        title: "ConFd_ManualInvest",
        text: "คู่มือนักลงทุน",
        link: "/ConFd_ManualInvest",
        id: 65
      },
      {
        title: "ConFd_PVDNewsReport",
        text: "รายงาน ข่าวสารกองทุนสำรองเลี้ยงชีพ",
        link: "/ConFd_PVDNewsReport",
        id: 66
      },
      {
        title: "ConFd_PVDPlaning",
        text: "คลังความรู้ด้านการวางแผนการเงิน",
        link: "/ConFd_PVDPlaning",
        id: 67
      },
      {
        title: "ConFd_PVDFactSheet",
        text: "หนังสือชี้ชวนส่วนสรุปกองทุนสำรองเลี้ยงชีพ",
        link: "/ConFd_PVDFactSheet",
        id: 68
      },
      {
        title: "ConFd_NotiInvest",
        text: "ประกาศกองทุนรวม",
        link: "/ConFd_NotifyInvest",
        id: 69
      },
      {
        title: "ConFd_Others",
        text: "อื่นๆ",
        link: "/ConFd",
        id: 71
      },
      {
        title: "ConFd_Srch",
        text: "ค้นหา",
        link: "/ConFd_Srch",
        id: 70
      }
    ]
  },
  {
    title: "ContFS",
    text: "Contenet Feed Sort",
    icon: Images.menuIcon.con_feed_sort,
    link: "/ContenetFeedSort",
    id: 13
  },
  {
    title: "RegisDevice",
    text: "Register Device",
    icon: Images.menuIcon.device,
    link: "/RegisDevice",
    id: 14
  },
  {
    title: "Activity",
    text: "Activity Log",
    icon: Images.menuIcon.acti,
    link: "/Activity",
    id: 15,
    children: [
      {
        title: "Activity",
        text: "USER",
        link: "/Activity_Usr",
        id: 151
      },
      {
        title: "Activity",
        text: "ADMIN",
        link: "/Activity_Admin",
        id: 152
      }
    ]
  },
  {
    title: "RegisterLog",
    text: "Register Log",
    icon: Images.menuIcon.exprot,
    link: "/RegisLog",
    id: 16,
    children: [
      {
        title: "RegisterLog",
        text: "COUNTER SERVICE",
        link: "/RegisLog_CounterService",
        id: 161
      },
      {
        title: "RegisterLog",
        text: "NDID",
        link: "/RegisLog_NDID",
        id: 162
      },
      {
        title: "RegisterLog",
        text: "NDIDLog",
        link: "/NDIDLog",
        id: 163
      }
    ]
  },
  {
    title: "Analytic",
    text: "Analytic",
    icon: Images.menuIcon.exprot,
    link: "/Analytic",
    id: 18,
    children: [
      {
        title: "Analytic",
        text: "E@syinvest",
        link: "/Analytic_EsyInv",
        id: 181
      },
      {
        title: "Analytic",
        text: "Applicant",
        link: "/Analytic_Apps",
        id: 182
      }
    ]
  }
];


export const menuListArray = selected_menuListArray;
